import { useState, useEffect } from 'react'
import { Coordinates } from '../../types/Common'
import StorageUtil from '../../common/StorageUtil'

type CheckCurrentLocationProps = {
  enable: boolean
}

export const useCheckCurrentLocation = (props: CheckCurrentLocationProps) => {
  const { enable } = props
  const [currentLocation, setCurrentLocation] = useState<Coordinates | null>(
    null
  )
  const [permissionState, setPermissionState] =
    useState<PermissionState | null>(null)
  const [canAskPermission, setCanAskPermission] = useState<boolean>(
    StorageUtil.getLocationPermissionAsk()
  )

  useEffect(() => {
    if (enable) {
      getCurrentPosition(
        () => {
          setPermissionState('granted')
        },
        () => setPermissionState('denied')
      )
    }
    setCanAskPermission(StorageUtil.getLocationPermissionAsk())
    return () => {}
  }, [enable])

  function onErrorPermission(er: GeolocationPositionError) {
    if (er.code === GeolocationPositionError.POSITION_UNAVAILABLE) {
      setPermissionState('prompt')
    } else if (er.code === GeolocationPositionError.PERMISSION_DENIED) {
      setPermissionState('denied')
    }
  }

  function requestCurrentPosition() {
    navigator.geolocation.getCurrentPosition(
      (possition) => {
        setCurrentLocation({
          latitude: possition.coords.latitude,
          longitude: possition.coords.longitude,
        })
        setPermissionState('granted')
      },
      (er) => {
        onErrorPermission(er)
      }
    )
  }

  function getCurrentPosition(
    onSuccess: (coordinates: Coordinates) => void,
    onFaild: (er: GeolocationPositionError) => void
  ) {
    navigator.geolocation.getCurrentPosition(
      (possition) => {
        onSuccess({
          latitude: possition.coords.latitude,
          longitude: possition.coords.longitude,
        })
      },
      (er) => {
        onFaild(er)
      }
    )
  }

  async function requestLocationPermission(reqLocation = true) {
    try {
      const result = await navigator.permissions.query({ name: 'geolocation' })
      setPermissionState(result.state)
      if (reqLocation) {
        requestCurrentPosition()
      }
    } catch (er: any) {
      onErrorPermission(er)
    }
  }

  const closeLocationPermisionBar = () => {
    StorageUtil.setLocationPermissionAsk(false)
    setCanAskPermission(false)
  }

  return {
    enable: enable,
    canAskPermission: canAskPermission,
    currentLocation,
    permissionState,
    requestLocationPermission,
    getCurrentPosition,
    closeLocationPermisionBar,
  }
}
