import { Timestamp } from 'firebase/firestore'
import { fireStoreDb } from './FirebaseConfig'
import { FirebaseError } from 'firebase/app'
import Util from './Util'
import Constants from './Constants'
// TODO: Append current branch to the collection
function getCollectionNameWithPrefix(name: string) {
  if (process.env.NODE_ENV === 'development') {
    // return `${name}-GT-2594` // TODO:will remove this after complete the implementation (only for development)
    return `${name}`
  }
  return name
}

function parseDateToFireStoreTimeStamp(date: Date) {
  return Timestamp.fromDate(date)
}

function handleFirebaseAuthenticationError(error: FirebaseError | any): any {
  if (error.code === 'permission-denied') {
    Util.showErrorMessage(
      'Looks like something wrong with the required configurations, Please logout and login again.',
      Constants.TIMEOUT.HIDE_IMPORTANT_MESSAGE
    )
  } else {
    throw error
  }
}

export {
  getCollectionNameWithPrefix,
  parseDateToFireStoreTimeStamp,
  fireStoreDb,
  handleFirebaseAuthenticationError,
}
