import React, { useEffect } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import {
  AppColors,
  ComponentOptions,
  Icon,
  Text,
} from '@tealsapp/teals-components'
import { Constants, MessageUtils, Util } from '../../common'
import { UploadItem } from '../../types/Files'
import FileChooser from '../FileChooser'

const styles = StyleSheet.create({
  wrapperStyle: {
    width: '100%',
    height: '100%',
  },
  dropzoneContainer: {
    width: '100%',
    height: 'calc(100% - 169px)',
    padding: ComponentOptions.SPACES.LARGE * 6,
    justifyContent: 'center',
    alignItems: 'center',
  },
  dropzoneInnerContainer: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 2,
    borderRadius: ComponentOptions.SPACES.MEDIUM,
    borderColor: AppColors.common.subtitleText,
    borderStyle: 'dashed',
  },
  textWrapperStyle: {
    flexDirection: 'row',
    marginTop: ComponentOptions.SPACES.MEDIUM,
  },
})

type ClipDropZoneProps = {
  onDroppedFile: (list: UploadItem[]) => void
  onPressOpenGallery?: (isVisible: boolean) => void
  onSelectFiles?: (items: UploadItem[]) => void
}

const ClipDropZone = ({
  onDroppedFile,
  onPressOpenGallery,
  onSelectFiles,
}: ClipDropZoneProps) => {
  useEffect(() => {
    window.addEventListener('dragover', onDragOver)
    window.addEventListener('drop', onDrop)
    return () => {
      window.removeEventListener('dragover', onDragOver)
      window.removeEventListener('drop', onDrop)
    }
  }, [])

  function onDragOver(e: any) {
    e.preventDefault()
    e.stopPropagation()
  }

  function onDrop(e: any) {
    e.preventDefault()
    e.stopPropagation()
    const droppedFiles = e.dataTransfer?.files || []
    if (
      droppedFiles.length >
      Constants.UPLOAD_LIMITS.CLIP_SLIDE_BULK_UPLOADING_COUNT
    ) {
      Util.showErrorMessage(
        `Can't upload more than ${Constants.UPLOAD_LIMITS.CLIP_SLIDE_BULK_UPLOADING_COUNT} items to a clip`
      )
    } else {
      handleSelectedFiles(droppedFiles)
    }
  }

  async function handleSelectedFiles(fileList: FileList) {
    const items = await MessageUtils.formatUploadedItems(fileList, false)
    const filteredList = items.filter((i) =>
      Constants.CLIP_ACCEPTED_MIME_TYPES.includes(i.file?.type || '')
    )
    onDroppedFile(filteredList)
  }

  return (
    <View style={styles.dropzoneContainer}>
      <View style={styles.dropzoneInnerContainer}>
        <Icon
          name={'AddImage'}
          fill={AppColors.common.primary}
          size={64}
          wrapperStyles={{
            margin: ComponentOptions.SPACES.EXTRA_SMALL,
            marginBottom: ComponentOptions.SPACES.LARGE,
          }}
        />
        <Text h3 color={AppColors.common.black}>
          Drop media files here
        </Text>
        <View style={styles.textWrapperStyle}>
          {onPressOpenGallery && (
            <>
              <Text color={AppColors.common.yellow}>{`or choose from `}</Text>
              <TouchableOpacity
                onPress={() => {
                  onPressOpenGallery(true)
                }}
              >
                <Text color={AppColors.common.primary}>{'Chat gallery'}</Text>
              </TouchableOpacity>
            </>
          )}

          {onPressOpenGallery === undefined && (
            <FileChooser
              displayElement={
                <Text color={AppColors.common.yellow}>
                  {'choose from the file uploader'}
                </Text>
              }
              isMultiple={true}
              isFileItem={false}
              onSelectFiles={(items) => {
                if (
                  items.length <=
                  Constants.UPLOAD_LIMITS.CLIP_SLIDE_BULK_UPLOADING_COUNT
                ) {
                  const filteredList = items.filter((i) =>
                    Constants.CLIP_ACCEPTED_MIME_TYPES.includes(
                      i.file?.type || ''
                    )
                  )
                  onSelectFiles?.(filteredList)
                } else {
                  Util.showErrorMessage(
                    `Can't upload more than ${Constants.UPLOAD_LIMITS.CLIP_SLIDE_BULK_UPLOADING_COUNT} items to a clip`
                  )
                }
              }}
              containerStyle={{}}
              disabled={false}
            />
          )}
        </View>
      </View>
    </View>
  )
}

export default ClipDropZone
