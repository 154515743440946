/*
This modal is using for show modal inside a container
*/
import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import StyleSheet from 'react-native-media-query'
import { AppColors, ComponentOptions, Button } from '@tealsapp/teals-components'
import { Constants } from '../common'

import { useScreenType } from '../common/DimensionsUtil'
import { ScreenType } from '../types/Common'

const { styles } = StyleSheet.create({
  drawerContainerStyle: {
    position: 'absolute',
    bottom: 0,
    height: '100%',
    backgroundColor: AppColors.common.darkDrop,
    zIndex: 10000,
    width: Constants.SCREEN_LAYOUT_DEFAULT_SIZES.RIGHT_PANEL,
    justifyContent: 'flex-end',
  },
  drawerSmallScreenContainerStyle: {
    width: '100%',
  },
  drawerInnerContainerStyle: {
    width: '100%',
    paddingHorizontal: ComponentOptions.SPACES.MEDIUM,
    paddingTop: ComponentOptions.SPACES.MEDIUM,
    paddingBottom: ComponentOptions.SPACES.LARGE,
    backgroundColor: AppColors.common.white,
    borderTopLeftRadius: ComponentOptions.BORDER_RADIUS.DEFAULT,
    borderTopRightRadius: ComponentOptions.BORDER_RADIUS.DEFAULT,
  },
  drawerTopButtonsContainer: {
    flex: 1,
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingBottom: ComponentOptions.SPACES.MEDIUM,
  },
  leftButtonContainerStyle: { paddingLeft: 0 },
  rightButtonContainerStyle: { paddingRight: 0 },
  cardHeaderMidStyle: {
    paddingHorizontal: ComponentOptions.SPACES.MEDIUM,
    justifyContent: 'center',
    flex: 1,
  },
  cardHeaderMidLineStyle1: {
    backgroundColor: AppColors.common.concrete,
    height: ComponentOptions.SPACES.EXTRA_SMALL,
    borderRadius: ComponentOptions.SPACES.EXTRA_SMALL,
  },
  cardHeaderMidLineStyle2: {
    backgroundColor: AppColors.common.concrete,
    height: ComponentOptions.SPACES.EXTRA_SMALL,
    borderRadius: ComponentOptions.SPACES.EXTRA_SMALL,
    width: '60%',
    marginHorizontal: ComponentOptions.SPACES.LARGE,
  },
})

type CustomModalProps = {
  isVisible: boolean
  onModalHide: () => void
  renderBodyElement: React.ReactNode
  isHideRightElement?: boolean
  actionButtonCancelText?: string
  actionButtonCancelOnPress?: () => void
  onPressDone?: () => void
}

const CustomBottomDrawer = (props: CustomModalProps) => {
  const {
    isVisible,
    renderBodyElement,
    isHideRightElement,
    onModalHide,
    onPressDone,
  } = props
  const screenType = useScreenType()

  if (isVisible) {
    return (
      <View
        style={[
          styles.drawerContainerStyle,
          screenType !== ScreenType.Desktop
            ? styles.drawerSmallScreenContainerStyle
            : {},
        ]}
      >
        <View style={styles.drawerInnerContainerStyle}>
          <View style={styles.drawerTopButtonsContainer}>
            <Button
              textProps={{ body: true }}
              type={ComponentOptions.BUTTON_TYPES.CLEAR}
              onPress={onModalHide}
              title={'Cancel'}
              containerStyle={styles.leftButtonContainerStyle}
              buttonColor={AppColors.common.subtitleText}
            />
            <View style={styles.cardHeaderMidStyle}>
              <TouchableOpacity
                onPress={onModalHide}
                style={
                  !isHideRightElement
                    ? styles.cardHeaderMidLineStyle1
                    : styles.cardHeaderMidLineStyle2
                }
                activeOpacity={0.5}
              />
            </View>
            {!isHideRightElement && (
              <Button
                textProps={{ body: true }}
                type={ComponentOptions.BUTTON_TYPES.CLEAR}
                onPress={() => {
                  !onPressDone && onModalHide()
                  onPressDone && onPressDone()
                }}
                title={'Done'}
                containerStyle={styles.rightButtonContainerStyle}
              />
            )}
          </View>
          {renderBodyElement}
        </View>
      </View>
    )
  }
  return null
}

export default CustomBottomDrawer
