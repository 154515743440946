import { StreamVideoParticipant } from '@stream-io/video-react-sdk'
import { Constants, Util } from '../common'
import { CallMember, CallMemberCategory, Member } from '../types/Stream'

type CallSubMetaData = {
  'thread-id'?: string
  'todolist-id'?: string
  'todolist-task-id'?: string
}

type CallCustomMetaData = {
  'call-category'?: string
  'call-member-category'?: string
}

export function getCallId(callCategory: string) {
  const id = Util.generateUUID()
  if (callCategory === Constants.STREAM_CALL.CALL_CATEGORRIES.THREAD) {
    return `thread_${id}`
  } else if (
    callCategory === Constants.STREAM_CALL.CALL_CATEGORRIES.TODO_LIST
  ) {
    return `todo_${id}`
  } else {
    return `todo_task_${id}`
  }
}

export function getCallType(callCategory: string) {
  if (callCategory === Constants.STREAM_CALL.CALL_CATEGORRIES.THREAD) {
    return Constants.STREAM_CALL.CALL_TYPES.THREAD
  } else if (
    callCategory === Constants.STREAM_CALL.CALL_CATEGORRIES.TODO_LIST
  ) {
    return Constants.STREAM_CALL.CALL_TYPES.TODO
  } else if (
    callCategory === Constants.STREAM_CALL.CALL_CATEGORRIES.TODO_TASK
  ) {
    return Constants.STREAM_CALL.CALL_TYPES.TODO_TASK
  }
  return ''
}

export function generateMetaDataFromIdAndSubId(
  callCategory: string,
  id: string,
  subId = ''
) {
  const metaData: CallSubMetaData = {}
  if (callCategory === Constants.STREAM_CALL.CALL_CATEGORRIES.THREAD) {
    metaData['thread-id'] = id
  }
  if (callCategory === Constants.STREAM_CALL.CALL_CATEGORRIES.TODO_LIST) {
    metaData['todolist-id'] = id
  }
  if (callCategory === Constants.STREAM_CALL.CALL_CATEGORRIES.TODO_TASK) {
    metaData['todolist-id'] = id
    metaData['todolist-task-id'] = subId
  }
  return metaData
}

export function generateCustomMetaData(
  id: string,
  subId: string,
  callCategory: string,
  callMemberCategory: string,
  validate = false
) {
  const data: CallCustomMetaData = {}

  if (validate) {
    if (!callCategory) {
      throw new Error('Call category is required')
    } else if (!callMemberCategory) {
      throw new Error('Call member category is required')
    }
  }

  const subMeta = generateMetaDataFromIdAndSubId(callCategory, id, subId)

  data['call-category'] = callCategory || ''
  data['call-member-category'] = callMemberCategory || ''

  return { ...data, ...subMeta }
}

export function convertToCallMembers(members: CallMember[]) {
  return members.map((member) => {
    return {
      user_id: member?.effectiveUserId,
      custom: {
        'user-id': member?.userId,
        'profile-pic-key': member?.profilePic,
        'org-id': member?.businessId,
        'org-name': member?.businessName,
        'first-name': member?.firstName,
        'last-name': member?.lastName,
      },
    }
  })
}

export function convertToCallMembersLobby(
  callMemberCategory: CallMemberCategory,
  members: any[]
) {
  if (
    callMemberCategory === Constants.STREAM_CALL.CALL_MEMBER_CATEGORRIES.THREAD
  ) {
    return members.map((member): CallMember => {
      return {
        callUserProfileCreated: !!member?.['call-user-profile-created'],
        effectiveUserId: member?.['effective-user-id'],
        firstName: member?.['first-name'] || '',
        lastName: member?.['last-name'] || '',
        profilePic: member?.['profile-pic'] || '',
        userId: member?.['user-id'],
        businessId: member?.['business-id'],
        businessName: member?.['business-name'] || '',
        roleType: member?.['role-type'],
        roleTemplateId: member?.['role-template-id'],
        roleTemplateDisplayName: member?.['role-template-display-name'],
      }
    })
  } else if (
    callMemberCategory ===
    Constants.STREAM_CALL.CALL_MEMBER_CATEGORRIES.BUSINESS
  ) {
    return members.map((member: Member): CallMember => {
      return {
        callUserProfileCreated: !!member?.['call-user-profile-created'],
        effectiveUserId: member?.['effective-user-id'],
        firstName: member?.['first-name'] || '',
        lastName: member?.['last-name'] || '',
        profilePic: member?.['profile-pic'] || '',
        userId: member?.['user-id'],
        businessId: member?.['business-id'],
        businessName: member?.['business-name'] || '',
        roleType: member?.['role-type'],
        roleTemplateId: member?.['role-template-id'],
        roleTemplateDisplayName: member?.['role-template-display-name'],
      }
    })
  }
  return []
}

export function getParticipantDisplayName(participant: StreamVideoParticipant) {
  const custom = participant?.custom || {}
  const orgName = custom?.['org-name'] || undefined
  const name = participant?.isLocalParticipant ? 'You' : participant?.name || ''

  return `${name} ${orgName ? ` @ ${orgName}` : ''}`
}
