import React, { useState } from 'react'
import { View, ActivityIndicator, TouchableOpacity } from 'react-native'
import StyleSheet from 'react-native-media-query'

import {
  ComponentOptions,
  AppColors,
  Button,
  Navigation,
  Icon,
  Spacer,
  Text,
} from '@tealsapp/teals-components'
import MainContent from '../../layout/MainContent'
import Image from '../media/Image'
import VideoPlayer from '../media/VideoPlayer'
import WarningModal from '../WarningModal'

import { Constants, MessageUtils, Util } from '../../common'
import { downloadMediaFile } from '../../common/S3Util'
import { SlideGalleryItem } from '../../types/ClipCentral'
import {
  GalleryItem,
  GalleryTab,
  GalleyType,
  Clip,
  AcceptedMediaTypes,
  UploadCategory,
} from '../../types/Files'
import { ClipPermissions } from '../../types/ClipCentral'

import { useFetchUserProfile } from '../../hooks/user/useFetchUser'
import { useDeleteSlide } from '../../hooks/clip/useMutateClip'

const { styles } = StyleSheet.create({
  mediaWrapper: {
    height: 300,
    width: 380,
    top: 'calc(50% - 200px)',
    alignItems: 'center',
  },
  mediaStyle: {
    flex: 1,
    width: '100%',
    height: 300,
  },
  footerContainerStyle: {
    paddingVertical: ComponentOptions.SPACES.MEDIUM,
    flexDirection: 'row',
    backgroundColor: AppColors.common.dark,
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: 375,
    position: 'absolute',
    bottom: 0,
  },
  clipPreviewButtonsContainerStyle: {
    flexDirection: 'column',
    alignItems: 'center',
    paddingHorizontal: ComponentOptions.SPACES.LARGE,
  },
})

const getRightPanelNavigationProps = (
  onClose: () => void,
  passedItem?: ClipGalleryItem | null
) => {
  const { data: uploadedUser } = useFetchUserProfile(
    passedItem?.uploadedByUserId || '',
    passedItem !== null
  )
  return {
    title: `${uploadedUser?.firstName || ''} ${uploadedUser?.lastName || ''}`,
    back: true,
    onBack: () => onClose(),
    rightIcon: (
      <Button
        type={ComponentOptions.BUTTON_TYPES.CLEAR}
        buttonColor={AppColors.common.primary}
        title={'Select'}
        paddingVertical={0}
        paddingHorizontal={0}
        textProps={{ body: true }}
      />
    ),
  }
}

const Header = (selectedClip: any) => {
  const { data: uploadedUser } = useFetchUserProfile(
    selectedClip.item['uploaded-by-user-id'] || '',
    selectedClip.item !== null
  )
  return (
    <Navigation
      title={`${uploadedUser?.firstName || ''} ${uploadedUser?.lastName || ''}`}
      subTitle={`${MessageUtils.createThreadLastUpdatedTime(
        selectedClip.item.gtCreatedTime || '',
        true
      )}`}
    />
  )
}
const MemorizedHeader = React.memo(Header)

interface ClipGalleryItem extends GalleryItem {
  clipUrl?: string
  slideKey: string
}

type GalleryMediaProps = {
  item: SlideGalleryItem
  style: any
}

const GalleryMedia = ({ item, style }: GalleryMediaProps) => {
  if (item.galleryType === GalleyType.photo) {
    return (
      <View style={style}>
        <Image
          size={Constants.IMAGE_SIZES.REPLICA}
          s3Key={item.slideKey}
          imageProps={{
            style: styles.mediaStyle,
            resizeMode: 'contain',
          }}
          imageFetchType={Constants.IMAGE_FETCH_TYPE.PHOTO}
          mediaNotAvailablePlaceholderUri={require('../../images/not-available-preview.png')}
          mediaNotSupportedPlaceholderUri={require('../../images/not-supported-preview.png')}
        />
      </View>
    )
  } else if (item.galleryType === GalleyType.video) {
    return (
      <View style={style}>
        <VideoPlayer
          s3Key={item.slideKey}
          videoProps={{
            flex: 1,
            resizeMode: 'contain',
            width: '100%',
            height: '100%',
          }}
        />
      </View>
    )
  }
  return null
}

type ClipPreviewButtonProps = {
  onPress: () => void
  iconName: string
  title: string
  isDisabled?: boolean
}
const ClipPreviewButton = (props: ClipPreviewButtonProps) => {
  const { onPress, iconName, title } = props
  return (
    <TouchableOpacity style={styles.clipPreviewButtonsContainerStyle}>
      <Icon
        name={iconName}
        size={22}
        fill={AppColors.common.white}
        stroke={1}
        onPress={onPress}
      />
      <Spacer size={ComponentOptions.SPACES.SMALL} />
      <Text body_small color={AppColors.common.white}>
        {title}
      </Text>
    </TouchableOpacity>
  )
}

type GalleryFooterProps = {
  clip: Clip
  item: ClipGalleryItem
  onPressSend: (item: ClipGalleryItem) => void
  onPressDelete: () => void
  clipPermissions: ClipPermissions
  isDeleting?: boolean
}
const GalleryFooter = (props: GalleryFooterProps) => {
  const {
    clip,
    item,
    onPressSend,
    onPressDelete,
    clipPermissions,
    isDeleting,
  } = props

  const onPressDownload = () => {
    const slide = item as unknown as any
    if (item.slideKey) {
      const itemMedia: any = {
        ...slide,
        objectId: slide.slideKey,
        galleryType: slide.uploadCategory,
        extension:
          slide.slideType === UploadCategory.photo
            ? AcceptedMediaTypes.JPEG
            : AcceptedMediaTypes.MP4,
      }
      downloadMediaFile(itemMedia)
    }
  }

  const onPressShare = () => {
    Util.copyToClipboard(
      clip.clipUrl,
      () => {
        Util.showSuccessMessage(
          'Copied links to clipboard',
          Constants.TIMEOUT.DEFAULT * 3
        )
      },
      (er) => {
        Util.showErrorMessage(er)
      }
    )
  }

  const iconColor = isDeleting ? AppColors.common.grey : AppColors.common.white

  const enableDownload =
    item.tab === GalleryTab.media && item.galleryType !== GalleyType.audio

  return (
    <View style={styles.footerContainerStyle}>
      {enableDownload && (
        <ClipPreviewButton
          title={`Download`}
          iconName={'Download'}
          onPress={() => onPressDownload()}
        />
      )}

      {isDeleting ? (
        <ActivityIndicator color={iconColor} />
      ) : item.galleryType === GalleyType.clips && !clipPermissions.delete ? (
        <></>
      ) : (
        <ClipPreviewButton
          title={`Delete`}
          iconName={'Bin'}
          onPress={onPressDelete}
        />
      )}

      <ClipPreviewButton
        title={`Share`}
        iconName={'Share'}
        onPress={() => onPressShare()}
      />

      <ClipPreviewButton
        title={`Send`}
        iconName={'Send'}
        onPress={() => onPressSend(item)}
      />
    </View>
  )
}

type SlideMainContentProps = {
  clip: Clip
  item?: SlideGalleryItem | null
  clipPermissions: ClipPermissions
  onClose: () => void
  onPressDelete: () => void
  setOpenThreadList: any
  isDeleting?: boolean
}
const SlideMainContent = (props: SlideMainContentProps) => {
  const {
    clip,
    item,
    clipPermissions,
    onClose,
    onPressDelete,
    setOpenThreadList,
    isDeleting,
  } = props

  return (
    <MainContent
      backgroundColor={AppColors.common.tertiary}
      onCloseMainContentCallback={() => onClose()}
      header={<MemorizedHeader item={item} />}
      rightPanelNavigationProps={getRightPanelNavigationProps(onClose)}
    >
      <GalleryMedia
        style={styles.mediaWrapper}
        item={item as SlideGalleryItem}
      />

      {item && (
        <GalleryFooter
          clip={clip}
          item={item}
          isDeleting={isDeleting}
          onPressSend={() => {
            setOpenThreadList(item)
          }}
          onPressDelete={onPressDelete}
          clipPermissions={clipPermissions}
        />
      )}
    </MainContent>
  )
}

type GalleryPreviewSidebarProps = {
  clip: Clip
  item: SlideGalleryItem | null
  onClose: any
  setOpenThreadList: (state: boolean) => void
}
const GalleryPreviewSidebar = ({
  clip,
  item,
  onClose,
  setOpenThreadList,
}: GalleryPreviewSidebarProps) => {
  const clipPermissions = Constants.DEFAULT_CLIP_PERMISSIONS
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

  const { mutate: deleteClipSlide, isLoading: isDeleting } = useDeleteSlide(
    onSuccessDeleteMedia,
    onErrorDeleteMedia
  )

  function onSuccessDeleteMedia() {
    onClose()
    Util.showSuccessMessage('Media deleted')
  }

  function onErrorDeleteMedia(error: any) {
    Util.showErrorMessage(
      error?.response?.data?.message || 'Error on deleting Media'
    )
  }

  return (
    <>
      <SlideMainContent
        clip={clip}
        item={item}
        onClose={onClose}
        clipPermissions={clipPermissions}
        onPressDelete={() => setShowDeleteModal(true)}
        isDeleting={isDeleting}
        setOpenThreadList={setOpenThreadList}
      />

      <WarningModal
        isVisible={showDeleteModal}
        title={`Remove ${item?.galleryType}?`}
        subTitle={`This will permenantly delete the ${item?.galleryType}`}
        onAccept={() => {
          setShowDeleteModal(false)
          item &&
            deleteClipSlide({
              clipId: clip.clipId,
              slideId: item.slideId,
              threadId: clip.threadId,
            })
        }}
        onCancel={() => setShowDeleteModal(false)}
        acceptButtonProp={{ buttonColor: AppColors.common.red }}
      />
    </>
  )
}

export default GalleryPreviewSidebar
