import { QueryClient, useMutation, useQueryClient } from 'react-query'
import { getAxios, QueryKeys } from '../../common'
import { useActiveOrgId } from '../../context/UserContext'
import { ServerError } from '../../types/Common'
const api = getAxios(true)

//----------Mark as read------------

const markAsReadNotification = async (notificationIds: string[]) => {
  const notifications =
    notificationIds.length > 0
      ? {
          'notification-ids': notificationIds,
        }
      : {}
  return await api.put('/notifications/markasread', notifications)
}

export const useMarkAsReadNotification = (
  onError: (error: ServerError, variables: string[]) => void
) => {
  const queryClient = useQueryClient()
  const activeOrgId = useActiveOrgId()
  const queryKey = [QueryKeys.NOTIFICATION_LIST, activeOrgId]
  return useMutation(markAsReadNotification, {
    onError: (error, variables, context: any) => {
      queryClient.setQueryData(queryKey, context.previousData)
      onError(
        (error?.response?.data || { message: error?.message }) as ServerError,
        variables
      )
    },
    onMutate: async (notificationIds) => {
      const notificationIdsList = notificationIds || []
      for (const notificationId of notificationIdsList) {
        deleteNotificationByIdInQueryData(
          queryClient,
          notificationId,
          activeOrgId
        )
      }
    },
    onSettled: () => {
      invalidateNotificationQueries(queryClient, activeOrgId)
    },
  })
}

// ============Delete notification =====
const deleteNotification = async (notificationId: string) => {
  return await api.delete(`/notifications/${notificationId}`)
}

export const useDeleteNotification = (
  onSuccess: (variables: string) => void,
  onError: (error: ServerError, variables: string) => void
) => {
  const queryClient = useQueryClient()
  const activeOrgId = useActiveOrgId()
  return useMutation(deleteNotification, {
    onError: (error, variables, context) => {
      onError(
        (error?.response?.data || {
          message: "can't delete notification",
        }) as ServerError,
        variables
      )
    },
    onMutate: async (variables) => {
      const notificationId = variables
      await deleteNotificationByIdInQueryData(
        queryClient,
        notificationId,
        activeOrgId
      )
    },
    onSuccess: (data, variables) => {
      onSuccess(variables)
    },
    onSettled: () => {
      invalidateNotificationQueries(queryClient, activeOrgId)
    },
  })
}

export const invalidateNotificationQueries = (
  queryClient: QueryClient,
  activeOrgId: string
) => {
  queryClient.invalidateQueries([QueryKeys.NOTIFICATION_LIST_ALL, activeOrgId])
  queryClient.invalidateQueries([QueryKeys.BADGE_COUNTS, activeOrgId])
}

export const deleteNotificationByIdInQueryData = async (
  queryClient: QueryClient,
  notificationId: string,
  activeOrgId: string
) => {
  await queryClient.cancelQueries([
    QueryKeys.NOTIFICATION_LIST_ALL,
    activeOrgId,
  ])

  queryClient.setQueryData(
    [QueryKeys.NOTIFICATION_LIST_ALL, activeOrgId],
    (oldQueryData: any) => {
      if (oldQueryData) {
        const updatedPages = (oldQueryData?.pages || []).map((page) => {
          if (notificationId) {
            const newNotificationsList = (
              page?.data?.notifications || []
            ).filter(
              (notification: any) =>
                notification['notification-id'] !== notificationId
            )

            return {
              ...page,
              data: {
                ...page.data,
                notifications: newNotificationsList,
              },
            }
          } else {
            return {
              ...page,
              data: {
                ...page.data,
                notifications: page?.data?.notifications || [],
              },
            }
          }
        })
        return {
          ...oldQueryData,
          pages: updatedPages,
        }
      }
      return oldQueryData
    }
  )
}
