import React, { useEffect, useRef, useState } from 'react'
import {
  FlatList,
  StyleSheet,
  View,
  TextInput,
  StyleProp,
  ViewStyle,
} from 'react-native'
import {
  AppColors,
  ComponentOptions,
  Text,
  Input,
  Button,
  ListItem,
  Icon,
  Navigation,
} from '@tealsapp/teals-components'
import { Constants, QueryKeys, Util } from '../../common'
import {
  useFindPlaces,
  useGetSpecificPlace,
} from '../../hooks/location/useFetchLocation'
import { Address, Place } from '../../types/Business'
import { useQueryClient } from 'react-query'
import { useCheckCurrentLocation } from '../../hooks/common/useFetchLocationPermission'

const styles = StyleSheet.create({
  coverModalWrapperStyle: {
    width: '100%',
    height: '100%',
    backgroundColor: AppColors.common.white,
    alignItems: 'center',
  },
  headerStyle: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  fullWidth: {
    width: '100%',
  },
  headerWrapperStyle: {
    paddingVertical: ComponentOptions.SPACES.MEDIUM,
  },
  searchListContentContainerStyle: {
    width: 'calc(100%- 16px)',
    borderTopLeftRadius: ComponentOptions.SPACES.SMALL,
    borderTopRightRadius: ComponentOptions.SPACES.SMALL,
    overflow: 'hidden',
    marginHorizontal: ComponentOptions.SPACES.MEDIUM,
  },
  locationInputWrapperStyle: {
    backgroundColor: AppColors.common.searchInputBackground,
    borderColor: AppColors.common.searchBorder,
    marginTop: ComponentOptions.SPACES.SMALL,
  },
  inputStyles: {
    color: AppColors.common.tertiary,
  },
  itemContainerStyle: { marginBottom: 0 },
  flatListContainerStyle: { flexWrap: 'wrap', maxWidth: 343 },
})

type LocationSearchProps = {
  onClose: () => void
  onLocationSelect: (location: Address) => void
  containerStyle?: StyleProp<ViewStyle>
}

function LocationSearch({
  onClose,
  onLocationSelect,
  containerStyle,
}: LocationSearchProps) {
  const [searchText, setSearchText] = useState('')
  const [placeId, setPlaceId] = useState('')
  const isTyping = useRef<NodeJS.Timeout | null>(null)
  const inputRef = useRef<TextInput>()

  const queryClient = useQueryClient()

  const {
    data: places,
    isFetching: isFetchingPlaces,
    refetch: refetchPlaces,
  } = useFindPlaces(searchText, false)

  const { refetch: refetchPlaceDetails } = useGetSpecificPlace(
    placeId,
    (place?: Address) => {
      if (!!place) {
        onLocationSelect(place as Address)
      }
    },
    !!placeId
  )

  const { permissionState, getCurrentPosition } = useCheckCurrentLocation({
    enable: true,
  })

  useEffect(() => {
    queryClient.setQueryData([QueryKeys.LOCATIONS], () => [])
    queryClient.setQueryData([QueryKeys.PLACE_DETAILS], () => undefined)
    setSearchText('')
    setPlaceId('')
  }, [])

  useEffect(() => {
    if ((!searchText || !isFetchingPlaces) && inputRef?.current) {
      inputRef.current.focus()
    }
    if (!!searchText) {
      refetchPlaces()
    }
  }, [searchText])

  useEffect(() => {
    if (placeId) {
      refetchPlaceDetails()
    }
  }, [placeId])

  const onTextTyping = (txt: string) => {
    if (isTyping && isTyping.current !== null) {
      clearTimeout(isTyping.current)
    }
    isTyping.current = setTimeout(() => {
      isTyping.current = null
      setSearchText(txt)
    }, Constants.TIMEOUT.TYPE_WAITING)
  }

  const renderHeaderComponent = () => {
    return (
      <React.Fragment>
        <View style={styles.headerWrapperStyle}>
          <Text body_small color={AppColors.common.subtitleText}>
            Enter postcode or suburb
          </Text>
          <Input
            testIds={{ textInputTestId: 'locationSearchInput' }}
            refNode={(ref: any) => {
              inputRef.current = ref
            }}
            textColor={AppColors.common.tertiary}
            InputWrapperStyle={styles.locationInputWrapperStyle}
            leftIcon={{
              fill: AppColors.common.subtitleText,
              name: 'Location',
            }}
            rightIcon={{
              fill: AppColors.common.subtitleText,
              name: 'Search',
            }}
            InputStyles={styles.inputStyles}
            placeholderTextColor={AppColors.common.subtitleText}
            placeholder={'Search for a location'}
            onChangeText={(txt: string) => onTextTyping(txt)}
            loading={isFetchingPlaces}
            InputProps={{ autoFocus: true }}
            value={searchText}
          />
        </View>
        {permissionState === 'granted' ? (
          <ListItem
            leftElement={
              <Icon name="Location" fill={AppColors.common.tertiary} />
            }
            title={'Use Current location'}
            titleColor={AppColors.common.tertiary}
            titleProps={{
              body_small: true,
            }}
            onPress={() => {
              getCurrentPosition(
                (coordinates) => {
                  onLocationSelect({
                    name: 'Current Location',
                    suburb: 'Current Location',
                    cordinates: coordinates,
                  } as Address)
                },
                (er) => {
                  Util.showErrorMessage(er.message)
                }
              )
            }}
            containerStyle={styles.itemContainerStyle}
          />
        ) : null}
      </React.Fragment>
    )
  }

  const renderItem = (item: Place) => {
    return (
      <ListItem
        title={item.place}
        titleColor={AppColors.common.tertiary}
        titleProps={{
          body_small: true,
        }}
        onPress={() => setPlaceId(item.placeId)}
        containerStyle={styles.itemContainerStyle}
      />
    )
  }

  const result = searchText.length === 0 ? [] : places

  const wrapperStyle = containerStyle
    ? [styles.coverModalWrapperStyle, containerStyle]
    : styles.coverModalWrapperStyle
  return (
    <View style={wrapperStyle}>
      <View style={styles.headerStyle}>
        <Navigation title={'Add Location'} back={true} onBack={onClose} />
      </View>
      <FlatList
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        data={result}
        extraData={result}
        keyExtractor={(item) => `i${item.place_id}`}
        renderItem={({ item }) => renderItem(item)}
        ListHeaderComponent={() => renderHeaderComponent()}
        stickyHeaderIndices={[0]}
        style={styles.fullWidth}
        contentContainerStyle={styles.searchListContentContainerStyle}
      />
    </View>
  )
}

export default LocationSearch
