export enum RoleType {
  userSignUpRole = 'user-signup-role',
  seekerRole = 'seeker-role',
  tradieSignUpRole = 'tradie-signup-role',
  tradieOwner = 'tradie-owner',
  tradieWorker = 'tradie-worker',
  tradieAdmin = 'tradie-admin',
  tradiePendingBusinessRole = 'tradie-pending-business-role',
  tradiePendingBusinessRejectedRole = 'tradie-pending-business-rejected-role',
  defaultOwner = 'default-owner',
}

export interface UserOrgRole {
  details?: UserOrgRoleBusiness
  'org-id'?: string
  'org-type'?: string
  type?: string
  'is-loading'?: boolean
  'role-type'?: RoleType
  'user-id'?: string
  'effective-user-id'?: string
  'role-template-display-name'?: string
}

export interface UserOrgRoleBusiness {
  'business-name'?: string
  handle?: string
  'business-logo'?: string
}

export interface SignupStage {
  assignedBusiness: string
  confirmedAccountCreation: string
  createdBusiness: string
  enteredEmail: string
  enteredName: string
  rejectedFromBusiness: string
  requestedBusiness: string
  selectedProfileType: string
}

export interface Service {
  name: string
  gtUpdatedTime: string
  serviceId: string
  gtRecordVersion: number
  serviceHashtag: string
  gtCreatedTime: string
}

export interface PendingBusiness {
  businessId: string
  gtCreatedTime: string
  gtRecordVersion: number
  gtUpdatedTime: string
  status: string
}

export interface Address {
  streetAddress: string
  cordinates: object
  state: string
  postalCode: string
  suburb: string
  subpremise: string
  streetNumber: string
  route: string
}

export interface Business {
  businessId: string
  gtUpdatedTime: string
  businessName: string
  gtRecordVersion: number
  gtCreatedTime: string
  roleType: string
  effectiveUserId: string
  handle: string
  phoneNumber: string
  owners: Profile[]
  suburb: string
  trades: Array<any>[]
  services: Array<any>[]
  lcExpired: boolean
  businessLogo: string
  businessLogoUrl: string
  address: Address
}

export interface Trade {
  tradeHash: string
  tradeName: string
}

export interface Metadata {
  startedTime: string
  endTime: string
}

export interface Profile {
  firstName: string
  phoneNumber: string
  gtRecordVersion: 1
  lastName: string
  email: string
  name: string
  signupStages: SignupStage
  pendingBusiness: PendingBusiness[]
  gtCreatedTime: string
  trades: string[]
  emailVerificationToken: string
  sub: string
  cognitoIdProviders: string
  services: Service[]
  emailAddress: string
  profile: string
  userId: string
  business: Business[]
  profileType: string
  viewOwnerOnboardingScreen: boolean
  gtUpdatedTime: string
  profilePic: string
  businessTrades: Trade[]
  invitationId: string
  viewWorkerOnboardingScreen: boolean
  cognitoUsername: string
  cognitoUserPoolid: string
  emailVerified: boolean
  profilePicUrl: string
  roleStatus: string
  metadata: Metadata
  chatFilters: string[]
  tradieType: string
  roleTemplateDisplayName: string
  roleType: string
  roleTemplateId?: string
  effectiveUserId?: string
}

export enum userRoles {
  userSignUpRole = 'user-signup-role',
  seekerRole = 'seeker-role',
  tradieSignUpRole = 'tradie-signup-role',
  tradieOwner = 'tradie-owner',
  tradieWorker = 'tradie-worker',
  tradieAdmin = 'tradie-admin',
  tradiePendingBusinessRole = 'tradie-pending-business-role',
  tradiePendingBusinessRejectedRole = 'tradie-pending-business-rejected-role',
  defaultOwner = 'default-owner',
}

export enum profileDetailsSections {
  personalDetails = 'personalDetails',
  personalAccountDetails = 'personalAccountDetails',
  personalDetailsSubmitButton = 'personalDetailsSubmitButton',
}

export enum ProfileDetailsUpdateScreens {
  PersonalDetailsScreen = 'PersonalDetailsScreen',
  ProfilePicUpdateScreen = 'ProfilePicUpdateScreen',
  ProfilePasswordUpdateScreenStep1 = 'ProfilePasswordUpdateScreenStep1',
  ProfilePasswordUpdateScreenStep2 = 'ProfilePasswordUpdateScreenStep2',
}
