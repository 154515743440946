import { useMutation, useQueryClient } from 'react-query'
import { Constants, getAxios, MessageUtils, QueryKeys } from '../../common'
import { useActiveOrgId } from '../../context/UserContext'
import { GalleryTab } from '../../types/Files'
import { Media } from '../../types/Message'
import { TaskStatus } from '../../types/TodoList'
const api = getAxios(true)

//-----------------Send Messages-----------------
type SendMessageProps = {
  threadId: string
  message: any
  threadFilter?: string
  isClipCreation?: boolean
  isForwardContent?: boolean
}

const sendMessageToThread = async ({ threadId, message }: SendMessageProps) => {
  const messagePayload = {
    ...message,
    client: 'web',
  }
  return await api.post(`/threads/${threadId}/messages`, messagePayload)
}

export const useSendMessageToThread = (
  onSuccess: (data: any, variables: any) => void,
  onError: (error: any, variables: any) => void
) => {
  const queryClient = useQueryClient()
  const activeOrgId = useActiveOrgId()

  return useMutation(sendMessageToThread, {
    onSuccess,
    onError,
    onSettled: (data, error, variables) => {
      const {
        threadId,
        threadFilter,
        isClipCreation,
        isForwardContent,
        message,
      } = variables

      queryClient.invalidateQueries([QueryKeys.THREAD, threadId, activeOrgId])
      //queryClient.invalidateQueries([QueryKeys.MESSAGES, threadId, activeOrgId])   // To stop refetching in thread
      if (isClipCreation) {
        queryClient.invalidateQueries([
          QueryKeys.THREAD_MEDIA,
          threadId,
          GalleryTab.clips,
          activeOrgId,
        ])
      }
      if (isForwardContent) {
        const media = message?.metadata?.media || []
        const effectiveUserId = message['effective-user-id']

        if (media.length > 0) {
          const hasTodoLists = media.some(
            (item: Media) =>
              item.type === Constants.MESSAGE_MEDIA_TYPE.TODO_LIST
          )
          const hasImagesVideos = media.some(
            (item: Media) =>
              item.type === Constants.MESSAGE_MEDIA_TYPE.VIDEO ||
              item.type === Constants.MESSAGE_MEDIA_TYPE.PHOTO
          )
          const hasDocs = media.some(
            (item: Media) => item.type === Constants.MESSAGE_MEDIA_TYPE.DOCUMENT
          )
          const hasClips = media.some(
            (item: Media) => item.type === Constants.MESSAGE_MEDIA_TYPE.CLIP
          )

          if (hasTodoLists) {
            queryClient.invalidateQueries([
              QueryKeys.THREAD_MEDIA,
              threadId,
              GalleryTab.todoLists,
              activeOrgId,
            ])

            queryClient.invalidateQueries([
              QueryKeys.BUSINESS_LATEST_TODO_LISTS,
              activeOrgId,
            ])

            queryClient.invalidateQueries([
              QueryKeys.USER_TASKS_STATUS_WISE,
              effectiveUserId,
              TaskStatus.outStanding,
            ])
            queryClient.invalidateQueries([
              QueryKeys.USER_TASKS_STATUS_WISE,
              effectiveUserId,
              TaskStatus.completed,
            ])
          }
          if (hasImagesVideos) {
            queryClient.invalidateQueries([
              QueryKeys.THREAD_MEDIA,
              threadId,
              GalleryTab.media,
              activeOrgId,
            ])
          }
          if (hasDocs) {
            queryClient.invalidateQueries([
              QueryKeys.THREAD_MEDIA,
              threadId,
              GalleryTab.docs,
              activeOrgId,
            ])
          }

          if (hasClips) {
            queryClient.invalidateQueries([
              QueryKeys.THREAD_MEDIA,
              threadId,
              GalleryTab.clips,
              activeOrgId,
            ])
          }
        }
      }

      if (threadFilter) {
        queryClient.invalidateQueries([
          QueryKeys.THREADS,
          threadFilter,
          activeOrgId,
        ])
      }
    },
  })
}

//------------Mark As Read
type MarkAsReadProps = {
  threadId: string
  effectiveUserId: string
  threadFilter?: string
}
const markAsRead = async ({ threadId, effectiveUserId }: MarkAsReadProps) => {
  const payload = {
    'mark-all-read-payload': {
      'viewed-by': effectiveUserId,
      'page-size': Constants.PAGE_SIZES.MESSAGE_FETCH_BATCH_SIZE,
    },
  }
  return await api.post(`/threads/${threadId}/messages`, payload)
}

//------------Use to Delete Media in Clustered Media Message------------
type DeleteMessageMediaProps = {
  threadId: string
  messageId: string
  deleteMedia: string[]
}

const deleteMessageMedia = async ({
  threadId,
  messageId,
  deleteMedia,
}: DeleteMessageMediaProps) => {
  const data = {
    media: deleteMedia,
  }
  return await api.delete(`/threads/${threadId}/messages/${messageId}`, {
    data: data,
  })
}

export const useMarkAsRead = (onError) => {
  const queryClient = useQueryClient()
  const activeOrgId = useActiveOrgId()

  return useMutation(markAsRead, {
    onError: (error, { threadFilter }, context) => {
      if (threadFilter) {
        queryClient.setQueryData(
          [QueryKeys.THREADS, threadFilter, activeOrgId],
          context.previousData
        )
      }

      onError(error)
    },
    onMutate: async ({ threadId, effectiveUserId, threadFilter }) => {
      if (threadFilter) {
        await queryClient.cancelQueries([
          QueryKeys.THREADS,
          threadFilter,
          activeOrgId,
        ])
        queryClient.setQueryData(
          [QueryKeys.THREADS, threadFilter, activeOrgId],
          (oldQueryData) => {
            if (oldQueryData) {
              const updatedPages = oldQueryData?.pages.map((page) => {
                const arThreads = page?.data?.threads
                const arUpdatedThreads = arThreads.map((thread) => {
                  if (thread['thread-id'] === threadId) {
                    const updatedThread = thread
                    updatedThread['read-info'][effectiveUserId] = true
                    return updatedThread
                  } else {
                    return thread
                  }
                })
                return {
                  ...page,
                  data: {
                    ...page.data,
                    threads: arUpdatedThreads,
                  },
                }
              })
              return {
                ...oldQueryData,
                pages: updatedPages,
              }
            } else {
              return oldQueryData
            }
          }
        )
      }
    },
    onSettled: (data, error, variables, context) => {
      const { threadFilter } = variables
      queryClient.invalidateQueries([QueryKeys.THREADS_ALL, activeOrgId])
      if (threadFilter) {
        queryClient.invalidateQueries([
          QueryKeys.THREADS,
          threadFilter,
          activeOrgId,
        ])
      }

      queryClient.invalidateQueries([
        QueryKeys.UNREAD_THREADS_COUNT,
        threadFilter,
        activeOrgId,
      ])
    },
  })
}

//--------------------Delete Message------------
type DeleteMessageProps = {
  threadId: string
  messageId: string
}

const deleteMessage = async ({ threadId, messageId }: DeleteMessageProps) => {
  return await api.delete(`/threads/${threadId}/messages/${messageId}`)
}

export const useDeleteMessage = (
  onSuccess: (data: any, variables: any) => void,
  onError: (data: any, variables: any) => void
) => {
  const queryClient = useQueryClient()
  const activeOrgId = useActiveOrgId()
  return useMutation(deleteMessage, {
    onSuccess,
    onError,
    onMutate: async ({ threadId, messageId }) => {
      const queryKey = [QueryKeys.MESSAGES, threadId, activeOrgId]
      const message = MessageUtils.findMessageFromCacheById(
        queryClient,
        queryKey,
        messageId
      )
      if (!!message) {
        const updatedMessage = {
          ...message,
          'app-is-deleting': true,
        }
        await MessageUtils.updateQueryCache(
          queryClient,
          queryKey,
          updatedMessage
        )
      }
    },
    onSettled: (data, error, variables, context) => {
      const queryKey = [QueryKeys.MESSAGES, variables.threadId, activeOrgId]
      queryClient.invalidateQueries(queryKey)
    },
  })
}

export const useDeleteMediaMessage = (
  onSuccess: (data: any) => void,
  onError: (error: any, variables: any, data: any) => void
) => {
  const queryClient = useQueryClient()
  const activeOrgId = useActiveOrgId()
  return useMutation(deleteMessageMedia, {
    onSuccess,
    onError,
    onMutate: async ({ threadId, messageId }) => {
      const queryKey = [QueryKeys.MESSAGES, threadId, activeOrgId]
      const message = MessageUtils.findMessageFromCacheById(
        queryClient,
        queryKey,
        messageId
      )
      if (!!message) {
        const updatedMessage = {
          ...message,
          'app-is-deleting': true,
        }
        await MessageUtils.updateQueryCache(
          queryClient,
          queryKey,
          updatedMessage
        )
      }
    },
    onSettled: async (data, error, variables) => {
      queryClient.invalidateQueries([
        QueryKeys.THREAD,
        variables.threadId,
        activeOrgId,
      ])
      queryClient.invalidateQueries([
        QueryKeys.MESSAGES,
        variables.threadId,
        activeOrgId,
      ])
      queryClient.invalidateQueries([
        QueryKeys.THREAD_MEDIA,
        variables.threadId,
        GalleryTab.media,
        activeOrgId,
      ])
    },
  })
}
