import { TouchableOpacity, View, } from 'react-native'
import { Avatar, Text, Icon, ComponentOptions, AppColors } from '@tealsapp/teals-components'
import StyleSheet from 'react-native-media-query'
import { useFetchNotificationUserProfile } from '../hooks/user/useFetchUser'
import { ChatNotificationType, NotificationMediaMetadata, WebsocketNotificationCamelCase } from '../types/Notification'
import { useEffect, useState } from 'react'
import NavigationKeys from '../navigation/NavigationKeys'
import Util from '../common/Util'
import { Constants } from '../common'
import { useActiveOrgId } from '../context/UserContext'
import { useFetchBusiness } from '../hooks/business/useFetchBusiness'
import { useFetchAllThreadsUnlimited } from '../hooks/message/useFetchThread'

const { styles } = StyleSheet.create({
  notification: {
    width: '100%',
    height: 'auto',
    padding: ComponentOptions.SPACES.MEDIUM,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: AppColors.common.dark,
    borderWidth: 1,
    borderRadius: ComponentOptions.BORDER_RADIUS.DEFAULT,
    borderColor: AppColors.common.slate,
  },
  notificationLeftContent: {
    marginEnd: 10,
  },
  notificationCenterContent: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: 50,
    marginLeft: ComponentOptions.SPACES.SMALL,
  },
  notificationRightContent: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: 70,
  },
  notificationTitleWrapper: {
    marginBottom: ComponentOptions.SPACES.SMALL / 2,
  },
})

interface NotificationProps extends WebsocketNotificationCamelCase {
  removeMessage: (props: WebsocketNotificationCamelCase) => void
}

const Notification = (props: NotificationProps) => {
  const {
    removeMessage,
    messageId,
    message,
    userId,
    threadId,
    threadType,
    businessId,
    orgId,
    metadata,
    notificationScenario,
    title,
    messageNotifictionTitle,
  } = props

  const activeOrgId = useActiveOrgId()
  const { data: senderData } = useFetchNotificationUserProfile(userId, !!(userId))
  const { data: business } = useFetchBusiness(businessId || orgId)
  const { data: threadsData } = useFetchAllThreadsUnlimited()

  const [chatNotifications, setChatNotification] = useState<ChatNotificationType>()

  const notificationMetaMedia = (metadata as NotificationMediaMetadata)?.media || []
  const attachmentIncluded = notificationMetaMedia.length > 0

  let receivedFrom = ''
  let emoji = null
  if (attachmentIncluded) {
    if (notificationMetaMedia[0].type === Constants.MESSAGE_MEDIA_TYPE.PHOTO) {
      emoji = '📷'
    } else if (notificationMetaMedia[0].type === Constants.MESSAGE_MEDIA_TYPE.VIDEO) {
      emoji = '🎥'
    } else if (notificationMetaMedia[0].type === Constants.MESSAGE_MEDIA_TYPE.AUDIO) {
      emoji = '🎙️'
    } else if (notificationMetaMedia[0].type === Constants.MESSAGE_MEDIA_TYPE.DOCUMENT) {
      emoji = '📄'
    } else if (notificationMetaMedia[0].type === Constants.MESSAGE_MEDIA_TYPE.TODO_LIST) {
      emoji = '📝'
    } else if (notificationMetaMedia[0].type === Constants.MESSAGE_MEDIA_TYPE.CLIP) {
      emoji = '🎬'
    }
    receivedFrom = `${emoji ? emoji + ' ' : ''}You've received ${notificationMetaMedia.length === 1 ? 'a' : ''} ${notificationMetaMedia[0].type}${notificationMetaMedia.length > 1 ? '(s)' : ''}`
  }

  const chatFromName: string = Util.getUsername(senderData) || ''
  const avatarAltLabel: string = chatFromName?.charAt(0)

  const isDifferentOrg = activeOrgId !== orgId || ''

  let notificationTitle = chatFromName
  const avatarImageUri = senderData?.profilePicUrl

  if (isDifferentOrg && business) {
    notificationTitle = `${chatFromName} @${business?.businessName}`
  }

  if (notificationScenario) {
    notificationTitle = title || messageNotifictionTitle || ''
  }

  useEffect(() => {
    setChatNotification({
      id: messageId,
      title: notificationTitle,
      description: message,
      avatar: {
        imageUri: avatarImageUri,
        altText: avatarAltLabel
      },
    })
  }, [senderData, business])

  const onNotificationClick = async () => {
    const threadIds = (threadsData?.pages || []).map((threadData: any) => threadData.threadId)
    const chatThreadType = threadType === Constants.USER_MESSAGE_CHAT_FILTERS.TEAMS ? NavigationKeys.TEAMS : NavigationKeys.WORKSITES
    const isThreadAvailable = threadIds.includes(threadId || metadata?.threadId)
    if (isThreadAvailable) {
      window.location.replace(`${chatThreadType}/${threadId}`)
    } else {
      window.location.replace(`${NavigationKeys.SETTINGS}?switchTo=${business?.businessId}`)
    }
  }

  return (
    <TouchableOpacity style={styles.notification} onPress={onNotificationClick}>
      {!notificationScenario ? <View style={styles.notificationLeftContent}>
        <Avatar
          hasBorder={true}
          imageUri={chatNotifications?.avatar?.imageUri}
          altText={chatNotifications?.avatar?.altText}
          size={ComponentOptions.AVATAR_SIZE.SMALL}
        />
      </View> : null}
      <View style={[styles.notificationCenterContent, notificationScenario ? { width: 'calc(100% - 28px)' } : { width: 'calc(100% - 88px)' }]}>
        <View style={styles.notificationTitleWrapper}>
          {receivedFrom ? <Text
            color={AppColors.common.white}
            body_small_bold
          >{receivedFrom}</Text> : null}
          <Text
            color={AppColors.common.white}
            body_small_bold
          >{chatNotifications?.title}</Text>
        </View>
        <Text color={AppColors.common.white} body_small>{attachmentIncluded ? 'Preview available in the chat' : chatNotifications?.description}</Text>
      </View>
      <View style={styles.notificationRightContent}>
        <Icon
          size={16}
          name={'Error'}
          fill={AppColors.common.white}
          onPress={() => removeMessage(props)}
        />
      </View>
    </TouchableOpacity>
  )
}

export default Notification
