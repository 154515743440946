import { GeoPoint, Timestamp } from 'firebase/firestore'
import DateTimeUtils from '../common/DateTimeUtils'
import {
  User,
  PublishStatus,
  Location,
  TodoStatus,
  FirebaseLocation,
  TaskStatus,
  TaggedUsers,
  Period,
  FirebasePeriod,
} from '../types/TodoList'
import { Constants, Util } from '../common'
import { OrderBy } from '../types/Common'

import { Profile } from '../types/User'

abstract class Template {
  abstract build(): FirebaseCollection
  abstract buildForUpdate(obj: Template): FirebaseCollection

  convertToFirebaseObject(obj?: Template): object {
    const convertedObj = (obj ? this.buildForUpdate(obj) : this.build()) as any

    const cleanObject: object = Object.keys(convertedObj).reduce((acc, key) => {
      const value = convertedObj[key]
      if (value !== undefined) {
        acc[key] = value
      }
      return acc
    }, {})
    return cleanObject
  }

  convertToProfileToUser(
    profile: Profile,
    effectiveUserId: string,
    activeBusinessId: string
  ): User {
    const activeBusiness = profile.business.find(
      (b) => b.businessId === activeBusinessId
    )
    return {
      userId: profile.userId,
      firstName: profile.firstName,
      lastName: profile.lastName,
      profilePic: profile?.profilePic || '',
      roleTemplateDisplayName: profile.roleTemplateDisplayName,
      businessName: activeBusiness?.businessName || '',
      businessId: activeBusinessId,
      effectiveUserId: effectiveUserId,
      phoneNumber: profile?.phoneNumber || '',
      email: profile?.email || '',
    }
  }
}

export abstract class FirebaseCollection {}

export class TodoList extends FirebaseCollection {
  publishStatus: PublishStatus
  createdByUser?: User
  updatedByUser?: User
  lastSequence?: number
  title: string | null
  location?: FirebaseLocation
  id: string | null
  threadId: string | null
  businessId: string | null
  enableTodoDateTime: boolean
  todoDate?: Timestamp | null
  todoStatus: TodoStatus
  todoOrderBy: OrderBy
  createdTime?: Timestamp
  updatedTime?: Timestamp
  deviceType = 'web'
  constructor(
    id: string | null = null,
    publishStatus: PublishStatus = Constants.PUBLISH_STATUS.DRAFT,
    createdByUser: User | null = null,
    lastSequence: number | null = null,
    title: string | null = null,
    location: Location | null = null,
    threadId: string | null = null,
    businessId: string | null = null,
    updatedByUser: User | null = null,
    todoStatus: TodoStatus = Constants.TO_DO_STATUS.OPEN.key,
    enableTodoDateTime = false,
    todoDate: Date | null = null,
    todoOrderBy: OrderBy = Constants.TO_DO_ORDER_BY.O2N
  ) {
    super()
    this.publishStatus = publishStatus

    if (createdByUser) {
      this.createdByUser = createdByUser
    }
    if (updatedByUser) {
      this.updatedByUser = updatedByUser
    }

    if (lastSequence) {
      this.lastSequence = lastSequence
    }

    this.title = title

    if (location) {
      const obj = { ...this.location }
      if (location.coordinates) {
        const coordinates = location.coordinates
        obj.coordinates = new GeoPoint(
          coordinates.latitude,
          coordinates.latitude
        )
      }
      if (location.suburb) {
        obj.suburb = location.suburb
      }
      this.location = obj
    }

    this.id = id

    this.threadId = threadId || null
    this.businessId = businessId || null

    this.enableTodoDateTime = enableTodoDateTime

    if (todoDate) {
      this.todoDate = Timestamp.fromDate(todoDate)
    }

    this.todoStatus = todoStatus || Constants.TO_DO_STATUS.OPEN.key
    this.todoOrderBy = todoOrderBy || Constants.TO_DO_ORDER_BY.O2N
  }
}

export class TodoListBuilder extends Template {
  id?: string | null
  title?: string
  publishStatus?: PublishStatus
  createdTime?: Date
  lastSequence?: number
  createdByUser?: User | null
  updatedByUser?: User | null
  location?: Location | null
  threadId?: string | null
  businessId?: string | null
  todoStatus?: TodoStatus
  enableTodoDateTime?: boolean = false
  todoDate?: Date
  todoOrderBy?: OrderBy
  isChanged?: boolean
  updatedTime?: Date
  tasks?: TaskBuilder[]
  setInit() {
    if (!this.id) {
      this.title = ''
      this.publishStatus = Constants.PUBLISH_STATUS.DRAFT
      this.lastSequence = -1
      this.id = null
      this.threadId = null
      this.businessId = null
      this.todoStatus = Constants.TO_DO_STATUS.OPEN.key
      this.enableTodoDateTime = false
      this.todoOrderBy = Constants.TO_DO_ORDER_BY.O2N
      this.location = null
      this.isChanged = false
    }
    return this
  }

  setId(id: string) {
    this.id = id
    return this
  }

  setTitle(title: string) {
    this.title = title
    return this
  }

  setCreatedByUser(user: User) {
    this.createdByUser = user
    return this
  }

  setUpdatedByUser(user: User) {
    this.updatedByUser = user
    return this
  }

  setLocation(location: Location) {
    this.location = location
    return this
  }

  setLastSequence(seq: number) {
    this.lastSequence = seq
    return this
  }

  setPublishStatus(status: PublishStatus) {
    this.publishStatus = status
    return this
  }

  setThreadId(threadId: string) {
    this.threadId = threadId
    return this
  }

  setBusinessId(businessId: string) {
    this.businessId = businessId
    return this
  }

  setTodoStatus(todoStatus: TodoStatus) {
    this.todoStatus = todoStatus
    return this
  }

  setEnableTodoDateTime(enableTodoDateTime: boolean) {
    this.enableTodoDateTime = enableTodoDateTime
    return this
  }

  setTodoDate(todoDate: Date) {
    this.todoDate = todoDate
    return this
  }

  setTodoOrderBy(todoOrderBy: OrderBy) {
    this.todoOrderBy = todoOrderBy
    return this
  }

  // to identify change in the item (not need to pass it to new object)
  setIsChanged(isChanged: boolean) {
    this.isChanged = isChanged
    return this
  }

  convertToTodoList(obj: TodoList): TodoListBuilder {
    if (obj) {
      this.id = obj.id
      this.createdTime = obj?.createdTime?.toDate()
      this.publishStatus = obj.publishStatus
      this.createdByUser = obj.createdByUser ? obj.createdByUser : null
      this.lastSequence = obj.lastSequence
      this.title = obj.title || ''
      this.updatedTime = obj.updatedTime?.toDate()
      this.location = obj.location ? obj.location : null
      this.threadId = obj.threadId || null
      this.businessId = obj.businessId
      this.updatedByUser = obj.updatedByUser ? obj.updatedByUser : null

      this.todoStatus = obj.todoStatus || Constants.TO_DO_STATUS.OPEN.key
      this.enableTodoDateTime = obj.enableTodoDateTime

      this.todoDate =
        obj.todoDate?.toDate() || DateTimeUtils.getCurrentDate().toDate()

      this.todoOrderBy = obj.todoOrderBy || Constants.TO_DO_ORDER_BY.O2N
    }

    return this
  }

  buildForUpdate(todoList: TodoListBuilder) {
    return this.getClone(todoList)
      .setCreatedByUser(undefined)
      .setLastSequence(undefined)
      .build()
  }

  getClone(todoList: TodoListBuilder) {
    return Object.assign(
      Object.create(Object.getPrototypeOf(todoList)),
      todoList
    )
  }

  build() {
    return new TodoList(
      this.id,
      this.publishStatus,
      this.createdByUser,
      this.lastSequence,
      this.title,
      this.location,
      this.threadId,
      this.businessId,
      this.updatedByUser,
      this.todoStatus,
      this.enableTodoDateTime,
      this.todoDate,
      this.todoOrderBy
    )
  }
}

export class Task extends FirebaseCollection {
  publishStatus: PublishStatus
  createdByUser?: User | null
  description?: string | null
  updatedByUser?: User
  sequence?: number
  title?: string | null
  taskStatus: TaskStatus
  taggedUsers?: TaggedUsers
  taggedEffectiveUserIds?: string[]
  taskStatusChangedByUser: any
  taskId?: string | null
  todoListId?: string | null
  localRefId?: string | null
  placeholderText: string
  enablePeriod: boolean
  period: FirebasePeriod | null
  location?: FirebaseLocation | null
  index?: number
  createdTime?: Timestamp | null
  updatedTime?: Timestamp | null
  constructor(
    publishStatus = Constants.PUBLISH_STATUS.DRAFT,
    todoListId: string | null = null,
    taskId: string | null = null,
    taskStatus: TaskStatus | null = Constants.TASK_STATUS.OUTSTANDING.key,
    createdByUser: User | null = null,
    sequence: number | null = null,
    title: string | null = '',
    taggedUsers: TaggedUsers | null = null,
    localId: string | null = null,
    placeholderText = '',
    updatedByUser: User | null = null,
    description: string | null = null,
    enablePeriod = false,
    period: Period | null = null,
    taskStatusChangedByUser: User | null = null,
    location: Location | null = null,
    index = -1
  ) {
    super()
    this.publishStatus = publishStatus

    if (createdByUser) {
      this.createdByUser = createdByUser
    }
    this.description = description || ''

    if (updatedByUser) {
      this.updatedByUser = updatedByUser
    }
    if (sequence) {
      this.sequence = sequence
    }

    this.title = title
    this.taskStatus = taskStatus || Constants.TASK_STATUS.OUTSTANDING.key

    if (taggedUsers) {
      const taggedUsersObj: TaggedUsers = {}
      const taggedUsersEffectiveUserIds = []
      for (const taggedUser of Object.values(taggedUsers)) {
        if (taggedUser.effectiveUserId) {
          taggedUsersObj[taggedUser.effectiveUserId] = taggedUser
          taggedUsersEffectiveUserIds.push(taggedUser.effectiveUserId)
        }
      }
      this.taggedUsers = taggedUsersObj
      this.taggedEffectiveUserIds = taggedUsersEffectiveUserIds
    }
    if (taskStatusChangedByUser) {
      this.taskStatusChangedByUser = taskStatusChangedByUser
    }

    this.taskId = taskId || null
    this.todoListId = todoListId || null

    if (localId) {
      this.localRefId = localId
    }

    this.placeholderText = placeholderText

    if (enablePeriod && period) {
      this.enablePeriod = enablePeriod
      this.period = {
        allDay: period.allDay,
        startDate: Timestamp.fromDate(period.startDate),
        endDate: Timestamp.fromDate(period.endDate),
      }
    } else {
      this.enablePeriod = false
      this.period = null
    }

    if (location) {
      const obj = { ...this.location }
      const { coordinates, ...rest } = location
      if (coordinates) {
        obj.coordinates = new GeoPoint(
          coordinates.latitude,
          coordinates.latitude
        )
      }

      this.location = { ...obj, ...rest }
    }

    if (index) {
      this.index = index
    }
  }
}

export class TaskBuilder extends Template {
  title?: string | null
  localId: any
  taskStatus?: TaskStatus | null
  sequence?: number
  createdTime?: Date | null
  index?: number
  createdByUser?: User | null
  updatedByUser?: User | null
  publishStatus?: PublishStatus
  taggedUsers?: TaggedUsers
  todoListId?: string | null
  taskId?: string | null
  placeholderText?: string
  description?: string | null
  enablePeriod?: boolean
  period?: Period | null
  taskStatusChangedByUser?: User | null
  location?: Location | null
  isChanged?: boolean
  updatedTime?: Date | null
  setInit() {
    this.title = ''
    this.localId = Util.generateUUID()
    this.taskStatus = Constants.TASK_STATUS.OUTSTANDING.key
    this.sequence = 0
    this.index = -1
    this.createdByUser = null
    this.updatedByUser = null
    this.publishStatus = Constants.PUBLISH_STATUS.DRAFT
    this.taggedUsers = {}
    this.todoListId = null
    this.taskId = null
    this.placeholderText = ''
    this.description = ''
    this.enablePeriod = false
    this.period = null
    this.taskStatusChangedByUser = null
    this.location = null
    this.isChanged = false
    this.updatedTime = null

    return this
  }

  setIndex(index: number) {
    this.index = index
    return this
  }

  setTaskStatus(status?: TaskStatus) {
    this.taskStatus = status
    return this
  }

  setTitle(title: string) {
    this.title = title
    return this
  }

  setCreatedByUser(user: User) {
    this.createdByUser = user
    return this
  }

  setUpdatedByUser(user: User) {
    this.updatedByUser = user
    return this
  }

  setSequence(seq: number) {
    this.sequence = seq
    return this
  }

  setPublishStatus(status: PublishStatus) {
    this.publishStatus = status
    return this
  }

  setTaggedUsers(taggedUsers: TaggedUsers) {
    this.taggedUsers = taggedUsers
    return this
  }

  setTodoListId(todoListId: string) {
    this.todoListId = todoListId
    return this
  }

  setTaskId(taskId: string) {
    this.taskId = taskId
    return this
  }

  setLocalId(id: string) {
    this.localId = id
    return this
  }

  setPlaceholderText(txt: string) {
    this.placeholderText = txt
    return this
  }

  setDescription(txt: string) {
    this.description = txt
    return this
  }

  setEnablePeriod(isEnabled: boolean) {
    this.enablePeriod = isEnabled
    return this
  }

  setPeriod(period: Period) {
    if (this.enablePeriod) {
      this.period = period
    } else {
      this.period = null
    }

    return this
  }

  setTaskStatusChangedByUser(user: User) {
    this.taskStatusChangedByUser = user
    return this
  }

  setLocation(location: Location) {
    this.location = location
    return this
  }

  // to identify change in the item (not need to pass it to new object)
  setIsChanged(isChanged: boolean) {
    this.isChanged = isChanged
    return this
  }

  convertToTask(obj: Task) {
    if (obj) {
      this.createdByUser = obj.createdByUser ? obj.createdByUser : null
      this.createdTime = obj.createdTime ? obj.createdTime?.toDate() : null
      this.localId = obj.localRefId
      this.taskId = obj.taskId || null

      this.sequence = obj.sequence
      this.publishStatus = obj.publishStatus || Constants.PUBLISH_STATUS.DRAFT
      this.taskStatus = obj.taskStatus || null
      this.title = obj.title || null
      this.description = obj.description || null
      this.todoListId = obj.todoListId || null
      this.updatedByUser = obj.updatedByUser ? obj.updatedByUser : null
      this.placeholderText = obj.placeholderText || ''
      this.updatedTime = obj.updatedTime ? obj.updatedTime?.toDate() : null
      if (obj.taggedUsers) {
        const taggedUsersObj = obj.taggedUsers || {}

        const convertedUsers: TaggedUsers = {}

        for (const prop in taggedUsersObj) {
          const user = taggedUsersObj[prop]
          if (user?.effectiveUserId) {
            convertedUsers[prop] = user
          }
        }

        this.taggedUsers = convertedUsers
      }

      this.enablePeriod = obj.enablePeriod
      this.period = this.enablePeriod ? ({} as Period) : null
      if (obj.period) {
        this.period = {
          allDay: obj.period?.allDay || false,
          startDate: obj.period?.startDate?.toDate(),
          endDate: obj.period?.endDate?.toDate(),
        }
      }

      this.taskStatusChangedByUser = obj.taskStatusChangedByUser
        ? obj.taskStatusChangedByUser
        : null

      this.location = obj.location
        ? ({
            ...obj.location,
          } as Location)
        : null
    }
    return this
  }

  buildForUpdate(task: TaskBuilder) {
    return this.getClone(task)
      .setCreatedByUser(undefined)
      .setLocalId(undefined)
      .setTaskStatusChangedByUser(undefined)
      .setSequence(undefined)
      .build()
  }

  getClone(task: TaskBuilder) {
    return Object.assign(Object.create(Object.getPrototypeOf(task)), task)
  }

  build() {
    return new Task(
      this.publishStatus,
      this.todoListId,
      this.taskId,
      this.taskStatus,
      this.createdByUser,
      this.sequence,
      this.title,
      this.taggedUsers,
      this.localId,
      this.placeholderText,
      this.updatedByUser,
      this.description,
      this.enablePeriod,
      this.period,
      this.taskStatusChangedByUser,
      this.location,
      this.index
    )
  }
}
