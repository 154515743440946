import { TabProps } from './Tab'
import {
  DocumentData,
  DocumentReference,
  GeoPoint,
  Timestamp,
} from 'firebase/firestore'
import { Coordinate } from './Business'
import { TaskBuilder, TodoListBuilder } from '../prototypes/TodoList'
import { GalleryItem, GalleyType, UploadStatus } from './Files'
import { UploadCategory } from 'aws-sdk/clients/devicefarm'

export enum FirebaseCollections {
  ToDoListCollection = 'todoLists',
  TaskCollection = 'tasks',
  DeletedTaskCollection = 'deletedTasks',
  TaskStatusChangeHistory = 'taskStatusChangeHistory',
  DeletedToDoListCollection = 'deletedToDoLists',
  DeletedTodoListTasksCollection = 'deletedTodoListsTasks', // These tasks removed because of todo list deletion
}

export enum TaskGalleryTab {
  media = 'media',
  docs = 'docs',
  clips = 'clips',
}

export interface UploadTaskGalleryItem {
  recordKey: string
  galleryType: GalleyType
  uploadCategory?: UploadCategory
  uploadedByUserId: string
  objectId: string
  uploadStatus?: UploadStatus
  uploadingUrl?: string
  thumbUrl?: string
  uploadingStatusValue?: number
  fileName?: string
  file?: File
  tab?: TaskGalleryTab
  gtUpdatedTime: string
  gtCreatedTime: string
}
export interface TaskMedia {
  businessId: string
  effectiveUserId: string
  imageType: string
  index?: number
  mediaType: string
  messageId: string
  mimeType: string
  source: string
  sourceId: string
  sourceStatus: string
  tab?: TaskGalleryTab
  threadId?: string
  url: string
  userId: string
  clipId?: string
  clipUrl?: string
}

export interface User {
  userId: string
  firstName: string
  lastName: string
  profilePic: string
  roleTemplateDisplayName: string
  businessName: string
  businessId: string
  effectiveUserId: string
  phoneNumber: string
  email: string
  affiliationType?: string
}

export enum PublishStatus {
  draft = 'draft',
  publish = 'publish',
}
export enum TodoStatus {
  open = 'OPEN',
  close = 'CLOSE',
}
export enum TaskFilters {
  TaskStatus = 'TASK_STATUS',
  TaskAssignee = 'TASK_ASSIGNEE',
}

export enum TaskStatus {
  outStanding = 'OUTSTANDING',
  completed = 'COMPLETED',
}

export type TaskStatusObject = {
  key: TaskStatus
  name: string
  des: string
}

export type TaskStatusKeyValPair = {
  [key: string]: TaskStatusObject
}

export type Location = {
  suburb?: string
  coordinates?: Coordinate
  name?: string
  state?: string
}

export type FirebaseLocation = {
  suburb?: string
  coordinates?: GeoPoint
  name?: string
  state?: string
}

export interface TaggedUsers {
  [effectiveUserId: string]: User
}

export type Period = { allDay: boolean; startDate: Date; endDate: Date }
export type FirebasePeriod = {
  allDay: boolean
  startDate: Timestamp
  endDate: Timestamp
}

export type TodoListScreenProps = {
  todoList?: TodoListBuilder
  task?: TaskBuilder
}
export type TodoListScreenParaProps = {
  isNew: boolean
  todoListId?: string
  taskId?: string
}
export enum TaskMediaType {
  photo = 'photo',
  video = 'video',
  empty = 'empty-clip-slide',
}

export type TodoListRightPanelScreensType = {
  screenKey: TodoListRightPanelScreenKeys
  todoList: TodoListBuilder
  todoListTask?: TaskBuilder
}

export enum TodoListRightPanelScreenKeys {
  TO_DO_LIST = 'TO_DO_LIST', //only apply for chat screen
  TO_DO_LIST_TASK_DETAILS = 'TO_DO_LIST_TASK_DETAILS',
  TO_DO_LIST_DETAILS = 'TO_DO_LIST_DETAILS',
  TO_DO_LIST_TASK_GALLERY_CLIP_CREATE = 'TO_DO_LIST_TASK_GALLERY_CLIP_CREATE',
}

export enum TodoListTaskRightPanelScreenKeys {
  TO_DO_LIST_TASK_DETAIL = 'TO_DO_LIST_TASK_DETAIL',
  TO_DO_LIST_TASK_ASSIGNEE = 'TO_DO_LIST_TASK_ASSIGNEE',
  TO_DO_LIST_TASK_ADD_MEDIA = 'TO_DO_LIST_TASK_ADD_MEDIA',
}

export type NavigationTodoListDetailsProps = {
  todoList: TodoListBuilder
}
export type NavigationTodoListTaskDetailsProps = {
  todoList: TodoListBuilder
  task: TaskBuilder
}

export type TodoListTaskRef = {
  [key: string]: { reference: DocumentReference<DocumentData> }
}

export type TodoTaskCreateResponse = {
  message: string
  todoListBuild: TodoListBuilder
  taskBuild?: TaskBuilder
  isNewTask?: boolean
  isNewTodoList?: boolean
  forceBack?: boolean
}
export type TodoTaskBulkCreateResponse = {
  message: string
  todoListBuild: TodoListBuilder
  taskBuild?: TaskBuilder[]
  isNewTask?: boolean
  isNewTodoList?: boolean
  forceBack?: boolean
}

export type TodoTaskDeleteResponse = {
  taskBuild?: TaskBuilder
}

export enum TodoCentralTabKeys {
  YourTodos = `Your to-do's`,
  Drafts = 'Drafts',
}

export enum TaskGalleryTabKeys {
  Media = 'Media',
  Docs = 'Docs',
  Clips = 'Clips',
}
export interface TaskGalleryTabComponentProps extends TabProps {
  tabKey: TaskGalleryTabKeys
}

export interface TaskGalleryTabProps {
  task: TaskBuilder
  onPressItem: () => void
}

export interface TodoCentralTab extends TabProps {
  tabKey: TodoCentralTabKeys
  title: string
  icon: { name: string }
  indicator: boolean
  activeTextColor?: string
  inactiveTextColor?: string
}

export enum TaskCentralTabKeys {
  Outstanding = 'Outstanding',
  Completed = 'Completed',
}

export type TaskCentralTab = {
  tabKey: TaskCentralTabKeys
  title: string
  icon: { name: string }
  indicator: boolean
  activeTextColor?: string
  inactiveTextColor?: string
}

export type TaskFilterValue = { filterType: TaskFilters; value: any }
export type TaskFiltersKeyValPair = {
  [key: string]: TaskFilterValue
}

export type TaskFilterSection = {
  key: string
  title: string
  hasScroll: boolean
  data: any[]
}

export enum TaskGalleryMediaCategory {
  MEDIA = 'media',
  DOCS = 'docs',
  CLIPS = 'clips',
}

export type TaskGalleryGroupItem = {
  data: {
    key: string
    data: GalleryItem[]
  }
  title: string
}

export type TaskGalleryTabScreenProps = {
  tabKey: TaskGalleryTabKeys
  title: string
  icon: { name: string }
  indicator: boolean
  activeTextColor: string
  inactiveTextColor: string
}
