import { useEffect, useState } from 'react'
import { View } from 'react-native'
import { AppColors } from '@tealsapp/teals-components'
import StyleSheet from 'react-native-media-query'
import TabSwitcher from '../../components/tabView'
import YourClips from './tabs/YourClips'
import { Clip, ClipCreateAction } from '../../types/Files'
import ClipCreateDrawer from './clipCreateDrawer'
import { HomeSection } from '../../types/Home'
import { useDeleteClip } from '../../hooks/clip/useMutateClip'
import Util from '../../common/Util'
import { ClipDeleteProgress } from '../../types/ClipCentral'

const { styles } = StyleSheet.create({
  containerStyle: {
    flex: 1,
    backgroundColor: AppColors.common.rm_grey1,
  },
})

type ClipsCentralSideBarProps = {
  onPressClip: (clip: Clip) => void
  onSectionChange: (section: HomeSection) => void
  clipDeletingProgress: (deletingClip: ClipDeleteProgress) => void
  deletedClipId: (clipId: string) => void
  onBackPress: () => void
}

const ClipsCentralSideBar = (props: ClipsCentralSideBarProps) => {
  const {
    onPressClip,
    onSectionChange,
    clipDeletingProgress,
    deletedClipId,
    onBackPress,
  } = props
  const tabs = [
    {
      tabKey: '1',
      title: 'Your collections',
      icon: { name: 'Clip' },
      indicator: false,
    },
  ]
  const [activeTab, setActiveTab] = useState(tabs[0].tabKey)

  const [isVisibleClipCreationDrawer, setIsVisibleClipCreationDrawer] =
    useState(false)
  const [deletingClip, setDeletingClip] = useState<string>('')
  const [isDeletingClip, setIsDeletingClip] = useState<boolean>(false)

  useEffect(() => {
    if (deletingClip.length > 0) {
      setIsDeletingClip(true)
      deletedClipId(deletingClip)
    }
  }, [deletingClip])

  const { mutate: deleteClip, isLoading: isDeleting } = useDeleteClip(
    onSuccessDeleteClip,
    onErrorDeleteClip
  )

  function onSuccessDeleteClip() {
    clipDeletingProgress({
      isDeleted: true,
    })
    Util.showSuccessMessage('Collection deleted')
    setDeletingClip('')
  }

  function onErrorDeleteClip(error: any) {
    clipDeletingProgress({})
    Util.showErrorMessage(
      error?.response?.data?.message || 'Error on deleting collection'
    )
    setDeletingClip('')
    setIsDeletingClip(false)
  }

  function onPressCreateClipItem(type: ClipCreateAction) {
    setIsVisibleClipCreationDrawer(false)
    if (type === ClipCreateAction.newClip) {
      onSectionChange(HomeSection.CreateNewClip)
    } else if (type === ClipCreateAction.emptyClip) {
      onSectionChange(HomeSection.CreateEmptyClip)
    }
  }

  return (
    <View style={styles.containerStyle}>
      <TabSwitcher
        activeTab={activeTab}
        onChangeActiveTab={(key: string) => {
          setActiveTab(key)
        }}
      >
        {/*TODO enable this later*/}
        {/* <TabHeader>
          {tabs.map((tab) => {
            return (
              <Tab
                key={tab.tabKey}
                {...tab}
                titleProps={{ body_strong: true }}
              />
            )
          })}
        </TabHeader> */}

        {tabs.map((tab, index) => (
          <YourClips
            key={`tab_${index}`}
            tab={tab}
            isDeletingClip={isDeletingClip}
            isFromClipCentral={true}
            onPressClip={onPressClip}
            onLongPressClip={(clipData) => {
              setDeletingClip(clipData.clipId)
              clipDeletingProgress({
                clipId: clipData.clipId,
                isDeleting: true,
              })
              deleteClip(clipData)
            }}
            onPressCreateClip={() => setIsVisibleClipCreationDrawer(true)}
            onBackPress={onBackPress}
          />
        ))}
        {
          <ClipCreateDrawer
            isVisibleClipCreationDrawer={isVisibleClipCreationDrawer}
            setIsVisibleClipCreationDrawer={setIsVisibleClipCreationDrawer}
            onPressCreateClipItem={onPressCreateClipItem}
          />
        }
      </TabSwitcher>
    </View>
  )
}

export default ClipsCentralSideBar
