import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import StyleSheet from 'react-native-media-query'
import {
  Lozenger,
  AppColors,
  ComponentOptions,
  Icon,
  Text,
} from '@tealsapp/teals-components'
import { StorageUtil } from '../common'

const { styles } = StyleSheet.create({
  containerStyle: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: AppColors.common.slate,
    paddingHorizontal: ComponentOptions.SPACES.MEDIUM,
    paddingVertical: ComponentOptions.SPACES.MEDIUM,
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  leftContainerStyle: {
    flexDirection: 'row',
    flex: 3,
    alignItems: 'center',
  },
  rightContainerStyle: {
    flexDirection: 'row',
  },
  leftDeniedContainerStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 2,
  },
})

const LocationPermissionBar = () => {
  const [canAskPermission, setCanAskPermission] = useState<boolean>(
    StorageUtil.getLocationPermissionAsk()
  )

  const [permissionState, setPermissionState] =
    useState<PermissionState | null>(null)

  const [triedToGetPosition, setTriedToGetPosition] = useState<boolean>(false)

  useEffect(() => {
    if (canAskPermission) {
      setPermissionState('prompt')
    }
    navigator.geolocation.getCurrentPosition(
      onSuccessGetPosition,
      onErrorGetPosition
    )
    setTriedToGetPosition(true)
  }, [])

  function onSuccessGetPosition(position: GeolocationPosition) {
    if (position.coords.latitude && position.coords.longitude) {
      setPermissionState('granted')
      closeLocationPermisionBar()
    } else if (canAskPermission) {
      setPermissionState('prompt')
    } else {
      setPermissionState('denied')
    }
  }

  function onErrorGetPosition() {
    if (canAskPermission) {
      setPermissionState('denied')
    }
  }

  const closeLocationPermisionBar = () => {
    StorageUtil.setLocationPermissionAsk(false)
    setCanAskPermission(false)
  }

  async function requestLocationPermission() {
    try {
      const result = await navigator.permissions.query({ name: 'geolocation' })
      setPermissionState(result.state)
    } catch (er: any) {
      onError(er)
    }
  }

  function onError(er?: GeolocationPositionError) {
    if (er?.code === GeolocationPositionError.PERMISSION_DENIED) {
      setPermissionState('denied')
    } else if (er?.code === GeolocationPositionError.POSITION_UNAVAILABLE) {
      setPermissionState('prompt')
    }
  }

  if (canAskPermission && triedToGetPosition) {
    if (permissionState === 'denied') {
      return (
        <View style={styles.containerStyle}>
          <View style={styles.leftDeniedContainerStyle}>
            <Icon
              fill={AppColors.common.black}
              name="Error"
              marginRight={ComponentOptions.SPACES.SMALL}
              onPress={() => closeLocationPermisionBar()}
            />
            <Text
              body_strong
              StyleProps={{ textWrap: 'wrap' }}
              color={AppColors.common.black}
            >
              {
                'Raymmer needs your permission to enable location, looks like you have denied the location permission. Please enable it using browser settings'
              }
            </Text>
          </View>
        </View>
      )
    } else if (permissionState === 'prompt') {
      return (
        <View style={styles.containerStyle}>
          <View style={styles.leftContainerStyle}>
            <Icon
              fill={AppColors.common.black}
              name="Error"
              marginRight={ComponentOptions.SPACES.SMALL}
              onPress={() => closeLocationPermisionBar()}
            />
            <Text
              body_strong
              StyleProps={{ textWrap: 'wrap' }}
              color={AppColors.common.black}
            >
              {'Raymmer needs your permission to enable location'}
            </Text>
          </View>

          <View style={styles.rightContainerStyle}>
            <Lozenger
              buttonColor={AppColors.common.primary}
              message={'Enable Location'}
              textProps={{
                color: AppColors.common.white,
              }}
              type={ComponentOptions.BUTTON_TYPES.SOLID}
              paddingVertical={ComponentOptions.SPACES.EXTRA_SMALL}
              paddingHorizontal={ComponentOptions.SPACES.MEDIUM}
              onPress={requestLocationPermission}
            />
          </View>
        </View>
      )
    }
  }
  return null
}

export default LocationPermissionBar
