import React, { createContext, useContext, useEffect } from 'react'
import { ActivityIndicator, TouchableOpacity, View } from 'react-native'
import type {
  TabPanelProps,
  TabSwitcherProps,
  TabContextProps,
  TabHeaderProps,
  TabProps,
  ChatTabProps,
} from '../../types/Tab'

import {
  AppColors,
  Icon,
  Text,
  ComponentOptions,
} from '@tealsapp/teals-components'
import StyleSheet from 'react-native-media-query'
import { Constants } from '../../common'

const { styles } = StyleSheet.create({
  tabHeaderContainerStyle: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  tabContainerStyle: {
    flex: 1,
    alignItems: 'center',
    borderBottomColor: AppColors.common.concrete,
    borderBottomWidth: 1,
  },
  chatTabContainerStyle: {
    flex: 1,
    alignItems: 'center',
    paddingRight: ComponentOptions.SPACES.SMALL,
    justifyContent: 'center',
  },

  tabInnerStyle: {
    borderBottomColor: AppColors.common.transparent,
    borderBottomWidth: 2,
    marginBottom: -1,
  },
  chatTabInnerStyle: {
    backgroundColor: AppColors.common.concrete,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: ComponentOptions.SPACES.MEDIUM,
    paddingVertical: ComponentOptions.SPACES.SMALL,
    borderRadius: ComponentOptions.BORDER_RADIUS.DEFAULT,
    flexDirection: 'row',
    marginBottom: ComponentOptions.SPACES.SMALL,
  },
  tabInnerActiveStyle: {
    borderBottomColor: AppColors.common.primary,
  },
  chatTabInnerActiveStyle: {
    backgroundColor: AppColors.common.primary,
  },
  tabItemStyle: {
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    paddingBottom: ComponentOptions.SPACES.SMALL,
  },
  tabLoadingStyle: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  badge: {
    position: 'absolute',
    top: -1,
    left: -1,
    backgroundColor: AppColors.common.primary,
    width: ComponentOptions.SPACES.SMALL,
    height: ComponentOptions.SPACES.SMALL,
    borderRadius: 4,
  },
  unreadTabIndicator: {
    marginLeft: ComponentOptions.SPACES.EXTRA_SMALL,
    height: ComponentOptions.SPACES.MEDIUM,
    width: ComponentOptions.SPACES.MEDIUM,
    borderRadius: ComponentOptions.SPACES.SMALL,
    alignItems: 'center',
    justifyContent: 'center',
  },
  containText: { maxWidth: 90 },
})

const DataContext = createContext<TabContextProps | null>(null)

function Tab(props: TabProps) {
  const {
    icon,
    title,
    titleProps,
    tabKey,
    activeTextColor,
    inactiveTextColor,
    indicator,
    containText,
  } = props
  const { activeTab, onChangeActiveTab } = useContext(
    DataContext
  ) as TabContextProps

  const tabInnerStyle = [
    styles.tabInnerStyle,
    activeTab === tabKey ? styles.tabInnerActiveStyle : {},
  ]
  const textColor =
    activeTab === tabKey
      ? activeTextColor || AppColors.common.subtitleText
      : inactiveTextColor || AppColors.common.concrete
  return (
    <TouchableOpacity
      style={styles.tabContainerStyle}
      onPress={() => onChangeActiveTab(tabKey)}
    >
      <View style={tabInnerStyle}>
        <View style={[styles.tabItemStyle, containText && styles.containText]}>
          {icon && <Icon {...icon} fill={textColor} />}
          <Text
            body_strong
            color={textColor}
            StyleProps={{
              paddingLeft: icon ? ComponentOptions.SPACES.SMALL : 0,
            }}
            textProps={
              containText && {
                numberOfLines: 1,
                ellipsizeMode: 'tail',
              }
            }
            {...titleProps}
          >
            {title}
          </Text>
          {indicator && <View style={styles.badge} />}
        </View>
      </View>
    </TouchableOpacity>
  )
}

function TabChatHeader(props: ChatTabProps) {
  const { title, tabKey, unreadCount, index } = props
  const { activeTab, onChangeActiveTab } = useContext(
    DataContext
  ) as TabContextProps

  const isActiveTab = activeTab === tabKey

  const tabInnerStyle = [
    styles.chatTabInnerStyle,
    isActiveTab ? styles.chatTabInnerActiveStyle : {},
  ]
  const textColor = isActiveTab ? AppColors.common.white : AppColors.common.dark
  return (
    <TouchableOpacity
      style={[
        styles.chatTabContainerStyle,
        index === 0 ? { paddingLeft: ComponentOptions.SPACES.SMALL } : {},
      ]}
      onPress={() => onChangeActiveTab(tabKey)}
    >
      <View style={tabInnerStyle}>
        <Text
          label
          color={textColor}
          textTransform={ComponentOptions.TEXT_TRANSFORM_OPTIONS.UPPERCASE}
        >
          {title}
        </Text>
        {unreadCount > 0 && (
          <View
            style={[
              styles.unreadTabIndicator,
              {
                backgroundColor: isActiveTab
                  ? AppColors.common.white
                  : AppColors.common.primary,
              },
            ]}
          >
            <Text
              body_small_bold
              color={
                isActiveTab ? AppColors.common.primary : AppColors.common.white
              }
            >
              {unreadCount > Constants.MAX_DISPLAY_COUNT.NOTIFICATIONS
                ? `+${Constants.MAX_DISPLAY_COUNT.NOTIFICATIONS}`
                : unreadCount}
            </Text>
          </View>
        )}
      </View>
    </TouchableOpacity>
  )
}

function TabHeader({ children }: TabHeaderProps) {
  return <View style={styles.tabHeaderContainerStyle}>{children}</View>
}

function TabPanel({ displayKey, children }: TabPanelProps) {
  const { activeTab } = useContext(DataContext) as TabContextProps
  return (
    <React.Fragment>
      {activeTab === displayKey ? children : null}
    </React.Fragment>
  )
}

function TabSwitcher(props: TabSwitcherProps) {
  const { activeTab, children, isLoading, onChangeActiveTab } = props

  useEffect
  return (
    <DataContext.Provider value={{ activeTab, onChangeActiveTab }}>
      {isLoading ? (
        <View style={styles.tabLoadingStyle}>
          <ActivityIndicator color={AppColors.common.primary} size={'large'} />
        </View>
      ) : (
        children
      )}
    </DataContext.Provider>
  )
}

export default TabSwitcher
export { Tab, TabPanel, TabHeader, TabChatHeader }
