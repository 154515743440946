import { TouchableOpacity, View, ViewStyle } from 'react-native'
import StyleSheet from 'react-native-media-query'
import { Clip, GalleryItemProps } from '../types/Files'
import {
  Text,
  ComponentOptions,
  AppColors,
  Shimmer,
} from '@tealsapp/teals-components'
import LinearGradient from 'react-native-linear-gradient'
import { Util, Constants } from '../common'
import Image from './media/Image'
import Avatar from './Avatar'
import React from 'react'

const clipWidth = Constants.SCREEN_LAYOUT_DEFAULT_SIZES.RIGHT_PANEL * 0.3
const { styles } = StyleSheet.create({
  containerStyle: {
    width: clipWidth,
  },
  coverImageStyle: {
    width: '100%',
    height: '100%',
  },
  clipContainerStyle: {
    flex: 1,
    borderRadius: ComponentOptions.BORDER_RADIUS.DEFAULT,
    overflow: 'hidden',
  },
  clipOverlayContainerStyle: {
    position: 'absolute',
    zIndex: 1000,
    overflow: 'hidden',
    padding: ComponentOptions.SPACES.SMALL,
    justifyContent: 'space-between',
  },
  clipOverlayContainerTransparentStyle: {
    position: 'absolute',
    zIndex: 1000,
    overflow: 'hidden',
  },
  clipIconWrapper: {
    justifyContent: 'flex-start',
    flexDirection: 'row',
    width: '100%',
  },
  clipIconWithRightIconWrapper: {
    justifyContent: 'space-between',
  },
})

interface ClipItemProps extends GalleryItemProps {
  item?: Clip
  index?: number
  columns?: number
  width?: number
  height?: number
  padding?: number
  containerStyle?: ViewStyle
  TouchableComponent?: React.Component<any>
  gradientColor1?: string
  gradientColor2?: string
  isLoading?: boolean
  theme?: any
}

const MemorizedImage = React.memo(Image)
const MemorizedAvatar = React.memo(Avatar)

const ClipItem = (props: ClipItemProps) => {
  const {
    item,
    index,
    columns,
    onPressItem,
    onLongPressItem,
    width: passedWidth,
    height: passedHeight,
    padding: passedPadding,
    containerStyle: passedContainerStyle,
    RightIcon,
    TouchableComponent: passedTouchableComponent,
    gradientColor1,
    gradientColor2,
    isLoading,
    theme,
  } = props
  const { width } = Util.getWindowDimensions()

  const padding = passedPadding || ComponentOptions.SPACES.MEDIUM

  const clipContainerWidth = passedWidth
    ? passedWidth
    : (width < Constants.BREAK_POINT
        ? width - padding
        : Constants.SCREEN_LAYOUT_DEFAULT_SIZES.RIGHT_PANEL) * 0.5

  const paddings = Util.getPaddings(columns || 1, padding, index || 0)
  const clipContainerHeight = passedHeight
    ? passedHeight
    : clipContainerWidth * 1.3
  const containerStyle = {
    width: clipContainerWidth,
    height: clipContainerHeight,
    ...paddings,
    ...passedContainerStyle,
  }

  const clipOverlayContainerStyle = {
    width: clipContainerWidth - (paddings.paddingLeft + paddings.paddingRight),
    height:
      clipContainerHeight - (paddings.paddingTop + paddings.paddingBottom),
  }

  if (isLoading) {
    return (
      <View style={[styles.containerStyle, containerStyle]}>
        <View style={styles.clipContainerStyle}>
          <Shimmer
            key={`loadingHighlights`}
            width={clipOverlayContainerStyle.width}
            height={clipOverlayContainerStyle.height}
            borderRadius={ComponentOptions.BORDER_RADIUS.DEFAULT}
            theme={theme}
          />
        </View>
      </View>
    )
  } else {
    const TouchableComponent = passedTouchableComponent
      ? passedTouchableComponent
      : TouchableOpacity
    return (
      <TouchableComponent
        style={[styles.containerStyle, containerStyle]}
        onPress={() => item && onPressItem(item)}
        onLongPress={() => item && onLongPressItem(item.clipId)}
      >
        <View style={styles.clipContainerStyle}>
          <LinearGradient
            style={[
              styles.clipOverlayContainerTransparentStyle,
              clipOverlayContainerStyle,
            ]}
            colors={[
              gradientColor1 || AppColors.common.transparent,
              gradientColor2 || AppColors.common.dark,
            ]}
            locations={[0.5, 1]}
          ></LinearGradient>
          <View
            style={[
              styles.clipOverlayContainerStyle,
              clipOverlayContainerStyle,
            ]}
          >
            <View
              style={[
                styles.clipIconWrapper,
                RightIcon ? styles.clipIconWithRightIconWrapper : {},
              ]}
            >
              <MemorizedAvatar
                s3Key={item.businessLogo}
                imageFetchType={Constants.IMAGE_FETCH_TYPE.PHOTO}
                avatarProps={{
                  altText: Util.getAcronym(item.businessName),
                  size: ComponentOptions.AVATAR_SIZE.EXTRA_SMALL,
                  uniqueId: item.businessId,
                  hasBorder: true,
                  borderColor: AppColors.common.primary,
                }}
              />
              {RightIcon ? RightIcon(item) : null}
            </View>

            <Text
              body
              textProps={{
                numberOfLines: 1,
                ellipsizeMode: 'tail',
              }}
              color={AppColors.common.white}
            >
              {item.clipTitle}
            </Text>
          </View>

          <MemorizedImage
            size={Constants.IMAGE_SIZES.STORY_THUMB}
            s3Key={item.coverImage}
            imageProps={{ style: styles.coverImageStyle }}
            imageFetchType={Constants.IMAGE_FETCH_TYPE.PHOTO}
            mediaNotAvailablePlaceholderUri={require('../images/not-available-clip.png')}
            mediaNotSupportedPlaceholderUri={require('../images/not-supported-clip.png')}
          />
        </View>
      </TouchableComponent>
    )
  }
}

export default ClipItem
