import { useEffect, useRef, useState } from 'react'
import { Pressable, TouchableOpacity, View } from 'react-native'
import StyleSheet from 'react-native-media-query'
import { fetchMediaS3Key } from '../../common/S3Util'
import ReactPlayer from 'react-player'
import { AppColors, ComponentOptions, Icon } from '@tealsapp/teals-components'
import MediaController from './MediaController'

const { styles } = StyleSheet.create({
  videoPlayerWrapper: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: ComponentOptions.SPACES.MEDIUM,
    width: '100%',
    backgroundColor: AppColors.common.darkDrop,
  },
  videoComponent: {
    alignSelf: 'center',
    flex: 1,
    resizeMode: 'contain',
  },
  videoPlayerContainer: {
    flex: 1,
  },
  centerPlayButtonStyle: {
    width: 100,
    height: 100,
    borderRadius: 100,
    position: 'absolute',
    zIndex: 1000,
    backgroundColor: AppColors.common.darkDrop,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

type CenterButtonProps = {
  onPlay: () => void
}

const CenterButton = (props: CenterButtonProps) => {
  const { onPlay } = props
  return (
    <TouchableOpacity onPress={onPlay} style={styles.centerPlayButtonStyle}>
      <Icon name={'DetailedPlay'} size={40} />
    </TouchableOpacity>
  )
}

type VideoPlayerProps = {
  s3Key?: string
  source?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  videoProps: any
  hideControllers?: boolean
  isPlaying?: boolean
  onEnd?: () => void
  wrapperStyles?: any
}
const VideoPlayer = (props: VideoPlayerProps) => {
  const {
    s3Key,
    source,
    videoProps,
    hideControllers,
    isPlaying: passedIsPlaying,
    onEnd,
    wrapperStyles,
  } = props
  const videoRef = useRef(null)
  const [videoUri, setVideoUri] = useState<string | undefined>(undefined)

  const [isPlaying, setIsPlaying] = useState<boolean>(passedIsPlaying || false)
  const [currentSeek, setCurrentSeek] = useState<number>(0)
  const [totalDuration, setTotalDuration] = useState<number>(0)
  const [isCompleted, setIsCompleted] = useState<boolean>(false)

  useEffect(() => {
    if (totalDuration > 0 && currentSeek === totalDuration) {
      setIsCompleted(true)
      setIsPlaying(false)
    }
  }, [currentSeek])

  useEffect(() => {
    if (!!s3Key && s3Key.length > 0) {
      fetchMediaS3Key(s3Key)
        .then((uri: string) => {
          setVideoUri(uri)
        })
        .catch(() => {
          setVideoUri(undefined)
        })
    }
  }, [s3Key])

  useEffect(() => {
    if (!!source) {
      setVideoUri(source)
    }
  }, [source])

  function onPlay() {
    if (totalDuration === 0) {
      setTotalDuration(videoRef?.current?.getDuration() || 0)
    }
    setIsCompleted(false)
    setIsPlaying(true)
  }
  function onPause() {
    setIsPlaying(false)
  }

  function onProgress(state: {
    played: number
    playedSeconds: number
    loaded: number
    loadedSeconds: number
  }) {
    const { playedSeconds } = state
    setCurrentSeek(playedSeconds)
  }

  function handleSeekChange(value: number) {
    if (videoRef?.current) {
      videoRef.current.seekTo(value)
    }
  }

  if (videoUri) {
    const { width, height, style } = videoProps
    const Wrapper = hideControllers ? Pressable : View
    return (
      <Wrapper
        style={[styles.videoPlayerWrapper, !!wrapperStyles && wrapperStyles]}
        onPress={() => {
          isPlaying ? onPause() : onPlay()
        }}
      >
        <View style={styles.videoPlayerContainer}>
          <ReactPlayer
            url={videoUri}
            width={width}
            height={height}
            style={{ ...styles.videoComponent, ...style }}
            ref={videoRef}
            playing={isPlaying}
            onProgress={onProgress}
            muted={passedIsPlaying}
            onEnded={onEnd}
            controls={false}
          />
        </View>

        {!isPlaying && !hideControllers && (
          <CenterButton onPlay={() => onPlay()} />
        )}
        {!hideControllers && (
          <MediaController
            onPlay={() => onPlay()}
            onPause={() => onPause()}
            isPlaying={isPlaying}
            currentSeek={currentSeek}
            maxSeek={totalDuration}
            handleSeekChange={(value: number) => handleSeekChange(value)}
            isCompleted={isCompleted}
          />
        )}
      </Wrapper>
    )
  }
  return null
}

export default VideoPlayer
