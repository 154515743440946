import React from 'react'
import { View, StyleProp, ViewStyle } from 'react-native'
import StyleSheet from 'react-native-media-query'
import {
  ComponentOptions,
  Navigation,
  AppColors,
} from '@tealsapp/teals-components'
import { NavigationProps } from '../types/Common'
import { Constants, Util } from '../common'
const { styles } = StyleSheet.create({
  panelWrapper: {
    width: `${Constants.SCREEN_LAYOUT_DEFAULT_SIZES.RIGHT_PANEL}px`,
    '@media (max-width: 1024px)': {
      width: '100vw',
    },
    borderLeftWidth: 1,
    backgroundColor: AppColors.common.rm_grey1,
    borderLeftColor: AppColors.common.rm_grey1,
  },
  closeIconWrapper: {
    width: '100%',
    alignItems: 'flex-start',
    marginTop: ComponentOptions.SPACES.LARGE,
    marginBottom: ComponentOptions.SPACES.MEDIUM,
    marginHorizontal: ComponentOptions.SPACES.MEDIUM,
  },
  navigationWrapperStyle: {
    paddingVertical: ComponentOptions.SPACES.LARGE,
  },
})

type RightPanelProps = {
  containerStyles?: StyleProp<ViewStyle>
  children?: React.ReactNode
  navigationProps?: NavigationProps
}

function RightPanel({
  containerStyles,
  children,
  navigationProps,
}: RightPanelProps) {
  const { width } = Util.getWindowDimensions()
  function getResponsiveRightPanelHight() {
    if (width <= Constants.BREAK_POINT) {
      return `calc(100vh - 51px)` // because of the top menu
    } else {
      return `100vh`
    }
  }
  return (
    <View
      style={[
        containerStyles,
        styles.panelWrapper,
        { height: getResponsiveRightPanelHight() },
      ]}
    >
      {navigationProps && (
        <View style={styles.navigationWrapperStyle}>
          <Navigation
            titleStyle={{ color: AppColors.common.black }}
            {...navigationProps}
          />
        </View>
      )}
      {children}
    </View>
  )
}

export default RightPanel
