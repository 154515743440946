import { useEffect, useState } from 'react'
import {
  MobileNumber,
  Button,
  Globals as GTGlobals,
  AppColors,
  Text,
  Spacer,
  ComponentOptions,
  CountrySelection,
  SelectionList,
} from '@tealsapp/teals-components'
import SignUpContainerWrapper from '../../components/signUp/signUpContainerDefaultWrapper'

import { ForgotPasswordStep1Props } from '../../types/ForgotPassword'
import {
  useResetPassword,
  useValidateConfirmation,
  validateConfirmationParam,
} from '../../hooks/auth/useAuth'
import { Util } from '../../common'
import { ServerError } from '../../types/Common'
import ErrorCodes from '../../common/ErrorCodes'
import { View, StyleSheet } from 'react-native'
import { allowedCountryList } from '../../remoteConfig'
import { AllowedCountry } from '../../types/Settings'

const styles = StyleSheet.create({
  textWrapper: {
    alignSelf: 'center',
  },
  countryListContainer: {
    backgroundColor: AppColors.common.white,
    border: `1px solid ${AppColors.common.yellow}`,
    borderRadius: 8,
    position: 'absolute',
    marginTop: 65,
    zIndex: 1000,
    width: '100%',
  },
})

const ForgotPasswordStep1 = (props: ForgotPasswordStep1Props) => {
  const {
    onCompletionCallback,
    onBackPress,
    onUserNotConfirmed,
    onPasswordNotSet,
  } = props
  const [txtMobileNumber, setMobileNumber] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [showCountryList, setShowCountryList] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState<AllowedCountry>(
    allowedCountryList[0]
  )
  const { mutate: onResetPassword, isLoading } = useResetPassword(
    (data: any, username: string) => {
      onCompletionCallback({
        mobileNumber: username,
      })
    },
    (er) => Util.showErrorMessage(er?.message || '')
  )

  const {
    mutate: onValidateConfirmation,
    isLoading: isValidatingConfirmation,
  } = useValidateConfirmation(
    (data: any, variable: validateConfirmationParam) => {
      const isUserConfirmed = data?.data?.['user-confirmed'] || false
      if (isUserConfirmed) {
        onResetPassword(variable.phoneNumber)
      } else {
        onUserNotConfirmed({ mobileNumber: variable.phoneNumber })
      }
    },
    (er: ServerError, variables: validateConfirmationParam) => {
      if (
        er.type === ErrorCodes.GT_GENERAL.GTValidationError.type &&
        (er['error-code'] === ErrorCodes.GT_GENERAL.GTValidationError.code ||
          er.code === ErrorCodes.GT_GENERAL.GTValidationError.code)
      ) {
        onResetPassword(variables.phoneNumber)
      } else if (er.type === ErrorCodes.GT_SIGNUP.GTIncompleteSignupException) {
        //No password
        onPasswordNotSet({
          mobileNumber: variables.phoneNumber,
        })
      } else if (
        er.type === ErrorCodes.GT_GENERAL.GTResourceNotFoundException
      ) {
        //Cognito account not found
        Util.showErrorMessage(
          `No user account found for ${variables.phoneNumber}`
        )
      } else {
        Util.showErrorMessage(er?.message || '')
      }
    }
  )

  useEffect(() => {
    if (errorMessage !== '') {
      setErrorMessage('')
    }
  }, [txtMobileNumber])

  const onPressContinue = () => {
    onValidateConfirmation({
      phoneNumber: GTGlobals.formatMobileNumber(
        txtMobileNumber,
        selectedCountry
      ),
    })
  }

  return (
    <SignUpContainerWrapper
      title={`Reset password`}
      titleColor={AppColors.common.black}
    >
      <>
        <MobileNumber
          value={txtMobileNumber}
          onChangeText={(txt: string) => setMobileNumber(txt)}
          error={errorMessage}
          selectedCountry={selectedCountry}
          leftIcon={
            <CountrySelection
              flag={selectedCountry.flag}
              onPress={() => setShowCountryList(!showCountryList)}
            />
          }
        />
        {showCountryList && (
          <SelectionList
            allowedCountryList={allowedCountryList}
            selectedCountry={selectedCountry}
            onPressCountry={(country: AllowedCountry) => {
              setSelectedCountry(country)
              setShowCountryList(false)
            }}
            containerStyles={styles.countryListContainer}
          />
        )}
      </>

      <View style={styles.textWrapper}>
        <Text
          body_small
          color={AppColors.common.white}
        >{`Happens to the best of us. Enter your mobile number to get started`}</Text>
      </View>
      <Spacer />
      <Button
        onPress={onPressContinue}
        title="Continue"
        disabled={
          !GTGlobals.isValidMobileNumber(txtMobileNumber, selectedCountry)
        }
        loading={isLoading || isValidatingConfirmation}
      />
      <Spacer size={ComponentOptions.SPACES.LARGEST} />
      <Button
        onPress={onBackPress}
        title="BACK TO LOGIN"
        type={ComponentOptions.BUTTON_TYPES.CLEAR}
        buttonColor={AppColors.common.yellow}
        disabled={isLoading || isValidatingConfirmation}
      />
    </SignUpContainerWrapper>
  )
}

export default ForgotPasswordStep1
