import { useEffect, useState } from 'react'
import Constants from '../../common/Constants'

export default () => {
  const [installPrompt, setInstallPrompt] = useState<any>(null)
  const isAppInstalled = Boolean(Number(localStorage.getItem('isAppInstalled')))

  const installPWA = () => {
    return new Promise((resolve, reject) => {
      if (isAppInstalled)
        return reject('App is already installed on your computer')
      if (installPrompt) {
        installPrompt.prompt()
        installPrompt.userChoice.then((choiceResult: any) => {
          if (choiceResult.outcome === 'accepted') {
            localStorage.setItem('isAppInstalled', '1')
          }
          setInstallPrompt(null)
        })
        resolve(installPrompt.userChoice)
      } else {
        reject(
          'You need to be on Chrome, Edge, Opera and Brave browsers in order to download the desktop version'
        )
      }
    })
  }

  const beforeInstallPrompt = (event: Event) => {
    event?.preventDefault()
    setInstallPrompt(event)
  }

  useEffect(() => {
    setTimeout(() => {
      beforeInstallPrompt((window as any)?.beforeInstallPrompt as Event)
    }, Constants.TIMEOUT.CLAIM_SUCCESS_WAIT_LOADING)
  }, [])

  return {
    installPWA,
  }
}
