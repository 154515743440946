import { useEffect, useRef } from 'react'
import { Image, SafeAreaView, ScrollView, View } from 'react-native'
import StyleSheet from 'react-native-media-query'
import { ComponentOptions, Text, AppColors } from '@tealsapp/teals-components'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Nav from '../navigation/NavigationKeys'
import OrgRoleSelection from '../containers/login/OrgRoleSelection'
import { useUserContext } from '../context/UserContext'
import { Util } from '../common'

const { styles } = StyleSheet.create({
  wrapper: {
    flex: 1,
  },
  scrollWrapper: {
    position: 'relative',
  },
  scrollContainerStyles: {
    flexGrow: 1,
  },
  wrapperOuter: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxWidth: '75rem',
    marginHorizontal: 'auto',
  },
  wrapperStyle: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: ComponentOptions.SPACES.LARGER * 2,
    '@media (max-width: 1020px)': {
      paddingVertical: ComponentOptions.SPACES.LARGER,
    },
    '@media (max-width: 375px)': {
      paddingVertical: 0,
    },
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: '33.33333%',
    maxWidth: '33.33333%',
    '@media (max-width: 1024px)': {
      flexBasis: '100%',
      maxWidth: '100%',
    },
  },
  mainWrapper: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: '66.66667%',
    maxWidth: '66.66667%',
    alignItems: 'flex-start',
    paddingStart: ComponentOptions.SPACES.LARGE * 8,
    '@media (max-width: 1024px)': {
      flexBasis: '100%',
      maxWidth: '100%',
      paddingStart: 0,
    },
  },
  logoStyle: {
    width: '500px',
    height: '700px',

    '@media (max-width: 1024px)': {
      width: '335px',
      height: '420px',
    },
  },
  footerStyle: {
    position: 'absolute',
    width: '100%',
    bottom: ComponentOptions.SPACES.MEDIUM,
    flexDirection: 'row',
    justifyContent: 'center',
  },
})

const RoleSelectionPage = () => {
  const { activeRole, isAuthenticated } = useUserContext()
  const dateRef = useRef(new Date())
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    handleNavigation()
  }, [activeRole])

  const onOrgSelectionCompletion = () => {
    goToHome()
  }

  const handleNavigation = () => {
    if (activeRole !== null && isAuthenticated) {
      goToHome()
    }
  }

  const goToHome = () => {
    const redirect = searchParams.get('redirect')
    if (redirect !== null) {
      const url = `/${Util.parseURL(redirect)}`
      navigate(url)
    } else {
      navigate(Nav.HOME)
    }
  }

  const onAddNewOrgRole = () => {
    navigate(Nav.ADD_NEW_ACCOUNT)
  }

  return (
    <SafeAreaView style={styles.wrapper}>
      <ScrollView
        style={styles.scrollWrapper}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.scrollContainerStyles}
      >
        <View style={styles.wrapperOuter}>
          <View style={styles.wrapperStyle}>
            <OrgRoleSelection
              showAddNew={true}
              onCompletionCallback={onOrgSelectionCompletion}
              onAddNewOrgRole={onAddNewOrgRole}
            />
          </View>
          <View style={[styles.wrapperStyle, styles.mainWrapper]}>
            <Image
              source={require('../images/signin-hero.svg')}
              style={styles.logoStyle}
              resizeMode={'contain'}
            />
          </View>
        </View>
        <View style={styles.footerStyle}>
          <Text small color={AppColors.common.black}>
            {`© ${dateRef.current.getFullYear()} by ${process.env.RAYMMER}™`}
          </Text>
        </View>
      </ScrollView>
    </SafeAreaView>
  )
}

export default RoleSelectionPage
