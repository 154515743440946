import { StyleSheet, FlatList, TouchableOpacity } from 'react-native'
import { ComponentOptions, AppColors } from '@tealsapp/teals-components'
import { StreamVideoParticipant } from '@stream-io/video-react-sdk'
import OtherParticipantPreview from './OtherParticipantPreview'

const styles = StyleSheet.create({
  participantWrapper: {
    width: '190px',
    height: '100px',
    marginLeft: 'auto',
    marginRight: 'auto',
    overflow: 'hidden',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: ComponentOptions.SPACES.SMALL,
    borderRadius: ComponentOptions.SPACES.SMALL,
    backgroundColor: AppColors.common.tertiary,
  },
  highlightPreviewStyles: {
    borderWidth: 2,
    borderColor: AppColors.common.primary,
  },
})

type ParticipantsVideosListProps = {
  participants: StreamVideoParticipant[]
  mainPreviewParticipant: StreamVideoParticipant | null
  onPressParticipant: (participant: StreamVideoParticipant) => void
}

const ParticipantsVideosList = ({
  participants,
  mainPreviewParticipant,
  onPressParticipant,
}: ParticipantsVideosListProps) => {
  return (
    <FlatList
      data={participants}
      extraData={participants}
      keyExtractor={(item) => `stream-${item.sessionId}-${item.userId}`}
      showsVerticalScrollIndicator={false}
      renderItem={({ item }) => {
        return (
          <TouchableOpacity
            style={[
              styles.participantWrapper,
              mainPreviewParticipant?.userId === item.userId &&
                mainPreviewParticipant.sessionId === item.sessionId &&
                styles.highlightPreviewStyles,
            ]}
            onPress={() => onPressParticipant(item)}
          >
            <OtherParticipantPreview
              id={'streamVideo-MemberList'}
              participant={item}
              avatarsize={ComponentOptions.AVATAR_SIZE.MEMBER_WIDGET}
              videoScale="scale(-1.1,1.1)"
            />
          </TouchableOpacity>
        )
      }}
    />
  )
}

export default ParticipantsVideosList
