import { StreamVideoClient, User } from '@stream-io/video-react-sdk'
import {
  fetchStreamUser,
  getStreamAuthTokenForBackground,
} from './useStreamAuth'
import { useEffect, useState } from 'react'
import { useCallManagerContext } from '../../context/CallManagerContext'

type StreamClientProps = {
  activeOrgId?: string
  effectiveUserId?: string
}

export const useStreamClient = ({
  activeOrgId,
  effectiveUserId,
}: StreamClientProps) => {
  const { setStreamClient } = useCallManagerContext()

  const [client, setClient] = useState<StreamVideoClient | null>()

  useEffect(() => {
    const initClient = async () => {
      if (!activeOrgId || !effectiveUserId) {
        setClient(null)
        return
      }

      setClient(null)

      const response = await fetchStreamUser(effectiveUserId)
      const token = await Promise.resolve(
        getStreamAuthTokenForBackground(effectiveUserId)
      )

      const streamUser = response?.data?.user || null

      if (!!activeOrgId && !!effectiveUserId && !!streamUser) {
        const apiKey = process.env.STREAM_API_KEY as string

        const user: User = {
          id: streamUser?.['effective-user-id'] || '',
          type: 'authenticated',
          name: streamUser?.name || '',
          image: streamUser?.['profile-pic-url'] || '',
          custom: {
            'user-id': streamUser?.['user-id'] || '',
            'profile-pic-key': streamUser?.['profile-pic'] || '',
            'org-id': streamUser?.['org-id'] || '',
            'org-name': streamUser?.['org-name'] || '',
          },
        }

        const streamClient = new StreamVideoClient({ apiKey, user, token })

        setClient(streamClient)
        setStreamClient(streamClient)
      }
    }

    initClient()
  }, [activeOrgId, effectiveUserId])

  return client
}
