import { ActiveRole } from '../types/Common'

//---------------Active Theme----------------
const getActiveTheme = async (defaultId = '') => {
  try {
    const value = await localStorage.getItem('activeTheme')
    if (value) {
      return value
    } else if (defaultId) {
      return defaultId
    }
    return null
  } catch (e) {
    // error reading value
    return null
  }
}

const setActiveTheme = async (id: string) => {
  try {
    await localStorage.setItem('activeTheme', id)
  } catch (e) {
    // error reading value
  }
}

//----------------ActiveRole-----------------
const getActiveRole = async (): Promise<ActiveRole | null> => {
  try {
    const value = await localStorage.getItem('activeRole')
    if (value) {
      return JSON.parse(value) as ActiveRole
    }
    return null
  } catch (e) {
    // error reading value
    return null
  }
}

const setActiveRole = async (role: ActiveRole): Promise<void> => {
  try {
    const value = JSON.stringify(role)
    await localStorage.setItem('activeRole', value)
  } catch (e) {
    // error reading value
    console.log('error saving', e)
  }
}

const removeActiveRole = async (): Promise<void> => {
  try {
    await localStorage.removeItem('activeRole')
  } catch (e) {
    // error reading value
  }
}

//----------------Active Thread---------------
const setActiveThreadId = async (threadId: string) => {
  try {
    await localStorage.setItem('activeThreadId', threadId)
  } catch (e) {
    // error reading value
  }
}

const getActiveThreadId = async () => {
  try {
    const value = await localStorage.getItem('activeThreadId')
    if (value) {
      return value
    }
    return null
  } catch (e) {
    // error reading value
    return null
  }
}

const removeActiveThreadId = async () => {
  try {
    await localStorage.removeItem('activeThreadId')
  } catch (e) {
    // error reading value
  }
}

//Location permision ask
const getLocationPermissionAsk = (): boolean => {
  try {
    const value = localStorage.getItem('askLocationPermision')
    if (value === null) {
      return true
    } else if (value !== undefined) {
      return value === '1'
    } else {
      return true
    }
  } catch (e) {
    // error reading value
    return true
  }
}
const setLocationPermissionAsk = (isEnable: boolean) => {
  try {
    localStorage.setItem('askLocationPermision', isEnable ? '1' : '0')
  } catch (e) {}
}

const STORAGE_KEYS = {
  SHOW_TRANSLATE_BANNER: 'show.translate.banner',
  TRANSLATE_ENTIRE_CHATS: 'translate.entire.chats',
  SELECTED_TRANSLATION_LANGUAGE: 'selected.translation.language',
}

export default {
  getActiveTheme,
  setActiveTheme,
  getActiveRole,
  setActiveRole,
  removeActiveRole,
  setActiveThreadId,
  getActiveThreadId,
  removeActiveThreadId,
  setLocationPermissionAsk,
  getLocationPermissionAsk,
  STORAGE_KEYS,
}
