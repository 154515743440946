import { View } from 'react-native'
import useScreenType from '../../common/DimensionsUtil'
import { Shimmer, ComponentOptions } from '@tealsapp/teals-components'
import ClipPreview from '../../components/ClipPreview'
import { useFetchClip } from '../../hooks/clip/useFetchClip'

import StyleSheet from 'react-native-media-query'

import { ScreenType } from '../../types/Common'
import { Clip } from '../../types/Files'
import { Util } from '../../common'
import { useUserContext } from '../../context/UserContext'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import NavigationKeys from '../../navigation/NavigationKeys'

const { styles } = StyleSheet.create({
  containerStyle: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
})

type ClipPreviewProps = {
  clipId?: string
}

const ClipPreviewContainer = (props: ClipPreviewProps) => {
  const { clipId } = props
  const navigate = useNavigate()
  const { isAuthenticated, activeRole } = useUserContext()

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(`${NavigationKeys.LOGIN}?redirect=clip/${clipId}`)
    } else if (!activeRole) {
      navigate(`${NavigationKeys.ROLE}?redirect=clip/${clipId}`)
    }
  }, [isAuthenticated, activeRole])

  const screenType = useScreenType()
  const { data, isLoading } = useFetchClip(
    clipId || '',
    clipId !== undefined || isAuthenticated,
    (er: any) => onErrorClipLoading(er)
  )

  const getClipDimensions = (screenTypeVal: ScreenType) => {
    let width: any = 375
    let height: any = 675

    if (screenTypeVal === ScreenType.Desktop) {
      width = 375
      height = '90%'
    } else if (screenTypeVal === ScreenType.Mobile) {
      width = '90%'
      height = '90%'
    }

    return { width, height }
  }

  const onErrorClipLoading = (er: any) => {
    Util.showErrorMessage(
      er?.message ||
        (isAuthenticated
          ? 'Something wrong in collection loading'
          : `Authentication required to view this collection`)
    )
  }

  const { width, height } = getClipDimensions(screenType)

  return (
    <View style={styles.containerStyle}>
      {!isLoading && (
        <ClipPreview height={height} width={width} clipObj={data as Clip} />
      )}
      {isLoading && (
        <Shimmer
          width={width}
          height={height}
          borderRadius={ComponentOptions.SPACES.MEDIUM}
        />
      )}
    </View>
  )
}

export default ClipPreviewContainer
