import { useEffect, useRef, useState } from 'react'
import {
  AvatarGroup as AvatarGroupBase,
  avatarGroupPropTypes,
} from '@tealsapp/teals-components'
import PropTypes from 'prop-types'
import { fetchMedia } from '../common/AxiosUtil'
import { Constants } from '../common'

type AvatarProps = {
  altText: string
  isOnline: boolean
  uniqueId?: string
}

type AvatarGroupProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  avatarProps: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  avatarGroupProps: any
}

function AvatarGroup(props: AvatarGroupProps) {
  const avatarProps = props.avatarProps
  const avatarGroupProps = props.avatarGroupProps

  const initialAvatarProps = avatarProps.map((prop: AvatarProps) => {
    return {
      imageUri: null,
      altText: prop.altText,
      isOnline: prop.isOnline,
      uniqueId: prop.uniqueId ? prop.uniqueId : '',
    }
  })

  const isMounted = useRef(false)
  const [fetchedAvatarProps, setFetchedAvatarProps] =
    useState(initialAvatarProps)

  useEffect(() => {
    isMounted.current = true
    loadImages()
      .then((fetched) => {
        if (isMounted.current) {
          setFetchedAvatarProps(fetched)
        }
      })
      .catch(() => {
        // TODO: Add an error reporting here
      })
    return () => {
      isMounted.current = false
    }
  }, [avatarProps])

  async function loadImages() {
    const fetchedAvatarProps = []
    for (const avatarProp of avatarProps) {
      if (isMounted.current) {
        const s3ImageKey = avatarProp.image.s3Key
        const size =
          avatarGroupProps?.size <= 20
            ? Constants.IMAGE_SIZES.AVATAR_SMALL
            : Constants.IMAGE_SIZES.AVATAR_LARGE
        let uri
        if (s3ImageKey) {
          try {
            uri = await fetchMedia(s3ImageKey, size)
          } catch (error) {
            // TODO: Add an error reporting here
          }
        }
        const prop = {
          ...avatarProp,
          imageUri: uri,
          key: avatarProp.image.s3Key,
        }
        fetchedAvatarProps.push(prop)
      }
    }
    return fetchedAvatarProps
  }

  const ids: string = fetchedAvatarProps.map((i: any) => i.key).join(',')
  return (
    <div data-img-ids={ids}>
      <AvatarGroupBase {...avatarGroupProps} avatarProps={fetchedAvatarProps} />
    </div>
  )
}

AvatarGroup.propTypes = {
  avatarGroupProps: PropTypes.shape(avatarGroupPropTypes),
  avatarProps: PropTypes.object,
  imageFetchType: PropTypes.string,
}

export default AvatarGroup
