import { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { QueryClient, QueryClientProvider, useQueryClient } from 'react-query'
import NavigationStack from './navigation/NavigationStack'
import { Hub } from 'aws-amplify'
import {
  ThemeProvider,
  themes,
  FlashMessage,
  ComponentOptions,
} from '@tealsapp/teals-components'
import { ReactQueryDevtools } from 'react-query/devtools'
import {
  useActiveEffectiveUserId,
  useActiveOrgId,
  useActiveUserId,
  UserContextProvider,
  useUserContext,
} from './context/UserContext'
import ContentWrapper from './layout/ContentWrapper'
import { AuthUtil, Constants, MessageUtils, QueryKeys, Util } from './common'
import ClientWebSocket from './common/ClientWebSocket'
import NotificationUtil from './common/NotificationUtil'
import { activate, fetchConfig } from 'firebase/remote-config'
import { remoteConfig } from './remoteConfig'
import { useFetchUnreadThreadsCount } from './hooks/message/useFetchThread'
import { invalidateNotificationQueries } from './hooks/notification/useMutateNotification'
import NotificationTray from './components/NotificationTray'
import { WebsocketNotification } from './types/Notification'
import { useFetchBadgeCount } from './hooks/notification/useFetchNotification'
import notificationSound from './Assets/notification-sound.wav'
import useInstallPrompt from './hooks/pwa/useInstallPrompt'
import {
  CallManagerContextProvider,
  useCallManagerContext,
} from './context/CallManagerContext'
import { useStreamClient } from './hooks/stream/useStreamClient'
import CallWrapper from './containers/StreamCall/CallWrapper'
import { StreamCall, StreamVideo } from '@stream-io/video-react-sdk'
import RingingPopUp from './containers/StreamCall/RingingPopUp'

const notificationPlayerStyles: React.CSSProperties = {
  display: 'none',
}

const styles = StyleSheet.create({
  pwaInstallWrapper: {
    display: 'none',
  },
  callFrame: {
    position: 'absolute',
    opacity: 1,
    width: window.innerWidth - ComponentOptions.SPACES.MEDIUM,
    height: window.innerHeight - ComponentOptions.SPACES.MEDIUM,
    backgroundColor: 'white',
    borderRadius: ComponentOptions.SPACES.SMALL,
    top: ComponentOptions.SPACES.SMALL,
    left: ComponentOptions.SPACES.SMALL,
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)',
  },
  incommingCallWrapper: {
    flex: 1,
    left: 100,
    bottom: 100,
    width: 250,
    zIndex: 2000,
    flexDirection: 'row',
    position: 'absolute',
    height: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: ComponentOptions.SPACES.SMALL,
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.4)',
  },
})

const NotificationAudio = () => {
  return (
    <audio
      style={notificationPlayerStyles}
      id={Constants.NOTIFICATION_SOUND_ID}
    />
  )
}

function Content() {
  const activeUserId = useActiveUserId()
  const activeOrgId = useActiveOrgId()
  const queryClient = useQueryClient()
  const effectiveUserId = useActiveEffectiveUserId()

  const {
    activeTheme,
    isAuthenticated,
    setAuthenticated,
    setFirebaseAuthenticate,
  } = useUserContext()
  const { streamClient, call, setStreamClient } = useCallManagerContext()
  const { data: unreadThreadsCount, refetch } = useFetchUnreadThreadsCount()
  const { data: badgeCount } = useFetchBadgeCount()

  const client = useStreamClient({ activeOrgId, effectiveUserId })

  const [isLoading, setLoading] = useState(true)
  const [chatNotification, setChatNotification] =
    useState<WebsocketNotification>()

  document.addEventListener('visibilitychange', setTitleUnreadCount)

  useEffect(() => {
    fetchConfig(remoteConfig)
    activate(remoteConfig)
    AuthUtil.getCurrentSession()
      .then((session) => {
        setAuthenticated(session?.isValid())
        if (session?.isValid()) {
          window.addEventListener('focus', onWindowFocused)
        }
      })
      .catch(() => {
        setAuthenticated(false)
        setLoading(false)
      })

    Hub.listen('auth', ({ payload: { event } }) => {
      switch (event) {
        case 'signIn': {
          AuthUtil.signInToFirebase((state) => setFirebaseAuthenticate(state))
          break
        }

        case 'signOut': {
          AuthUtil.signOutFromFirebase((state) =>
            setFirebaseAuthenticate(state)
          )
          break
        }
      }
    })

    return () => {
      window.removeEventListener('focus', onWindowFocused)
      !!ws && ws.closeWebSocket()
    }
  }, [])

  useEffect(() => {
    if (isAuthenticated) {
      AuthUtil.signInToFirebase(
        (state) => setFirebaseAuthenticate(state),
        setLoading
      )
    }
  }, [isAuthenticated])

  let ws: ClientWebSocket
  useEffect(() => {
    if (!activeUserId || !activeOrgId) {
      return
    }
    invalidateIndicators()
    if (isAuthenticated) {
      !!!ws && initWebSocket()
      AuthUtil.setSentryUserInfo(activeUserId, activeOrgId)
    } else {
      !!ws && ws.closeWebSocket()
    }
  }, [isAuthenticated, activeUserId, activeOrgId])

  useEffect(() => {
    if (client && isAuthenticated && activeUserId && activeOrgId) {
      setStreamClient(client)
    }
  }, [client, effectiveUserId])

  useEffect(() => {
    const unreadCount = unreadThreadsCount
      ? unreadThreadsCount?.worksites +
        unreadThreadsCount?.teams +
        unreadThreadsCount?.clients
      : 0
    const activeOrgBadgeCount = (badgeCount as any)?.details?.[activeOrgId] || 0
    const totalNotificationCount = unreadCount + activeOrgBadgeCount
    if ('setAppBadge' in navigator && 'clearAppBadge' in navigator) {
      if (navigator.setAppBadge) {
        if (totalNotificationCount > 0) {
          navigator.setAppBadge(unreadCount)
        } else {
          navigator.clearAppBadge()
        }
      }
    }
  }, [unreadThreadsCount, badgeCount])

  function onWindowFocused() {
    setTitleUnreadCount()
    invalidateIndicators()
    !!ws && ws.reconnectIfDisconnected() //Web Socket disconnects if the window is out of focus for more than 5 min
  }

  function setTitleUnreadCount() {
    const unreadCount = unreadThreadsCount
      ? unreadThreadsCount?.worksites +
        unreadThreadsCount?.teams +
        unreadThreadsCount?.clients
      : 0
    if (unreadCount === 0 || document.visibilityState === 'visible') {
      document.title = `${Constants.TITLE}`
    } else {
      document.title = `(${unreadCount < 100 ? unreadCount : '99+'}) ${
        Constants.TITLE
      }`
    }
  }

  function initWebSocket() {
    ws = ClientWebSocket.getWebSocket(activeUserId, activeOrgId)
    ws.initiateHeartBeat()
    ws.subscribe(onNotification)
  }

  function invalidateIndicators() {
    MessageUtils.invalidateNotificationIndicatorQueries(
      queryClient,
      activeOrgId
    )
  }

  function playNotificationSound() {
    const audio = document.getElementById(
      Constants.NOTIFICATION_SOUND_ID
    ) as HTMLAudioElement
    if (!audio) {
      return
    }
    audio.src = notificationSound
    audio.volume = 0.3
    audio.autoplay = true
    audio.muted = false
    audio.onended = () => {
      audio.autoplay = false
    }
  }

  function onNotification(received: any) {
    const orgId =
      received?.['business-id'] || received?.['org-id'] || activeOrgId
    if (received?.['message-id']) {
      queryClient.invalidateQueries([QueryKeys.UNREAD_THREADS_COUNT, '', orgId])
      refetch()
    }
    if (
      !!received?.['thread-id'] &&
      !!received?.['thread-type'] &&
      received['thread-id'] !==
        window.location.pathname?.split('/').toReversed()?.[0]
    ) {
      queryClient.invalidateQueries([
        QueryKeys.THREADS,
        MessageUtils.getThreadFilterFromType(received['thread-type']),
        orgId,
      ])
    }

    if (received.source === Constants.WEB_SOCKET_MESSAGE_ACTIONS.NOTIFICATION) {
      //handle forground notifications
      if (!received?.['message-id']) {
        playNotificationSound()
      }
      invalidateNotificationQueries(queryClient, orgId)
      const scenario = NotificationUtil.findNotificationScenario(received)
      if (
        scenario ===
        Constants.NOTIFICATION_SCENARIO.OWNER_ACCEPTED_REQUEST_NOTIFY_WORKER
      ) {
        queryClient.invalidateQueries(QueryKeys.MY_PROFILE)
        queryClient.invalidateQueries(QueryKeys.USER_ORG_ROLES)
      } else if (
        scenario ===
        Constants.NOTIFICATION_SCENARIO.OWNER_REJECTED_REQUEST_NOTIFY_WORKER
      ) {
        queryClient.invalidateQueries(QueryKeys.USER_ORG_ROLES)
      } else if (
        received['message-id'] &&
        received['user-id'] !== activeUserId
      ) {
        const currentLocation = location.pathname?.split('/')
        const excludedMessageTypes = ['sent-acknowledgement']

        const isGroupInvitation = Boolean(
          received['notification-scenario'] ===
            'new-invitation-to-join-group' &&
            received['delivery-status'] &&
            received['message-notifiction-title']
        )

        const notificationPermission = Boolean(
          Number(localStorage.getItem('askNotificationPermision'))
        )

        const isSetNotification = isGroupInvitation
          ? true
          : !(
              excludedMessageTypes.includes(received['message-type']) ||
              currentLocation.includes('chats')
            )

        if (isSetNotification && notificationPermission) {
          setChatNotification(received)
        }
      }
    }
  }

  return (
    <ThemeProvider theme={themes[activeTheme]}>
      <NotificationAudio />
      <ContentWrapper isLoading={isLoading} fillScreen={true}>
        <NavigationStack />
        <FlashMessage
          floating={true}
          duration={30000}
          testID={'flashMessage'}
        />
        <NotificationTray notification={chatNotification} />
        {streamClient ? (
          <StreamVideo client={streamClient}>
            {call ? (
              <StreamCall call={call}>
                <CallWrapper />
              </StreamCall>
            ) : (
              <></>
            )}
            <RingingPopUp />
          </StreamVideo>
        ) : (
          <></>
        )}
      </ContentWrapper>
    </ThemeProvider>
  )
}

export const App = () => {
  const { installPWA } = useInstallPrompt()
  const installPWAApplication = () => {
    installPWA().catch((err) => {
      Util.showWarningMessage(err)
    })
  }

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 5 * 60 * 1000,
      },
    },
  })

  return (
    <QueryClientProvider client={queryClient}>
      <UserContextProvider>
        <CallManagerContextProvider>
          <Content />
          <View style={styles.pwaInstallWrapper}>
            <button id="pwa-download" onClick={installPWAApplication}>
              Download PWA
            </button>
          </View>
          <ReactQueryDevtools initialIsOpen={false} />
        </CallManagerContextProvider>
      </UserContextProvider>
    </QueryClientProvider>
  )
}
