import { View } from 'react-native'

import {
  ComponentOptions,
  Text,
  AppColors,
  ListItem,
  Spacer,
  Icon,
} from '@tealsapp/teals-components'
import CustomBottomDrawer from './CustomBottomDrawer'

type DeleteBottomDrawerProps = {
  title: string
  subTitle: string
  isVisible: boolean
  onModalHide: () => void
  onPress: () => void
}

const DeleteBottomDrawer = ({
  title,
  subTitle,
  isVisible,
  onModalHide,
  onPress,
}: DeleteBottomDrawerProps) => {
  return (
    <CustomBottomDrawer
      isVisible={isVisible}
      isHideRightElement={true}
      onModalHide={onModalHide}
      renderBodyElement={
        <ListItem
          leftElement={
            <Icon name={'Bin'} size={20} fill={AppColors.common.red} />
          }
          title={title}
          titleColor={AppColors.common.red}
          subTitleElement={
            <View>
              <Spacer
                isVertical={false}
                size={ComponentOptions.SPACES.EXTRA_SMALL}
              />
              <Text body_small color={AppColors.common.subtitleText}>
                {subTitle}
              </Text>
            </View>
          }
          onPress={onPress}
        />
      }
    />
  )
}

export default DeleteBottomDrawer
