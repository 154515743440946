import React from 'react'
import { SafeAreaView, View } from 'react-native'
import StyleSheet from 'react-native-media-query'
import { Navigation, AppColors } from '@tealsapp/teals-components'

import {
  ScreenStackContextProvider,
  useNavigation,
  StackSidebarProps,
  Screen,
} from '../context/ScreenStackContext'

const { styles } = StyleSheet.create({
  containerStyle: {
    flex: 1,
    backgroundColor: AppColors.common.rm_grey1,
  },
})

type StackScreenProps = {
  stackProps: StackSidebarProps
  isVisible: boolean
  currentStack: Screen[]
  screen: Screen
  onBack: () => void
}

const StackScreen = (props: StackScreenProps) => {
  const {
    isVisible,
    currentStack,
    stackProps,
    screen,
    onBack: passedOnBack,
  } = props

  const {
    HeaderComponent,
    containerStyle: parentPassedContainerStyle,
    hideHeader: passedHideHeader,
    renderAbsoluteContent,
    extraProps,
  } = stackProps

  const getBackFunction = () => {
    const hasBack = screen?.onBack !== undefined
    const onBack = hasBack ? screen?.onBack : passedOnBack
    return {
      hasBack,
      onBack,
    }
  }

  const backProps = getBackFunction()
  const navProps = screen?.navProps || {}

  const hasCustomNavElement = screen?.navElement !== undefined
  const hideHeader =
    passedHideHeader || hasCustomNavElement || screen.hideHeader || false

  const NavElement = screen?.navElement || View

  const navContainerStyle = screen?.navContainerStyle || {}

  const parentContainerStyle = screen?.containerStyle || {}

  const navigation = useNavigation()
  const CurrentActiveScreen = navigation.currentScreen

  return isVisible ? (
    <SafeAreaView
      style={[
        styles.containerStyle,
        parentPassedContainerStyle,
        parentContainerStyle,
      ]}
    >
      {!hideHeader && !!HeaderComponent && HeaderComponent}
      {!hideHeader && !HeaderComponent && (
        <View style={navContainerStyle}>
          <Navigation
            title={screen?.title}
            back={backProps.hasBack || currentStack.length > 1}
            onBack={backProps.onBack}
            {...navProps}
          />
        </View>
      )}
      {hideHeader && hasCustomNavElement && (
        <View style={navContainerStyle}>
          <NavElement
            title={screen?.title}
            back={backProps.hasBack || currentStack.length > 1}
            onBack={backProps.onBack}
            {...navProps}
          />
        </View>
      )}
      {CurrentActiveScreen ? (
        <CurrentActiveScreen.screen
          {...CurrentActiveScreen.props}
          extraProps={extraProps}
        />
      ) : null}
      {renderAbsoluteContent ? renderAbsoluteContent : null}
    </SafeAreaView>
  ) : null
}

const MemorizedStackScreen = React.memo(StackScreen)

const StackSideBar = (props: StackSidebarProps) => {
  const navigation = useNavigation()

  const currentStack = navigation.currentStack || []

  return (
    <React.Fragment>
      {currentStack.map((screen, i) => (
        <MemorizedStackScreen
          key={`screen-${screen.key}${i}`}
          isVisible={screen.key === navigation.currentScreen?.key}
          currentStack={currentStack}
          screen={screen}
          stackProps={props}
          onBack={navigation.onBack}
        />
      ))}
    </React.Fragment>
  )
}

const StackSidebarWrapper = (props: StackSidebarProps) => {
  const { screenStack } = props
  return (
    <ScreenStackContextProvider screenStack={screenStack}>
      <StackSideBar {...props} />
    </ScreenStackContextProvider>
  )
}

StackSidebarWrapper.defaultProps = {
  containerStyle: {},
}

export default StackSidebarWrapper
