import { ReactElement } from 'react'
import { Location } from './Common'
import { SlideGalleryItem } from './ClipCentral'

export interface GalleryItemProps {
  RightIcon?: (item: GalleryItem) => React.ReactElement | null
  onPressItem: (item: GalleryItem) => void
  onLongPressItem: (item: string) => void
  onRemoveUploadingItem?: (item: GalleryItem) => void
  onRetryUpload?: (item: GalleryItem) => void
}

export interface GallerySectionProps {
  key: string
  RightIcon: (item: GalleryItem) => React.ReactElement | null
  onPressItem: (itemId: GalleryItem) => void
  onLongPressItem: (itemId: string) => void
  onRemoveUploadingItem?: (item: GalleryItem) => void
  onRetryUpload?: (item: GalleryItem) => void
}

export enum UploadCategory {
  document = 'document',
  photo = 'photo',
  video = 'video',
  audio = 'audio',
}

export enum GalleyType {
  video = 'video',
  photo = 'photo',
  audio = 'audio',
  docs = 'docs',
  clips = 'clips',
  todolist = 'todo-lists',
}
export enum GalleryTab {
  media = 'media',
  docs = 'docs',
  clips = 'clips',
  todoLists = 'todo-lists',
}

export enum UploadStatus {
  uploading = 'uploading',
  uploadFailed = 'uploadFailed',
  updatingAPI = 'updatingAPI',
  uploaded = 'uploaded',
}

export enum AcceptedMediaTypes {
  PNG = 'image/png',
  HEIC = 'image/heic',
  HEIF = 'image/heif',
  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  MP4 = 'video/mp4',
  mp3 = 'audio/mp3',
}

export enum AcceptedDocumentTypes {
  application = 'application/*',
  text = 'text/*',
}

export interface UploadItem {
  recordKey: string
  galleryType: GalleyType
  uploadCategory?: UploadCategory
  uploadedByUserId: string
  objectId: string
  uploadStatus?: UploadStatus
  uploadingUrl?: string
  thumbUrl?: string
  uploadingStatusValue?: number
  fileName?: string
  file?: File
  tab?: GalleryTab
  gtUpdatedTime: string
  gtCreatedTime: string
}

export interface GalleryItem extends UploadItem {
  index?: number
}

export type GalleyTabItems = {
  title: string
  data: { key: string; data: GalleryItem[] }[]
}

export type GalleryTabProps = {
  items: GalleyTabItems[]
  fetchNextPage: () => void
  hasNextPage: boolean | undefined
  isFetchingNextPage: boolean
  renderFooter: ReactElement
}

export type AcceptedMedia = {
  PNG: string
  HEIC: string
  HEIF: string
  JPG: string
  JPEG: string
  MP4: string
  mp3: string
}

export type AcceptedDocument = {
  application: string
  text: string
}

/* Media and related Types */

type MediaMetaData = {
  gtUpdatedTime: string
  extension: string
  contentType: string
  gtCreatedTime: string
  origin: string
  businessId: string
  mediaCategory: string
  uploadedByUserId: string
  contentLength: number
  fileName: string
  mediaSource: string
  uploadedByEffectiveUserId: string
  originId: string
  threadId: string
}

export interface Media extends GalleryItem {
  sourceId: string
  mediaType: string
  userId: string
  imageType: string
  source: string
  sourceStatus: string
  effectiveUserId: string
  threadId: string
  businessId: string
  messageId: string
  url: string
  origin?: string
  mediaCategory: string
  originId: string
  mediaSource: string
  metaData?: MediaMetaData
  extension: string
  mimeType: string
}

/* Clip and related Types */
export enum SlideType {
  photo = 'photo',
  video = 'video',
  empty = 'empty-clip-slide',
}
export interface Slide {
  slideSequence: number
  slideId: string
  thumbnailKey: string
  slideKey: string
  slideType: SlideType
  clipId: string
  businessLogo?: string
  businessName?: string
  url?: string
  gtCreatedTime: string
  isEmptySlide?: boolean
}

export enum EntityType {
  business = 'business',
  user = 'user',
}
export type HighlightedEntity = {
  [id: string]: {
    entityType: EntityType
  }
}

enum TaggedStatus {
  approved = 'approved',
  rejected = 'rejected',
}
export interface TaggedEntity {
  taggedStatus: TaggedStatus
  tagEntityType: EntityType
}
export interface TaggedEntityBusiness extends TaggedEntity {
  businessName: string
  handle: string
  businessId: string
  businessLogo: string
}
export interface TaggedEntityUser extends TaggedEntity {
  phoneNumber: string
  profilePic: string
  userId: string
  effectiveUserId: string
  businessId?: string
  firstName: string
  email: string
  lastName: string
}
export type TaggedEntities = {
  [key: string]: TaggedEntityBusiness | TaggedEntityUser
}

export enum ClipCreateAction {
  emptyClip = 'emptyClip',
  newClip = 'newClip',
}

export enum ClipOriginType {
  BUSINESS_PROFILE = 'business-profile',
  PROFILE = 'profile',
  THREAD = 'thread',
}

export enum fileOriginType {
  BUSINESS_PROFILE = 'business-profile',
  PROFILE = 'profile',
  THREAD = 'thread',
}

export enum ImageUploadFolderType {
  IMAGE = 'image',
  PROFILE = 'profile',
}

export interface Clip extends GalleryItem {
  origin: ClipOriginType
  clipTitle: string
  taggedServices: string[]
  location: Location
  coverImage: string
  addToHighlights: boolean
  slides: Slide[]
  admins: string[]
  clipId: string
  gtUpdatedTime: string
  highlightedEntities: { [key: string]: HighlightedEntity }
  businessHandle: string
  threadId: string
  postedBy: string
  clipDescription: string
  taggedEntities: TaggedEntities
  businessId: string
  businessName: string
  businessLogo: string
  clipUrl: string
  sequence: number
  clipPermissions: any
}

/* End: Clip and related Types */

/* Document type */
export interface Document extends GalleryItem {
  effectiveUserId: string
  fileName: string
  imageType: string
  mediaType: string
  messageId: string
  origin: string
  source: string
  sourceId: string
  url: string
  sourceStatus: string
  threadId: string
}

export interface ThreadGalleryTodoList extends GalleryItem {
  gtCreatedTime: string
  todoListId: string
  gtUpdatedTime: string
}
/* End:Document type */

export type CoverImageMedia = {
  imageSource: string
  mimeType?: string
  path?: string
  filename?: string
  file?: File
  objectId?: string
  uploadStatus?: UploadStatus
  galleryType: GalleyType
}

export type SelectedGalleryItem = {
  id: string
  type: string
  item?: GalleryItem
}

export type SelectedSlideGalleryItem = {
  id: string
  slideId: string
  type: string
  item?: SlideGalleryItem
}
