import React, { useRef } from 'react'
import { SafeAreaView, View } from 'react-native'
import StyleSheet from 'react-native-media-query'
import {
  ComponentOptions,
  AppColors,
  MessageNote,
  Icon,
  Spacer,
} from '@tealsapp/teals-components'
import { ProgressStatus } from '../types/Common'
import CustomStatusBar from '../components/CustomStatusBar'
import Logo from '../components/Logo'

const { styles } = StyleSheet.create({
  wrapperStyle: {
    flex: 1,
  },
  layoutMidStyle: {
    flexGrow: 8,
    alignItems: 'center',
  },
  layoutBottomStyle: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-end',
    flexDirection: 'row',
    paddingVertical: ComponentOptions.SPACES.LARGE,
    zIndex: -1,
  },
  logoStyle: {
    paddingTop: ComponentOptions.SPACES.LARGEST,
    paddingBottom: ComponentOptions.SPACES.LARGE,
  },
  selectIconWrapper: {
    width: ComponentOptions.FONT_SIZES_OPTIONS.BUTTON,
    height: ComponentOptions.FONT_SIZES_OPTIONS.BUTTON,
    borderRadius: ComponentOptions.FONT_SIZES_OPTIONS.BUTTON,
    backgroundColor: AppColors.common.primary,
    alignItems: 'center',
    justifyContent: 'center',
  },
  statusBarWrapper: {
    alignItems: 'center',
    alignContent: 'center',
    paddingTop: ComponentOptions.SPACES.LARGE,
  },
  logo: {
    width: 255,
  },
})

type Props = {
  children: React.ReactNode
  showProgress?: boolean
  progressStatus?: ProgressStatus[]
}

const SignUpLayout = (props: Props) => {
  const { children, showProgress, progressStatus } = props
  const dateRef = useRef(new Date())

  const onPressContactUs = () => {}

  return (
    <SafeAreaView style={styles.wrapperStyle}>
      <Spacer size={ComponentOptions.SPACES.LARGEST} />
      {showProgress && progressStatus && (
        <View style={styles.statusBarWrapper}>
          <CustomStatusBar
            barWidth={115}
            leftElement={
              <Icon
                name={'Tick'}
                fill={AppColors.common.white}
                stroke={5}
                size={6}
                wrapperStyles={styles.selectIconWrapper}
              />
            }
            stepCompleteColor={AppColors.common.primary}
            stepIncompleteColor={AppColors.common.white}
            progressStatus={progressStatus}
          />
        </View>
      )}
      <View style={styles.layoutMidStyle}>
        <Logo style={styles.logo} />
        {children}
      </View>
      <View style={styles.layoutBottomStyle}>
        <MessageNote
          arMessages={[
            {
              title: `© ${dateRef.current.getFullYear()} by ${
                process.env.RAYMMER
              }™`,
              color: AppColors.common.black,
            },
            {
              action: () => onPressContactUs(),
              color: AppColors.common.yellow,
              title: 'Contact us',
              paddingLeft: ComponentOptions.SPACES.SMALL,
            },
          ]}
          paddingHorizontal={0}
          paddingVertical={0}
        />
      </View>
    </SafeAreaView>
  )
}

export default SignUpLayout
