import { Profile } from './User'

export enum TradieType {
  worker = 'worker',
  owner = 'owner',
}
export enum ProfileType {
  tradie = 'tradie',
  seeker = 'seeker',
}

export enum UserType {
  worker = 'worker',
  owner = 'owner',
  seeker = 'seeker',
}

export type SignUpResultProps = {
  mobileNumber?: string
  generatedPassword?: string
  firstName?: string
  lastName?: string
  email?: string
  newPassword?: string
  tradieType?: TradieType
  businessId?: string
  userId?: string
  effectiveUserId?: string
}

export type unclaimedBusinessDataProps = {
  businessName?: string
  mobileNumber?: string
  businessId?: string
  email?: string
  firstName?: string
  lastName?: string
  trade?: Trade
  logoS3Key?: string
  businessLogo?: string
  handle?: string
}

export type Trade = {
  'trade-name': string
  'trade-hash': string
}

export type BusinessSearchResult = {
  businessId: string
  businessName: string
  businessLogo: string
  handle: string
  trades: any
}

export type SignUpNotConfirmedProps = {
  onCompletionCallback: (result: SignUpResultProps) => void
  data: SignUpResultProps
  onBack: () => void
  onUserConfirmed: () => void
}
export type SignUpIncompletePasswordProps = {
  onCompletionCallback: (result: SignUpResultProps) => void
  data: SignUpResultProps
  onBack: () => void
  onUserConfirmed: () => void
  onUserNotConfirmed: (result: SignUpResultProps) => void
  onRedirectResetPassword: (phoneNumber: string) => void
}

export type SignUpStep1Props = {
  onCompletionCallback: (result: SignUpResultProps) => void
  onBackPress: () => void
}

export type SignUpStep2Props = {
  onCompletionCallback: () => void
  data: SignUpResultProps
  onBackPress: () => void
}

export type SignUpStep3Props = {
  onCompletionCallback: (result: SignUpResultProps) => void
  data: SignUpResultProps
  isBusinessClaimed: boolean
  businessResult: unclaimedBusinessDataProps
}

export type SignUpStep4Props = {
  onCompletionCallback: (result: SignUpResultProps) => void
  data: SignUpResultProps
  onBackPress?: () => void
  setUserType?: (user: string) => void
  isAddAccount: boolean
  hasASeekerAccount?: boolean
  isUpdatingProfile?: boolean
}

export type SignUpStep5Props = {
  onCompletionCallback: (result: SignUpResultProps) => void
  navigateToBusinessCreate?: (result: SignUpResultProps) => void
  data: SignUpResultProps
  onBackPress: () => void
  isBusinessClaimed?: boolean
  businessResult?: unclaimedBusinessDataProps
  isAddNewAccount?: boolean
  currentUser?: Profile
  selectedBusiness?: (business: BusinessSearchResult) => void
}

export type ShowUnclaimedBusinessProps = {
  goToPasswordScreen: (isClaimed: boolean) => void
  businessResult?: unclaimedBusinessDataProps
}

export enum SignUpIncompleteScreens {
  verifyAccount = 'verify-account',
  setPassword = 'set-password',
}
export enum ForgotPasswordIncompleteScreens {
  setPassword = 'set-password',
}

export enum SignUpFlowPath {
  general = 'general',
  claim = 'claim',
}
