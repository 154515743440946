import { View, StyleSheet } from 'react-native'
import {
  ComponentOptions,
  AppColors,
  Icon,
  Text,
  Spacer,
} from '@tealsapp/teals-components'
import { getParticipantDisplayName } from '../../common/CallUtils'
import { SfuModels, StreamVideoParticipant } from '@stream-io/video-react-sdk'

const styles = StyleSheet.create({
  participantInfoWrapper: {
    position: 'absolute',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: ComponentOptions.SPACES.EXTRA_SMALL,
    left: ComponentOptions.SPACES.EXTRA_SMALL,
    paddingLeft: ComponentOptions.SPACES.SMALL,
    paddingRight: ComponentOptions.SPACES.EXTRA_SMALL,
    paddingTop: ComponentOptions.SPACES.EXTRA_SMALL,
    paddingBottom: ComponentOptions.SPACES.EXTRA_SMALL,
    borderRadius: ComponentOptions.SPACES.EXTRA_SMALL,
    backgroundColor: AppColors.common.modalBackDrop,
  },
})

type ParticipantVideoInfoProps = {
  participant?: StreamVideoParticipant
  isLocalParticipant?: boolean
  localParticipantMicOn?: boolean
}

const ParticipantVideoInfo = ({
  participant,
  isLocalParticipant,
  localParticipantMicOn,
}: ParticipantVideoInfoProps) => {
  return (
    <View style={styles.participantInfoWrapper}>
      <Text
        body_small
        color={AppColors.common.white}
        textProps={{
          numberOfLines: 1,
          ellipsizeMode: 'tail',
        }}
      >
        {isLocalParticipant
          ? 'You'
          : participant
          ? getParticipantDisplayName(participant)
          : ''}
      </Text>
      <Spacer isVertical size={ComponentOptions.SPACES.EXTRA_SMALL} />
      <Icon
        name={
          participant?.publishedTracks?.includes(SfuModels.TrackType.AUDIO) ||
          localParticipantMicOn
            ? 'DetailedMicOn'
            : 'DetailedMicOff'
        }
        size={15}
        fill={
          participant?.publishedTracks?.includes(SfuModels.TrackType.AUDIO) ||
          localParticipantMicOn
            ? AppColors.common.white
            : AppColors.common.red
        }
      />
    </View>
  )
}

export default ParticipantVideoInfo
