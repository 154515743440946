import React from 'react'
import { StyleSheet, View } from 'react-native'
import LogoIcon from './LogoIcon'

const styles = StyleSheet.create({
  innerLoadingContainerStyle: {
    width: '100vw',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

function Loader() {
  return (
    <View style={styles.innerLoadingContainerStyle}>
      <LogoIcon size={200} />
    </View>
  )
}

export default Loader
