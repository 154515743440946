import React from 'react'
import { View } from 'react-native'
import { AppColors, ListItem, Icon } from '@tealsapp/teals-components'
import StyleSheet from 'react-native-media-query'
import CustomBottomDrawer from '../../components/CustomBottomDrawer'
import { ClipCreateAction } from '../../types/Files'

const { styles } = StyleSheet.create({
  clipCreateDrawerWrapperStyle: {
    position: 'absolute',
    bottom: 0,
    height: '100%',
  },
})

type ClipCreateDrawerProps = {
  setIsVisibleClipCreationDrawer: (isVisible: boolean) => void
  isVisibleClipCreationDrawer: boolean
  onPressCreateClipItem: (action: ClipCreateAction) => void
}
const ClipCreateDrawer = (props: ClipCreateDrawerProps) => {
  const {
    setIsVisibleClipCreationDrawer,
    isVisibleClipCreationDrawer,
    onPressCreateClipItem,
  } = props
  return (
    <View style={styles.clipCreateDrawerWrapperStyle}>
      <CustomBottomDrawer
        onModalHide={() => setIsVisibleClipCreationDrawer(false)}
        isVisible={isVisibleClipCreationDrawer}
        renderBodyElement={
          <React.Fragment>
            <ListItem
              leftElement={
                <Icon
                  name={'AddImage'}
                  size={18}
                  fill={AppColors.common.primary}
                />
              }
              testIds={{ listItemOnPressTestId: 'newTeamChatOption' }}
              title={'Create new collection'}
              titleColor={AppColors.common.tertiary}
              onPress={() => onPressCreateClipItem(ClipCreateAction.newClip)}
            />
            <ListItem
              leftElement={
                <Icon
                  name={'EditClip'}
                  size={18}
                  fill={AppColors.common.primary}
                />
              }
              testIds={{ listItemOnPressTestId: 'newTeamChatOption' }}
              title={'Create empty collection'}
              titleColor={AppColors.common.tertiary}
              onPress={() => onPressCreateClipItem(ClipCreateAction.emptyClip)}
            />
          </React.Fragment>
        }
      />
    </View>
  )
}

export default ClipCreateDrawer
