import React, { useCallback, useEffect, useState } from 'react'
import { StyleProp, ViewStyle } from 'react-native'

export type GTNavigationComponentProps = {
  title?: string
  back?: boolean
  onBack?: () => void
  rightButton?: React.ReactNode
}

export type Screen = {
  key: string
  title?: string
  screen: React.FunctionComponent<any>
  props?: any
  navProps?: {
    rightButton?: React.ReactNode
  }
  navElement?: React.ComponentType<GTNavigationComponentProps>
  navContainerStyle?: StyleProp<ViewStyle>
  containerStyle?: StyleProp<ViewStyle>
  onBack?: () => void
  hideHeader?: boolean
}

export type StackSidebarProps = {
  screenStack: Screen[]
  HeaderComponent?: React.ReactNode
  containerStyle?: StyleProp<ViewStyle>
  hideHeader?: boolean
  renderAbsoluteContent?: React.ReactNode
  extraProps?: any
}

type ScreenStackContextType = {
  navigateTo: (screenKey: string, props?: any) => void
  replaceTo: (screenKey: string, props?: any) => void
  onBack: () => void
  onBackToTop?: () => void
  currentScreen?: Screen
  currentStack: Screen[]
}

type ScreenStackProviderProps = {
  children: React.ReactNode
  screenStack: Screen[]
}

const ScreenStackContext = React.createContext<
  ScreenStackContextType | undefined
>(undefined)

const ScreenStackContextProvider = ({
  children,
  screenStack,
}: ScreenStackProviderProps) => {
  const [activeScreenStack, setActiveScreenStack] = useState<Screen[]>(
    screenStack.length > 0 ? [screenStack[0]] : []
  )

  const [currentScreen, setCurrentScreen] = useState<Screen | undefined>()

  useEffect(() => {
    setCurrentScreen(activeScreenStack.at(-1))
  }, [activeScreenStack])

  function navigateTo(screenKey: string, props?: any) {
    const newScreen = screenStack.find((screen) => screen.key === screenKey)
    if (newScreen) {
      if (props) {
        newScreen.props = props
      }

      const findCurrentIndex = activeScreenStack.findIndex(
        (screen) => screen.key === screenKey
      )
      if (findCurrentIndex > -1) {
        setCurrentScreen({ ...newScreen })
      } else {
        const updatedStack = [...activeScreenStack, newScreen]
        setActiveScreenStack(updatedStack)
      }
    } else {
      throw new Error('No specific screen found for given key')
    }
  }

  function replaceTo(screenKey: string, props?: any) {
    const newScreen = screenStack.find((screen) => screen.key === screenKey)
    if (newScreen) {
      if (props) {
        newScreen.props = props
      }
      setActiveScreenStack([newScreen])
    } else {
      throw new Error('No specific screen found for given key')
    }
  }

  function onBack() {
    if (activeScreenStack.length > 1) {
      if (currentScreen) {
        const currentScreenIndex = activeScreenStack.findIndex(
          (screen) => screen.key === currentScreen.key
        )
        if (currentScreenIndex > 0) {
          activeScreenStack.splice(currentScreenIndex)
          setActiveScreenStack([...activeScreenStack])
        }
      } else {
        activeScreenStack.pop()
        setActiveScreenStack([...activeScreenStack])
      }
    }
  }

  function onBackToTop() {
    setActiveScreenStack(screenStack.length > 0 ? [screenStack[0]] : [])
  }

  return (
    <ScreenStackContext.Provider
      value={{
        navigateTo: navigateTo,
        onBack: onBack,
        onBackToTop: onBackToTop,
        currentScreen: currentScreen,
        currentStack: activeScreenStack,
        replaceTo: replaceTo,
      }}
    >
      {children}
    </ScreenStackContext.Provider>
  )
}

export type ScreenNavigationProps = {
  navigateTo: (screenKey: string, props?: any) => void
  replaceTo: (screenKey: string, props?: any) => void
  onBack: () => void
  onBackToTop: () => void
  currentScreen?: Screen
  currentStack: Screen[]
}
const useNavigation = () => {
  const context = React.useContext(ScreenStackContext)
  return {
    navigateTo: context?.navigateTo,
    replaceTo: context?.replaceTo,
    onBack: context?.onBack,
    onBackToTop: context?.onBackToTop,
    currentScreen: context?.currentScreen,
    currentStack: context?.currentStack || [],
  } as ScreenNavigationProps
}

export { ScreenStackContextProvider, useNavigation }
