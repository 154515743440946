import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import Loader from '../components/Loader'
import { useUserContext } from '../context/UserContext'
import ClipsCentralPage from '../pages/ClipsCentral'
import ClipView from '../pages/ClipView'
import DefaultRedirectPage from '../pages/DefaultRedirect'
import ForgotPasswordPage from '../pages/ForgotPassword'
import MagicLinkAuthenticated from '../pages/MagicLinkAuthenticated'
import MagicLinkPendingWorkerInvite from '../pages/MagicLinkPendingWorkerInvite'
import RoleSelectionPage from '../pages/RoleSelection'
import Nav from './NavigationKeys'

//lazy load the different routes
const Login = React.lazy(() => import('../pages/Login'))
const Home = React.lazy(() => import('../pages/Home'))
const Settings = React.lazy(() => import('../pages/Settings'))
const Chat = React.lazy(() => import('../pages/Chat'))
const Find = React.lazy(() => import('../pages/Find'))
const Notifications = React.lazy(() => import('../pages/Notifications'))
const Profile = React.lazy(() => import('../pages/Profile'))
const BusinessProfile = React.lazy(() => import('../pages/BusinessProfile'))
const SignUp = React.lazy(() => import('../pages/SignUp'))
const AddNewAccount = React.lazy(() => import('../pages/AddNewAccount'))
const ClaimBusiness = React.lazy(() => import('../pages/ClaimBusiness'))
const Unsubscribe = React.lazy(() => import('../pages/Unsubscribe'))

const NotFound = React.lazy(() => import('../pages/NotFound'))

const SuccessWait = React.lazy(() => import('../pages/SuccessWaiting'))

const MagicLink = React.lazy(() => import('../pages/MagicLink'))

const TestPage = React.lazy(() => import('../pages/Test'))

const UnclaimedBusiness = React.lazy(
  () => import('../pages/CreateUnclaimedBusiness')
)

const UnclaimedBusinessError = React.lazy(
  () => import('../pages/CreateUnclaimedBusinessError')
)

type RouteProps = {
  authenticated: boolean
  path?: string
  component?: JSX.Element
  sub?: JSX.Element
  redirect?: boolean
}

//Only add to this list when needed to add a new page
let myRoutes: RouteProps[] = [
  { authenticated: false, path: Nav.LOGIN, component: <Login /> },
  {
    authenticated: false,
    path: Nav.RESET_PASSWORD,
    sub: (
      <Route path={Nav.RESET_PASSWORD} element={<ForgotPasswordPage />}>
        <Route path={':username/:screen'} element={<ForgotPasswordPage />} />
      </Route>
    ),
  },
  {
    authenticated: true,
    path: Nav.ROLE,
    component: <RoleSelectionPage />,
  },
  {
    authenticated: false,
    path: Nav.SIGNUP,
    sub: (
      <Route path={Nav.SIGNUP} element={<SignUp />}>
        <Route path={':username/:screen'} element={<SignUp />} />
      </Route>
    ),
  },

  {
    authenticated: true,
    path: Nav.HOME,
    redirect: false,
    sub: (
      <Route path={Nav.HOME} element={<Home />}>
        <Route path={`${Nav.TODO_LIST}/:todoListId`} element={<Home />}>
          <Route
            path={`${Nav.TODO_LIST}/:todoListId/:taskId`}
            element={<Home />}
          />
        </Route>
      </Route>
    ),
  },
  {
    authenticated: true,
    path: Nav.CLIP,
    redirect: true,
    sub: (
      <Route path={Nav.CLIP} element={<ClipView />}>
        <Route path={':clipId'} element={<ClipView />} />
      </Route>
    ),
  },
  {
    /*TODO: remove this later*/
    authenticated: true,
    path: Nav.SETTINGS,
    sub: (
      <Route path={Nav.SETTINGS} element={<Settings />}>
        <Route path={':slug'} element={<Settings />} />
      </Route>
    ),
  },
  {
    authenticated: true,
    path: Nav.CHAT,
    sub: (
      <Route path={Nav.CHAT} element={<Chat />}>
        <Route path={':category'} element={<Chat />}>
          <Route path={':threadId'} element={<Chat />} />
        </Route>
      </Route>
    ),
  },
  {
    authenticated: true,
    path: Nav.FIND,
    sub: (
      <Route path={Nav.FIND} element={<Find />}>
        <Route path={':type'} element={<Find />} />
      </Route>
    ),
  },
  {
    authenticated: true,
    path: Nav.NOTIFICATIONS,
    component: <Notifications />,
  },
  { authenticated: true, path: Nav.PROFILE, component: <Profile /> },
  {
    authenticated: true,
    path: Nav.BUSINESS_PROFILE,
    component: <BusinessProfile />,
  },
  {
    authenticated: true,
    path: Nav.CLIPS_CENTRAL,
    component: <ClipsCentralPage />,
  },
  {
    authenticated: true,
    path: Nav.CLIPS_CENTRAL,
    sub: (
      <Route path={Nav.CLIPS_CENTRAL} element={<ClipsCentralPage />}>
        <Route path={':clipId'} element={<Chat />} />
      </Route>
    ),
  },
  {
    authenticated: true,
    path: Nav.ADD_NEW_ACCOUNT,
    component: <AddNewAccount />,
  },
  {
    authenticated: false,
    path: `${Nav.CLAIM_BUSINESS}/:handle`,
    component: <ClaimBusiness />,
  },
  {
    authenticated: false,
    path: '*',
    component: <NotFound />,
  },
  {
    authenticated: false,
    path: Nav.CONTACTS,
    component: <UnclaimedBusiness />,
  },
  {
    authenticated: true,
    path: Nav.CONTACTS,
    component: <UnclaimedBusinessError />,
  },
  {
    authenticated: true,
    path: Nav.SUCCESSWAIT,
    component: <SuccessWait />,
  },

  {
    authenticated: false,
    path: `${Nav.MAGIC_LINK}/:id`,
    redirect: true,
    component: <MagicLink />,
  },

  {
    authenticated: true,
    path: `${Nav.MAGIC_LINK_ACTIVE}/:id`,
    component: <MagicLinkAuthenticated />,
  },
  {
    authenticated: true,
    redirect: true,
    path: `${Nav.DEFAULT_REDIRECT}`,
    component: <DefaultRedirectPage />,
  },
  {
    authenticated: true,
    path: `${Nav.MAGIC_LINK_PENDING_WORKER}/:id`,
    component: <MagicLinkPendingWorkerInvite />,
  },
  {
    authenticated: false,
    path: Nav.UNSUBSCRIBE,
    component: <Unsubscribe />,
  },
  {
    authenticated: true,
    path: Nav.UNSUBSCRIBE,
    component: <Unsubscribe />,
  },
  {
    authenticated: true,
    path: `${Nav.TODO_LIST}/:id`,
    sub: (
      <Route path={Nav.TODO_LIST} element={<Home />}>
        <Route path={':id'} element={<Home />} />
      </Route>
    ),
  },
]

if (process.env.NODE_ENV === 'development') {
  myRoutes = [
    { authenticated: true, path: Nav.TEST_PAGE, component: <TestPage /> },
    ...myRoutes,
  ]
}

const NavigationStack = () => {
  const { isAuthenticated, activeRole } = useUserContext()

  const renderRouteItem = (item: RouteProps): React.ReactElement => {
    if (item.sub) {
      return item.sub
    }
    return <Route path={item.path} element={item.component} />
  }

  const renderRoute = (item: RouteProps): React.ReactElement => {
    if (
      (!item.authenticated && !isAuthenticated) ||
      (item.authenticated && isAuthenticated && activeRole !== null) ||
      item.redirect
    ) {
      return renderRouteItem(item)
    } else if (
      item.authenticated &&
      isAuthenticated &&
      activeRole === null &&
      item.path === Nav.ROLE
    ) {
      return <Route path={item.path} element={<RoleSelectionPage />} />
    } else if (
      item.authenticated &&
      isAuthenticated &&
      activeRole === null &&
      item.path === Nav.ADD_NEW_ACCOUNT
    ) {
      return <Route path={item.path} element={<AddNewAccount />} />
    } else if (item.authenticated && isAuthenticated && activeRole === null) {
      return <Route path={item.path} element={<Navigate to={Nav.ROLE} />} />
    } else if (item.authenticated && !isAuthenticated && activeRole === null) {
      return <Route path={item.path} element={<Navigate to={Nav.LOGIN} />} />
    } else if (isAuthenticated && item.path === '/claim/:handle') {
      return !activeRole ? (
        <Route path={item.path} element={<Navigate to={Nav.ROLE} />} />
      ) : (
        <Route path={item.path} element={<Navigate to={Nav.HOME} />} />
      )
    } else if (
      isAuthenticated &&
      (item.path === Nav.LOGIN || item.path === Nav.SIGNUP)
    ) {
      return <Route path={item.path} element={<Navigate to={Nav.HOME} />} />
    }

    // TODO: need to check with other scenarios

    // else if (!item.authenticated && isAuthenticated && activeRole !== null) {
    //   return <Route path={item.path} element={<LoadingPage />} />
    // }
    else {
      return <Route path="*" element={<NotFound />} />
    }
  }

  const routesList = myRoutes.map((item) => renderRoute(item))
  return (
    <React.Suspense fallback={<Loader />}>
      <BrowserRouter>
        <Routes>{routesList}</Routes>
      </BrowserRouter>
    </React.Suspense>
  )
}

export default NavigationStack
