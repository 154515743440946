import { AppColors } from '@tealsapp/teals-components'
import { Profile } from '../../types/User'
import { Constants, Util } from '../../common'
import LocalStreamVideo from './LocalStreamVideo'
import Avatar from '../../components/Avatar'
import ParticipantVideoInfo from './ParticipantVideoInfo'

type LocalParticipantPreviewProps = {
  avatarSize: number
  localParticipantMicOn: boolean
  id?: string
  profile?: Profile
  myMediaStream?: MediaStream
  styles?: any
  videoScale?: string
}

const LocalParticipantPreview = ({
  avatarSize,
  localParticipantMicOn,
  id,
  profile,
  myMediaStream,
  styles,
  videoScale,
}: LocalParticipantPreviewProps) => {
  return (
    <>
      {myMediaStream ? (
        <LocalStreamVideo
          mediaStream={myMediaStream}
          elementId={id || ''}
          wrapperCustomStyles={styles}
          videoScale={videoScale}
        />
      ) : (
        <Avatar
          s3Key={profile?.profilePic || ''}
          imageFetchType={Constants.IMAGE_FETCH_TYPE.PROFILE}
          avatarProps={{
            size: avatarSize,
            altText: Util.getAcronym(profile?.firstName || ''),
            uniqueId: profile?.effectiveUserId,
            hasBorder: true,
            borderColor: AppColors.common.white,
          }}
        />
      )}
      <ParticipantVideoInfo
        isLocalParticipant={true}
        localParticipantMicOn={localParticipantMicOn}
      />
    </>
  )
}

export default LocalParticipantPreview
