import { useState, useEffect, memo } from 'react'
import { View, StyleSheet } from 'react-native'
import { FlashMessageStack } from '@tealsapp/teals-components'
import Notification from './../components/Notification'
import { WebsocketNotification, WebsocketNotificationCamelCase } from '../types/Notification'
import { Util } from '../common'

const styles = StyleSheet.create({
  notificationContainer: {
    position: 'absolute',
    top: 30,
    right: 30,
    width: 350,
    'max-height': 'calc(100vh - 30px)',
    'overflow-y': 'auto',
  }
})

type NotificationWrapperProps = {
  notification?: WebsocketNotification
}
const NotificationTray = (props: NotificationWrapperProps) => {
  const { notification } = props
  const [chatNotifications, setChatNotification] = useState<WebsocketNotificationCamelCase[]>([])
  useEffect(() => {
    if (notification) {
      const formattedNotificationObj = Util.convertKebabToCamelCase(notification) as WebsocketNotificationCamelCase
      const messageIds = chatNotifications.map((notification: WebsocketNotificationCamelCase) => notification.messageId)
      if (!messageIds.includes(formattedNotificationObj.messageId)) {
        setChatNotification([formattedNotificationObj, ...chatNotifications])
      }
    }
  }, [notification])
  const removeNotification = (args: WebsocketNotificationCamelCase) => {
    const filteredNotifications = chatNotifications.filter((notification: WebsocketNotificationCamelCase) => notification.messageId !== args.messageId)
    setChatNotification(filteredNotifications)
  }

  const removeNotifications = () => {
    setChatNotification([])
  }

  return (
    <View style={styles.notificationContainer}>
      <FlashMessageStack
        messages={chatNotifications}
        clearAllLabel={'CLEAR ALL'}
        removeMessages={removeNotifications}
        renderCustomContent={(args: WebsocketNotificationCamelCase) => {
          return <Notification {...args} removeMessage={removeNotification} />
        }
      } />
    </View>
  )
}

const MemoizedNotificationTray = memo(NotificationTray)

export default MemoizedNotificationTray
