import { useMutation, useQueryClient } from 'react-query'
import { Constants, getAxios, MessageUtils, QueryKeys } from '../../common'
import { useActiveOrgId } from '../../context/UserContext'
import { ServerError } from '../../types/Common'

const api = getAxios(true)

/*====================== Invite to magic link ================================*/
type inviteMagicLinkParam = {
  phoneNumber: string
  magicToken: string
  threadId: string
  threadType: string
  isseeker?: boolean
}

const inviteMagicLink = async (params: inviteMagicLinkParam) => {
  return await api.post(`/threads/groups/invites/magiclink`, {
    'phone-number': params.phoneNumber,
    'magic-token': params.magicToken,
  })
}

export const useInviteMagicLink = (
  onSuccess: (data: any, variables: any) => void,
  onErrorCallback: (error: ServerError, variables: any) => void
) => {
  const queryClient = useQueryClient()
  const activeOrgId = useActiveOrgId()

  return useMutation(inviteMagicLink, {
    onSuccess,
    onSettled: (data, error, variables) => {
      if (!!variables?.threadId) {
        queryClient.invalidateQueries([
          QueryKeys.THREAD,
          variables.threadId,
          variables?.isseeker ? Constants.SEEKER_ORG_ID : activeOrgId,
        ])
        if (!!variables?.threadType) {
          queryClient.invalidateQueries([
            QueryKeys.THREADS,
            variables?.isseeker
              ? Constants.THREAD_TYPE.GENERAL
              : MessageUtils.getThreadFilterFromType(variables.threadType),
            variables?.isseeker ? Constants.SEEKER_ORG_ID : activeOrgId,
          ])
        }
      }
    },
    onError: (error: any, variables: any) => {
      onErrorCallback(error?.response?.data as ServerError, variables)
    },
  })
}
