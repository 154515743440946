import moment from 'moment'
import { DateType } from '../types/Common'

function formatDateTime(date: any, format = 'Do MMMM') {
  const v = moment(date).format(format)
  return v
}

function parseDateTime(
  dateStr: string,
  format = 'YYYY-MM-DD hh:mm a',
  toMoment = false
): Date | moment.Moment {
  return toMoment
    ? moment(dateStr, format)
    : new Date(moment(dateStr as any, format as any) as any)
}
function parseDateTimeToMoment(
  dateStr: any,
  format = 'YYYY-MM-DD hh:mm a'
): Date | moment.Moment {
  return parseDateTime(dateStr, format, true)
}

function compareAndAddDay(startDate: string, endDate: any, addDays = 1) {
  const startDateObj = moment(startDate)
  const endDateObj = moment(endDate)

  if (startDateObj.isBefore(endDateObj)) {
    return endDate
  } else {
    return new Date((startDateObj as moment.Moment).add(addDays, 'days') as any)
  }
}

function getCurrentDate() {
  return moment()
}

export default {
  formatDateTime,
  parseDateTime,
  compareAndAddDay,
  getCurrentDate,
  parseDateTimeToMoment,
  FORMATS: {
    DEFAULT_DATE_TIME: 'YYYY-MM-DD h:mm a',
    DEFAULT_DATE: 'YYYY-MM-DD',
    DEFAULT_TIME: 'h:mm a',
    TASK_DISPLAY_DATE: 'DD/MM/YYYY',
    TASK_DISPLAY_TIME: 'h:mm a',
  },
  DAY_TIME: {
    START: '12:00 am',
    END: '11:59 pm',
  },
  DATE_TYPE: {
    START_DATE: DateType.startDate,
    END_DATE: DateType.endDate,
  },
}
