import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import StyleSheet from 'react-native-media-query'
import MobileMenu from './MobileMenu'
import Menu from './Menu'
import { Constants, Util } from '../common'
import DefaultContent from './DefaultContent'
import { DefaultContentType } from '../types/Common'
import LocationPermissionBar from '../components/LocationPermissionBar'

const { styles } = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    '@media (max-width: 1024px)': {
      flexDirection: 'column',
    },
  },
  sidebarWrapper: {
    width: '375px',
    height: '100vh',
    '@media (max-width: 1024px)': {
      width: '100vw',
    },
  },
  mainContentWrapper: {
    width: 'calc(100vw - 425px)',
    minWidth: '375px',
    height: '100vh',
    '@media (max-width: 1024px)': {
      width: '100vw',
    },
  },
  mainContentWrapperFullWidth: {
    width: '100vw',
    minWidth: '375px',
    height: '100vh',
  },
})

type SidebarProps = {
  show: boolean
  sidebar: React.ReactNode
}

const MemoSidebar = React.memo((props: SidebarProps) => {
  if (props.show) {
    return <View style={styles.sidebarWrapper}>{props.sidebar}</View>
  }
  return null
})
MemoSidebar.displayName = 'MemoSidebar'

type MemoMainContentProps = {
  show: boolean
  sidebarSelection: any
  mainContent?: React.ReactNode
  hideSideBar?: boolean
  defaultContentType?: DefaultContentType
  renderAbsoluteContent?: React.ReactNode
}

const MemoMainContent = React.memo((props: MemoMainContentProps) => {
  const {
    renderAbsoluteContent,
    hideSideBar,
    show,
    sidebarSelection,
    mainContent,
  } = props
  if (show) {
    return (
      <View
        style={
          hideSideBar
            ? styles.mainContentWrapperFullWidth
            : styles.mainContentWrapper
        }
      >
        {sidebarSelection && mainContent ? (
          mainContent
        ) : (
          <DefaultContent type={props.defaultContentType} />
        )}

        {renderAbsoluteContent && renderAbsoluteContent}
      </View>
    )
  }
  return null
})
MemoMainContent.displayName = 'MemoMainContent'

const MemoMenu = React.memo(() => {
  return <Menu />
})
MemoMenu.displayName = 'MemoMenu'

const MemoMobileMenu = React.memo(() => {
  return <MobileMenu />
})
MemoMobileMenu.displayName = 'MemoMobileMenu'

type LayoutProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selection?: any
  renderSidebar: React.ReactNode
  renderContent?: React.ReactNode
  hideMainOnMobile?: boolean
  hideSideBar?: boolean
  defaultContentType?: DefaultContentType
  renderAbsoluteContent?: React.ReactNode
}
function Layout({
  selection,
  renderSidebar,
  renderContent,
  hideMainOnMobile,
  hideSideBar,
  defaultContentType,
  renderAbsoluteContent,
}: LayoutProps) {
  const [showSidebar, setShowSidebar] = useState(false)
  const [showMain, setShowMain] = useState(false)

  const { width } = Util.getWindowDimensions()

  useEffect(() => {
    if (hideSideBar) {
      setShowMain(true)
      setShowSidebar(false)
    } else {
      if (width > Constants.BREAK_POINT) {
        setShowMain(true)
        setShowSidebar(true)
      } else {
        setShowSidebar(!selection)
        setShowMain(selection)
      }
    }
  }, [selection, width])

  const isDesktop = width > Constants.BREAK_POINT

  return (
    <View>
      <MemoMobileMenu />
      <LocationPermissionBar />
      <View style={styles.wrapper}>
        <MemoMenu />

        <MemoSidebar
          show={hideSideBar ? false : hideMainOnMobile ? true : showSidebar}
          sidebar={renderSidebar}
        />
        {(isDesktop || (!isDesktop && !hideMainOnMobile)) && (
          <MemoMainContent
            show={showMain}
            sidebarSelection={selection}
            mainContent={renderContent}
            hideSideBar={hideSideBar}
            defaultContentType={defaultContentType}
            renderAbsoluteContent={renderAbsoluteContent}
          />
        )}
      </View>
    </View>
  )
}

export default Layout
