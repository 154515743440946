import { Platform } from 'react-native'
import {
  ComponentOptions,
  showMessage,
  hideMessage,
} from '@tealsapp/teals-components'
import Constants from './Constants'
import StorageUtil from './StorageUtil'
import {
  TaggedEntities,
  EntityType,
  TaggedEntityUser,
  TaggedEntityBusiness,
  GalleyType,
  Media,
  GalleryItem,
} from '../types/Files'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import { AllowedCountry } from '../types/Settings'
import { allowedCountryList } from '../remoteConfig'
import { OrderBy } from '../types/Common'
import { EditOrCreateBusinessTag, TagData } from '../types/Business'
import { ThreadSummary } from '../types/Thread'
import { Profile } from '../types/User'
const mimeDb = require('mime-db')

function showErrorMessage(
  message: string,
  timeout = Constants.TIMEOUT.FLASH_MESSAGE_WAITING
) {
  showMessage({
    message: undefined,
    description: message,
    type: ComponentOptions.FLASH_MESSAGE_TYPES.ERROR,
    statusBarHeight: 80,
    containerStyle: { alignSelf: 'center' },
  })
  setTimeout(hideMessage, timeout)
}

function showSuccessMessage(
  message: string,
  timeout = Constants.TIMEOUT.FLASH_MESSAGE_WAITING
) {
  showMessage({
    message: undefined,
    description: message,
    type: ComponentOptions.FLASH_MESSAGE_TYPES.SUCCESS,
    statusBarHeight: 80,
    containerStyle: { alignSelf: 'center' },
  })
  setTimeout(hideMessage, timeout)
}

function showWarningMessage(
  message: string,
  timeout = Constants.TIMEOUT.FLASH_MESSAGE_WAITING
) {
  showMessage({
    message: undefined,
    description: message,
    type: ComponentOptions.FLASH_MESSAGE_TYPES.WARNING,
    statusBarHeight: 80,
    containerStyle: { alignSelf: 'center' },
  })
  setTimeout(hideMessage, timeout)
}

function generateUUID() {
  return uuidv4()
}

function getTitleCase(str: string): string {
  return (str || '')
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join(' ')
}

function getMemberRoleForDisplay(roleType: string, roleDisplayName?: string) {
  let memberType = ''
  if (roleType === Constants.USER_ACTIVE_ROLE.tradieOwner) {
    memberType = 'Owner'
  } else if (roleType === Constants.USER_ACTIVE_ROLE.tradieAdmin) {
    memberType = 'Foreman'
  } else if (roleType === Constants.USER_ACTIVE_ROLE.tradieWorker) {
    memberType = 'Worker'
  } else if (
    roleType === Constants.USER_ACTIVE_ROLE.tradiePendingBusinessRole
  ) {
    memberType = 'Worker(Pending)'
  } else if (
    roleType === Constants.USER_ACTIVE_ROLE.tradiePendingBusinessRejectedRole
  ) {
    memberType = 'Worker(Rejected)'
  } else if (roleType === Constants.USER_ACTIVE_ROLE.seekerRole) {
    memberType = roleDisplayName || 'Seeker'
  }
  return memberType
}

function getAcronym(name: string): string {
  if (name) {
    const text = name.trim().replace(/\s/g, '').toUpperCase()
    if (text.length === 0) {
      return ''
    }
    const letterNumber = /^[0-9a-zA-Z]+$/
    for (let i = 0; i < text.length; i++) {
      const ch = text.charAt(i)
      if (ch.match(letterNumber)) {
        return ch
      }
    }
    return ''
  } else {
    return ''
  }
}

function checkIfIncludesSpecialChars(text: string) {
  const regEx = /^[A-Za-z\s]+$/
  return regEx.test(text)
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

function hasActiveOrg(): boolean {
  StorageUtil.getActiveRole()
    .then((role) => {
      return role && role != null && role['org-id'] && role['org-id'] != ''
    })
    .catch(() => {
      return false
    })

  return false
}

async function getActiveOrgId(): Promise<string> {
  try {
    const activeRole = await StorageUtil.getActiveRole()
    return activeRole?.['org-id'] || ''
  } catch (error) {
    return ''
  }
}

function replaceUndefinedWithDefault(value: any, defaultValue: any) {
  return value === undefined ||
    value === null ||
    value === '' ||
    value === 'NONE' ||
    value === 'DYNAMODB_NONE_CHARACTER' ||
    value === 'DYNAMODB-NONE-CHARACTER'
    ? defaultValue
    : value
}

function getClipAvatarProps(
  taggedEntities: TaggedEntities,
  type: EntityType,
  id: string
) {
  let name = ''
  let s3Key = ''
  let uniqueId = ''
  let otherProps: TaggedEntityBusiness | TaggedEntityUser | object = {}
  if (taggedEntities) {
    if (type === EntityType.business) {
      const entity = taggedEntities[id] as TaggedEntityBusiness
      name = entity?.businessName || ''
      s3Key = entity.businessLogo
      uniqueId = entity.businessId
      otherProps = { ...entity }
    } else if (type === EntityType.user) {
      const entity = taggedEntities[id] as TaggedEntityUser
      name = entity?.firstName
      s3Key = entity.profilePic
      uniqueId = entity.userId
      otherProps = { ...entity }
    }
  }

  return { name, s3Key, uniqueId, otherProps }
}
function getPaddings(columns: number, padding: number, index: number) {
  let paddingLeft = padding / 2
  let paddingRight = padding / 2
  let paddingTop = padding / 2
  const paddingBottom = padding / 2

  const row: number = Math.floor(index / columns)
  const isFirstItem: boolean =
    index % columns === 0 || index % columns === columns
  const isLastItem: boolean = index % columns === columns - 1
  if (row === 0) {
    //first row
    paddingTop = padding
    if (isFirstItem) {
      paddingLeft = padding
    } else if (isLastItem) {
      paddingRight = padding
    }
  } else {
    if (isFirstItem) {
      paddingLeft = padding
    } else if (isLastItem) {
      paddingRight = padding
    }
  }

  return { paddingLeft, paddingRight, paddingTop, paddingBottom }
}

function getThumbnailKeyFromVideoObjectKey(key: string) {
  if (key) {
    const sections = key.split('/')
    if (sections.length !== 2) {
      return null
    }
    return `videosthumbnail/${sections[1]}`
  }
  return null
}

function removeDuplicatesFromArray(ar: Array<any>, key = '') {
  // If no key is provided, use the entire object for comparison
  if (!key) {
    const uniqueObjects = new Set()

    return ar.filter((obj) => {
      const objString = JSON.stringify(obj)
      if (!uniqueObjects.has(objString)) {
        uniqueObjects.add(objString)
        return true
      }
      return false
    })
  }

  // If a key is provided, use it for comparison
  const uniqueKeys = new Set()

  return ar.filter((obj) => {
    const keyValue = obj[key]
    if (!uniqueKeys.has(keyValue)) {
      uniqueKeys.add(keyValue)
      return true
    }
    return false
  })
}

function getGalleyTypeOfMedia(mediaType: string) {
  let galleryType: GalleyType = GalleyType.photo
  if (mediaType === GalleyType.audio) {
    galleryType = GalleyType.audio
  } else if (mediaType === GalleyType.video) {
    galleryType = GalleyType.video
  }
  return galleryType
}
function getMediaPlayerTime(duration: number, currentPosition: number) {
  if (duration === 0) {
    return '0:00'
  } else {
    const timeDiff = Math.floor(duration - currentPosition)
    let seconds: number = Math.floor(timeDiff)
    const minutes: number = Math.floor(seconds / 60)
    seconds = seconds % 60
    return minutes + ':' + (seconds < 10 ? '0' : '') + seconds
  }
}

function getFileExtension(filename: string) {
  return (
    filename.substring(filename.lastIndexOf('.') + 1, filename.length) ||
    filename
  )
}

function getDefaultExtension(type: GalleyType, item: Media) {
  if (type === GalleyType.photo) {
    return mimeDb[item.mimeType]?.extensions[0]
  } else if (type === GalleyType.video) {
    return Platform.OS === 'ios' ? 'm4a' : 'mp4'
  }
  return null
}

function chunkArrayToGivenSizes(arr: any[], arSizes: any[]) {
  const arChunks = []
  let lastSlicedIndex = 0

  for (let index = 0; index < arSizes.length; index++) {
    const size = arSizes[index]

    if (lastSlicedIndex < arr.length) {
      const chunkedAr = arr.slice(lastSlicedIndex, lastSlicedIndex + size)
      arChunks.push(chunkedAr)
      lastSlicedIndex = lastSlicedIndex + size
    } else {
      break
    }
  }
  return arChunks
}

function getUniqueListBy(arr: Array<any>, key: string) {
  return Array.from(new Map(arr.map((item) => [item[key], item])).values())
}

function getTradieTypeFromRole(roleType: string) {
  const LIST_TRADIE_TYPE = Constants.TRADIE_TYPE

  let ACTIVE_TRADIE_TYPE = ''

  if (roleType === Constants.USER_ACTIVE_ROLE.tradieOwner) {
    ACTIVE_TRADIE_TYPE = LIST_TRADIE_TYPE.BUSINESS_OWNER
  } else if (roleType === Constants.USER_ACTIVE_ROLE.tradieAdmin) {
    ACTIVE_TRADIE_TYPE = LIST_TRADIE_TYPE.ADMIN
  } else if (roleType === Constants.USER_ACTIVE_ROLE.tradieWorker) {
    ACTIVE_TRADIE_TYPE = LIST_TRADIE_TYPE.WORKER
  } else if (
    roleType === Constants.USER_ACTIVE_ROLE.tradiePendingBusinessRole
  ) {
    ACTIVE_TRADIE_TYPE = LIST_TRADIE_TYPE.WORKER
  } else if (
    roleType === Constants.USER_ACTIVE_ROLE.tradiePendingBusinessRejectedRole
  ) {
    ACTIVE_TRADIE_TYPE = LIST_TRADIE_TYPE.WORKER
  } else if (roleType === Constants.USER_ACTIVE_ROLE.tradieOwner) {
    ACTIVE_TRADIE_TYPE = LIST_TRADIE_TYPE.BUSINESS_OWNER
  } else if (roleType === Constants.USER_ACTIVE_ROLE.tradieAdmin) {
    ACTIVE_TRADIE_TYPE = LIST_TRADIE_TYPE.ADMIN
  } else if (roleType === Constants.USER_ACTIVE_ROLE.tradieWorker) {
    ACTIVE_TRADIE_TYPE = LIST_TRADIE_TYPE.WORKER
  } else if (
    roleType === Constants.USER_ACTIVE_ROLE.tradiePendingBusinessRole
  ) {
    ACTIVE_TRADIE_TYPE = LIST_TRADIE_TYPE.WORKER
  } else if (
    roleType === Constants.USER_ACTIVE_ROLE.tradiePendingBusinessRejectedRole
  ) {
    ACTIVE_TRADIE_TYPE = LIST_TRADIE_TYPE.WORKER
  }

  return ACTIVE_TRADIE_TYPE
}

function removeUndefinedProps(obj: any) {
  return JSON.parse(JSON.stringify(obj))
}

function checkObjectIsExistsInArray(
  ar: any,
  obj: any,
  key = '',
  keyArray = ''
) {
  if (key !== '') {
    const idKey = keyArray === '' ? key : keyArray
    return ar.find((o: any) => o[idKey] === obj[key]) !== undefined
  }
  return ar.find((o: any) => o === obj) !== undefined
}

function checkObjectsAttributesExistsInArray(ar: any, attributes = {}) {
  return ar.includes(attributes)
}

function validateEmail(email: string) {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return re.test(email)
}

function copyToClipboard(
  text: string,
  onSuccess: (message: string) => void,
  onError: (er: string) => void
) {
  if (navigator?.clipboard) {
    navigator?.clipboard?.writeText(text)
    onSuccess('Link copied successfully')
  } else {
    onError('Copy option not supporting for unsecured context')
  }
}

function openNewTab(url: string) {
  window.open(url, '_blank')
}

function replaceEmptyWithUndefined(value: any) {
  if (!value) {
    return undefined
  } else if (typeof value === 'object' && Object.keys(value).length === 0) {
    return undefined
  } else if (typeof value === 'string' && value === '') {
    return undefined
  } else if (Array.isArray(value) && value.length === 0) {
    return undefined
  }
  return value
}

function generateQueryParameter(data: any, allowEmptyStrings = false) {
  let str = ''
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const value = data[key]
      if (value !== null) {
        if (Array.isArray(value)) {
          if (value.length > 0) {
            if (str !== '') {
              str += '&'
            }
            str += `${key}=${value.join(',')}`
          }
        } else if (value !== undefined && value !== '') {
          if (str !== '') {
            str += '&'
          }
          str += `${key}=${value}`
        } else if (value !== undefined && allowEmptyStrings) {
          if (str !== '') {
            str += '&'
          }
          str += `${key}=${value}`
        }
      }
    }
  }
  if (str !== '') {
    str = `?${str}`
  }
  return str
}

function getObjectArrayValuesOfKey(list: any, key: string) {
  return list.map((obj: any) => {
    return obj?.[key] ? obj?.[key] : null
  })
}

function generateGreetings() {
  const currentHour = parseInt(moment().format('HH'))
  if (currentHour >= 5 && currentHour < 12) {
    return 'Morning'
  } else if (currentHour >= 12 && currentHour < 17) {
    return 'Afternoon'
  } else if (currentHour >= 17 && currentHour > 5) {
    return 'Evening'
  } else {
    return 'Hello'
  }
}

const shortenText = (txt: string, maxLimit = 10) => {
  return txt.length > maxLimit ? txt.substring(0, maxLimit - 3) + '...' : txt
}

function setPriority(data: any[], key: string) {
  const priority: number[] = []
  const priorityTrack = []
  for (let i = 0; i < data.length; i++) {
    priority.push(data[i][key])
    priorityTrack.push({
      index: i,
      priority: data[i][key],
    })
  }
  return {
    priority: priority.sort((a, b) => a - b),
    priorityTrack: priorityTrack,
  }
}

function highestPriority(data: any[], key: string) {
  const priorityData = setPriority(data, key)
  const priorityIndex = priorityData.priorityTrack.filter(
    (item) => item.priority === priorityData.priority[0]
  )
  return data[priorityIndex[0].index]
}

function orderArray(data: any[], key: string) {
  if (data.length === 0) {
    return []
  }
  const priorityData = setPriority(data, key)
  const orderedArray = []
  for (let i = 0; i < priorityData.priority.length; i++) {
    const priorityIndex = priorityData.priorityTrack.filter(
      (item) => item.priority === priorityData.priority[i]
    )
    orderedArray.push(data[priorityIndex[0].index])
  }
  return orderedArray
}

function convertArrayToString(
  arr: any,
  key: string | number,
  isTitleCase = false,
  seperator = ' | '
) {
  let data = ''
  let i = 0
  try {
    for (const v of arr) {
      if (i > 0) {
        data += seperator
      }

      const val = key === '' ? v : v[key]
      if (isTitleCase) {
        data += getTitleCase(val || '')
      } else {
        data += val
      }
      i++
    }
  } catch (error) {}
  return data
}

function getStreetAddressFromDescription(description: string) {
  const arSplitAddress = description.split(',')
  return arSplitAddress.length > 0 ? arSplitAddress[0] : description
}

function checkArrayExistInGivenArray(
  parentArray: string[],
  childArray: string[]
) {
  let matches = 0
  for (const child of childArray) {
    for (const parent of parentArray) {
      if (child === parent) {
        matches += 1
      }
    }
  }

  return childArray.length > 0 && matches === childArray.length
}

function checkAnyItemInArrayExistInGivenArrayByKey(
  parentArray: any[],
  childArray: any[],
  parentKey: string,
  childKey?: string
) {
  let matches: any[] = []
  for (const child of childArray) {
    for (const parent of parentArray) {
      if (
        (!!childKey && child[childKey] === parent[parentKey]) ||
        child === parent[parentKey]
      ) {
        matches = [...matches, parent]
      }
    }
  }

  return matches
}

function getValueFromQueryParameter(key: string): string | null {
  const search = window.location.search
  const params = new URLSearchParams(search)

  return params.get(key)
}

function filterMediaByDate(items: GalleryItem[]) {
  const galleryGroups = items?.reduce(
    (prevValue: { [key: string]: GalleryItem[] }, currentValue) => {
      const splitedDateArray = currentValue.gtCreatedTime?.split('-')
      const date = splitedDateArray?.[0] + '-' + splitedDateArray?.[1]
      if (!prevValue[date]) {
        prevValue[date] = []
      }
      prevValue[date]?.push(currentValue)
      return prevValue
    },
    {}
  )

  return Object.keys(galleryGroups)
    .map((date) => {
      return {
        title: date,
        data: [
          {
            key: date,
            data: galleryGroups[date],
          },
        ],
      }
    })
    .sort((a, b) => (a.title > b.title ? -1 : 1))
}

function getSectionTitleGivenYearMonth(title: string) {
  const currentYear = new Date().getFullYear().toString()
  const splitedTitle = title.split('-')
  const date = new Date(
    parseInt(splitedTitle[0]),
    parseInt(splitedTitle[1]) - 1
  )
  const month = date.toLocaleString('default', { month: 'long' })
  const currentMonth = new Date().toLocaleString('default', { month: 'long' })
  return currentYear !== splitedTitle[0]
    ? `${splitedTitle[0]} ${month}`
    : currentMonth === month
    ? 'This month'
    : month
}

/* Get effective user Id from business object*/
function findEffectiveUserIdFromMember(members: any, userId: string) {
  for (const member of members) {
    if (member?.['user-id'] === userId) {
      return member['effective-user-id']
    }
    if (member?.['userId'] === userId) {
      return member['effectiveUserId']
    }
  }
  return ''
}

function getEfectiveUserIdFromMembersByUserId(member: any, userId: string) {
  if (!member) return undefined
  const keysWithUserId = Object?.keys(member)?.filter(
    (key) => member?.[key]?.['user-id'] === userId
  )
  return keysWithUserId?.[0]
}

function getEffectiveUserIdByBusiness(
  business: any,
  userId: string,
  isPendingMember = false
) {
  const members: any = isPendingMember
    ? Object.values(business?.['requested-members'] || {})
    : Object.values(business?.['members'] || {})
  return findEffectiveUserIdFromMember(members, userId)
}

function parseURL(url: string) {
  return url.replace(/\//, '')
}

const downloadFiles = (url: string, name: string) => {
  fetch(url).then((response) => {
    response.blob().then((blob) => {
      const url = window.URL.createObjectURL(blob)
      const documentElement = document.createElement('a')
      documentElement.href = url
      documentElement.download = name
      documentElement.click()
    })
  })
}

function countryByMobileNumber(number: string) {
  if (
    process.env.NODE_ENV !== 'development' &&
    number.startsWith(Constants.COUNTRY_CODES.LK)
  ) {
    return Constants.TEST_COUNTRY
  }
  const selectedCountry = allowedCountryList.filter((country: AllowedCountry) =>
    number?.startsWith(country.code)
  )
  if (selectedCountry.length > 0) {
    return selectedCountry[0]
  }
  return null
}

function createSelectedTradeObj(trades = []) {
  if (trades.length === 0) {
    return {}
  } else {
    return Object.fromEntries(
      trades.map((trade) => [
        trade['trade-hash'],
        {
          'trade-hash': trade['trade-hash'],
          'trade-name': trade['trade-name'],
        },
      ])
    )
  }
}

function removeEmptyNewLine(txt: string) {
  return txt?.replace(/(\r\n|\n|\r)/gm, '')
}

const orderByArray = (
  data: any[] = [],
  key: string,
  mode: OrderBy = OrderBy.asc
) => {
  if (mode === OrderBy.asc) {
    return data.sort((a, b) => a[key] - b[key])
  } else {
    return data.sort((a, b) => b[key] - a[key])
  }
}

const isEmpty = (value: object) => {
  return Object.keys(value).length === 0
}

function createTagObject(params: EditOrCreateBusinessTag) {
  return {
    'tag-id': params.tagId,
    'tag-name': params.tagName,
    'tag-type': params.tagType,
    'tag-description': params.tagDescription,
    'tag-colour': {
      'tag-colour-id': params.tagColor?.tagColorId,
      'tag-colour-name': params.tagColor?.tagColorName,
      'tag-colour-value': params.tagColor?.tagColorValue,
    },
    'business-id': params.activeBusinessId,
  }
}

const getBrowserDefaultLanguage = (): string => {
  const lang = navigator.language
  const parts = lang.split('-')
  const languageCode = parts.length > 0 ? parts[0] : ''
  return languageCode
}

const checkSummaryMessageContainsMedia = (summary: ThreadSummary) => {
  return (
    summary?.imagesCount ||
    summary?.videosCount ||
    summary?.documentsCount ||
    summary?.audiosCount ||
    summary?.clipsCount ||
    summary?.['todo-list-count']
  )
}

const convertKebabToCamelCase = (
  obj: Record<string, any>
): Record<string, any> => {
  if (obj && typeof obj === 'object') {
    if (Array.isArray(obj)) {
      return obj.map(convertKebabToCamelCase)
    } else {
      return Object.keys(obj).reduce(
        (acc: Record<string, any>, key: string) => {
          const camelKey: string = key.replace(/-([a-z])/g, (_, letter) =>
            letter.toUpperCase()
          )
          acc[camelKey] = convertKebabToCamelCase(obj[key])
          return acc
        },
        {}
      )
    }
  } else {
    return obj
  }
}

function getThreadIdFromUrl() {
  const threadId = window.location.pathname?.split('/').toReversed()?.[0]

  if (Constants.CHAT_FILTERS_LIST.includes(threadId)) {
    return null
  }
  return threadId
}

const detectPlatform = () => {
  // if userAgentData is not available, use navigator.platform which is deprecated
  const platform: string =
    (navigator as any).userAgentData?.platform || navigator.platform
  const mac = ['macOS', 'MacIntel']
  const windows = ['Windows', 'Win32']
  if (mac.includes(platform)) {
    return 'mac'
  } else if (windows.includes(platform)) {
    return 'windows'
  } else {
    return 'other'
  }
  return platform
}

const setUserRestrictedMessage = (
  isShowed: boolean,
  setIsShowed: () => void,
  roleType?: string
) => {
  if (
    (roleType === Constants.USER_ACTIVE_ROLE.tradieOwnerRestricted ||
      roleType === Constants.USER_ACTIVE_ROLE.tradieWorkerRestricted) &&
    !isShowed
  ) {
    showErrorMessage('Account restricted. Time to upgrade.')
    setIsShowed()
  }
}

const checkIfUserIsRestricted = (roleType?: string) => {
  return (
    roleType === Constants.USER_ACTIVE_ROLE.tradieOwnerRestricted ||
    roleType === Constants.USER_ACTIVE_ROLE.tradieWorkerRestricted
  )
}

const getUsername = (userProfile?: Profile) => {
  if (userProfile?.firstName && userProfile?.lastName) {
    return `${userProfile?.firstName} ${userProfile?.lastName}`
  } else if (userProfile?.firstName) {
    return userProfile?.firstName
  }
}

const isProspectiveUser = (userType?: string) => {
  if (!userType) {
    return false
  }
  return [
    Constants.PROSPECTIVE_USER_TYPES.PROSPECTIVE_TRADIE,
    Constants.PROSPECTIVE_USER_TYPES.PROSPECTIVE_SEEKER,
    Constants.PROSPECTIVE_USER_TYPES.PROSPECTIVE_USER,
    Constants.PROSPECTIVE_USER_TYPES.PROSPECTIVE_BUSINESS_OWNER,
  ].includes(userType)
}

function dragThisItem(
  xPos: number,
  yPos: number,
  id: string,
  isDragEnabled: boolean
) {
  const element = document.getElementById(id)
  if (isDragEnabled && element) {
    const rect = element.getBoundingClientRect()

    if (xPos < 0) {
      return
    }

    if (yPos < 0) {
      return
    }

    if (xPos > window.innerWidth) {
      return
    }

    if (yPos > window.innerHeight) {
      return
    }

    element.style.left = xPos - rect.width / 2 + 'px'
    element.style.top = yPos - rect.height / 2 + 'px'
  }
}

function listByTagType(items: TagData[], type: string) {
  const projects: TagData[] = []
  const status: TagData[] = []
  const other: TagData[] = []

  items.forEach((item) => {
    if (item.tagType === Constants.TAG_TYPES.PROJECTS) {
      projects.push(item)
    } else if (item.tagType === Constants.TAG_TYPES.STATUS) {
      status.push(item)
    } else {
      other.push(item)
    }
  })

  if (type === Constants.TAG_TYPES.PROJECTS) {
    return projects
  } else if (type === Constants.TAG_TYPES.STATUS) {
    return status
  } else {
    return other
  }
}

export default {
  BOT_ID: 'autogen-bot-profile-id',
  isEmpty,
  getSectionTitleGivenYearMonth,
  filterMediaByDate,
  showSuccessMessage,
  showErrorMessage,
  showWarningMessage,
  hideMessage,
  generateUUID,
  getTitleCase,
  getMemberRoleForDisplay,
  getAcronym,
  getWindowDimensions,
  hasActiveOrg,
  getActiveOrgId,
  replaceUndefinedWithDefault,
  getClipAvatarProps,
  getPaddings,
  getThumbnailKeyFromVideoObjectKey,
  removeDuplicatesFromArray,
  getGalleyTypeOfMedia,
  getMediaPlayerTime,
  getFileExtension,
  getDefaultExtension,
  chunkArrayToGivenSizes,
  getUniqueListBy,
  getTradieTypeFromRole,
  removeUndefinedProps,
  checkObjectIsExistsInArray,
  checkObjectsAttributesExistsInArray,
  validateEmail,
  copyToClipboard,
  openNewTab,
  replaceEmptyWithUndefined,
  generateQueryParameter,
  getObjectArrayValuesOfKey,
  generateGreetings,
  shortenText,
  highestPriority,
  orderArray,
  downloadFiles,
  convertArrayToString,
  getStreetAddressFromDescription,
  checkArrayExistInGivenArray,
  getValueFromQueryParameter,
  getEffectiveUserIdByBusiness,
  parseURL,
  countryByMobileNumber,
  createSelectedTradeObj,
  removeEmptyNewLine,
  orderByArray,
  createTagObject,
  getBrowserDefaultLanguage,
  checkSummaryMessageContainsMedia,
  convertKebabToCamelCase,
  checkAnyItemInArrayExistInGivenArrayByKey,
  getThreadIdFromUrl,
  detectPlatform,
  setUserRestrictedMessage,
  checkIfUserIsRestricted,
  getUsername,
  isProspectiveUser,
  dragThisItem,
  checkIfIncludesSpecialChars,
  getEfectiveUserIdFromMembersByUserId,
  listByTagType,
}
