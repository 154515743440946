import { useState } from 'react'
import { FlatList, StyleSheet, TouchableOpacity, View } from 'react-native'
import {
  AppColors,
  ComponentOptions,
  Text,
  Button,
} from '@tealsapp/teals-components'
import { CoverImageMedia, GalleyType, UploadItem } from '../../types/Files'
import Image from '../media/Image'
import { Constants, Util } from '../../common'
import ImageUploader from '../../components/ImageUploader'

const styles = StyleSheet.create({
  buttonSectionWrapperStyle: {
    paddingHorizontal: ComponentOptions.SPACES.MEDIUM,
    paddingVertical: ComponentOptions.SPACES.MEDIUM,
    flexGrow: 1,
    justifyContent: 'flex-end',
    paddingBottom: ComponentOptions.SPACES.MEDIUM,
  },
  coverImageModalWrapperStyle: {
    width: '100%',
    flex: 1,
    backgroundColor: AppColors.common.white,
    alignItems: 'center',
  },
  headerStyle: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: ComponentOptions.SPACES.MEDIUM,
    alignItems: 'center',
  },
  uploaderStyle: {
    padding: ComponentOptions.SPACES.MEDIUM,
  },
  iconContainer: {
    width: 48,
    height: 48,
    borderRadius: ComponentOptions.SPACES.SMALL,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: ComponentOptions.SPACES.SMALL,
    marginBottom: ComponentOptions.SPACES.SMALL,
  },
  fullWidth: {
    width: '100%',
  },
  flatListContainerStyle: { flexWrap: 'wrap', maxWidth: 343 },
})

type CoverImageSelectionProps = {
  coverImage: CoverImageMedia | null
  selectedMedia: UploadItem[]
  uploadedItems: UploadItem[]
  onChange: (cover: CoverImageMedia | null) => void
  onClose: () => void
}

function CoverImageSelection({
  coverImage: passedCoverImage,
  selectedMedia,
  uploadedItems,
  onChange,
  onClose,
}: CoverImageSelectionProps) {
  const [coverImage, setCoverImage] = useState<CoverImageMedia | null>(
    passedCoverImage
  )

  const renderUploadItem = ({ item }: { item: UploadItem }) => {
    const isVideo = item.galleryType === GalleyType.video
    const isUploaded =
      item.uploadStatus === Constants.FILE_UPLOAD_STATUS.UPLOADED

    const thumbnailKey = isUploaded
      ? (Util.getThumbnailKeyFromVideoObjectKey(item.objectId) as string)
      : undefined
    return (
      <TouchableOpacity
        style={styles.iconContainer}
        onPress={() =>
          setCoverImage({
            imageSource: 'gallery',
            mimeType: item.file?.type,
            path: isUploaded
              ? undefined
              : isVideo
              ? item.thumbUrl
              : item.uploadingUrl,
            file: item.file,
            filename: item.fileName,
            objectId: isUploaded
              ? isVideo
                ? thumbnailKey
                : item.objectId
              : undefined,
            galleryType: item.galleryType,
            uploadStatus: item.uploadStatus,
          })
        }
      >
        <Image
          source={
            isUploaded ? undefined : isVideo ? item.thumbUrl : item.uploadingUrl
          }
          imageProps={{
            style: {
              width: 48,
              height: 48,
              resizeMode: 'cover',
              borderRadius: ComponentOptions.SPACES.SMALL,
            },
          }}
          s3Key={
            isUploaded ? (isVideo ? thumbnailKey : item.objectId) : undefined
          }
          size={Constants.IMAGE_SIZES.GALLERY_THUMB}
        />
      </TouchableOpacity>
    )
  }

  const renderUploadComponent = () => {
    const isUploaded =
      coverImage?.uploadStatus === Constants.FILE_UPLOAD_STATUS.UPLOADED
    return (
      <ImageUploader
        selectedItemPath={isUploaded ? undefined : coverImage?.path}
        mainText={'Change your cover image'}
        selectedItemKey={isUploaded ? coverImage.objectId : undefined}
      />
    )
  }

  return (
    <View style={styles.coverImageModalWrapperStyle}>
      <View style={styles.headerStyle}>
        <Text body_strong color={AppColors.common.subtitleText}>
          Edit Cover
        </Text>
        <Button
          title={`Close`}
          onPress={onClose}
          textProps={{ body_strong: true }}
          type={ComponentOptions.BUTTON_TYPES.CLEAR}
          buttonColor={AppColors.common.yellow}
          paddingHorizontal={0}
        />
      </View>
      <View style={styles.uploaderStyle}>{renderUploadComponent()}</View>
      <FlatList
        contentContainerStyle={styles.flatListContainerStyle}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        horizontal={true}
        data={[...selectedMedia, ...uploadedItems]}
        renderItem={renderUploadItem}
        keyExtractor={(item) => item.objectId}
        style={{ flexGrow: 0 }}
      />
      <View style={[styles.buttonSectionWrapperStyle, styles.fullWidth]}>
        <Button title={'APPLY'} onPress={() => onChange(coverImage)} />
      </View>
    </View>
  )
}

export default CoverImageSelection
