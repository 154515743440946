type LogoIconProps = {
  size?: number
}

const LogoIcon = ({ size }: LogoIconProps) => {
  return (
    <img
      src={require('../../images/Loading-Logo.png')}
      style={{ width: size || 150, height: size || 150, borderRadius: 8 }}
    />
  )
}

export default LogoIcon
