import {
  addDoc,
  collection,
  doc,
  getDoc,
  updateDoc,
  serverTimestamp,
  writeBatch,
  DocumentSnapshot,
  DocumentData,
  QuerySnapshot,
  FirestoreError,
  Unsubscribe,
  onSnapshot,
  query,
  where,
  orderBy,
  setDoc,
  limit,
  runTransaction,
  Transaction,
  arrayUnion,
  Timestamp,
  getDocs,
} from 'firebase/firestore'
import {
  getCollectionNameWithPrefix,
  fireStoreDb,
  handleFirebaseAuthenticationError,
} from '../common/FirestoreUtils'
import {
  FirebaseCollections,
  TodoListTaskRef,
  TodoTaskBulkCreateResponse,
  TodoTaskCreateResponse,
  User,
} from '../types/TodoList'
import {
  Task,
  TaskBuilder,
  TodoList,
  TodoListBuilder,
} from '../prototypes/TodoList'
import {
  createTodoList,
  getTodoList,
  updateTodoList,
  updateTodoListUpdatedByOnly,
  updateTodoListWhenAddNewTask,
  updateTodoListWhenAddNewTasks,
} from './ToDoActions'
import { Constants } from '../common'

const COLLECTION_TODOLIST = getCollectionNameWithPrefix(
  FirebaseCollections.ToDoListCollection
)
const COLLECTION_TODOLIST_TASKS = getCollectionNameWithPrefix(
  FirebaseCollections.TaskCollection
)

const COLLECTION_TASK_STATUS_CHANGE_HISTORY = getCollectionNameWithPrefix(
  FirebaseCollections.TaskStatusChangeHistory
)
const COLLECTION_DELETED_TODOLIST_TASKS = getCollectionNameWithPrefix(
  FirebaseCollections.DeletedTodoListTasksCollection
)

/*================== Write Data ==========================*/

const addTask = async (
  todoListSnap: DocumentSnapshot<DocumentData>,
  taskBuild: TaskBuilder
): Promise<TaskBuilder> => {
  try {
    const nextSequence = (todoListSnap.data()?.lastSequence || 0) + 1
    taskBuild.setSequence(nextSequence)

    const task = taskBuild.build()
    const docRef = collection(fireStoreDb, COLLECTION_TODOLIST_TASKS)
    const docSnap = await addDoc(docRef, taskBuild.convertToFirebaseObject())
    const taskId = docSnap.id
    task.taskId = taskId
    taskBuild.setTaskId(taskId)

    const lastSeq = task.sequence || 0
    if (lastSeq === 0) {
      throw Error('Task sequence is 0')
    }

    const docRefTask = doc(
      fireStoreDb,
      COLLECTION_TODOLIST_TASKS,
      taskBuild.taskId as string
    )
    await updateDoc(docRefTask, {
      taskId: taskBuild.taskId,
      updatedTime: serverTimestamp(),
    })

    await updateTodoListWhenAddNewTask(todoListSnap, task)
    return taskBuild
  } catch (error) {
    throw error
  }
}

const addTasks = async (
  todoListSnap: DocumentSnapshot<DocumentData>,
  taskBuilds: TaskBuilder[]
): Promise<TaskBuilder[]> => {
  try {
    const batch = writeBatch(fireStoreDb)
    const todoListData = todoListSnap.data()
    let nextSequence = todoListData?.lastSequence || 0

    const tasks: Task[] = []
    for (const taskBuild of taskBuilds) {
      nextSequence++
      taskBuild.setSequence(nextSequence)

      const task = taskBuild.build()
      const docRef = collection(fireStoreDb, COLLECTION_TODOLIST_TASKS)
      const docSnap = await addDoc(docRef, taskBuild.convertToFirebaseObject())
      const taskId = docSnap.id
      task.taskId = taskId
      taskBuild.setTaskId(taskId)

      const lastSeq = task.sequence || 0
      if (lastSeq === 0) {
        throw Error('Task sequence is 0')
      }

      const docRefTask = doc(
        fireStoreDb,
        COLLECTION_TODOLIST_TASKS,
        taskBuild.taskId as string
      )
      batch.update(docRefTask, {
        taskId: taskBuild.taskId,
        updatedTime: serverTimestamp(),
      })
      tasks.push(task)
    }

    await batch.commit()
    await updateTodoListWhenAddNewTasks(todoListSnap, tasks, nextSequence)
    return taskBuilds
  } catch (error) {
    throw error
  }
}

const updateTask = async (
  todoListSnap: DocumentSnapshot<DocumentData>,
  taskBuild: TaskBuilder
): Promise<TaskBuilder> => {
  try {
    const docRef = doc(
      fireStoreDb,
      COLLECTION_TODOLIST_TASKS,
      taskBuild.taskId as string
    )
    const object = taskBuild.convertToFirebaseObject(taskBuild)
    delete (object as TaskBuilder).taskStatus
    await updateDoc(docRef, object)

    await updateDoc(todoListSnap.ref, {
      [`tasks.${taskBuild.taskId}.updatedTime`]: serverTimestamp(),
      [`tasks.${taskBuild.taskId}.updatedByUser`]: taskBuild.updatedByUser,
    })

    return taskBuild
  } catch (error) {
    throw error
  }
}

const publishRemoteTodoTasks = async (
  todoList: TodoListBuilder
): Promise<void> => {
  try {
    const todoListId = todoList?.id || ''
    if (todoListId !== '') {
      const todoListDocRef = doc(fireStoreDb, COLLECTION_TODOLIST, todoListId)
      const todoListSnap = await getDoc(todoListDocRef)

      const tasks = (todoListSnap.data()?.tasks || {}) as TodoListTaskRef

      const batch = writeBatch(fireStoreDb)
      for (const [key, value] of Object.entries(tasks)) {
        const taskRef = value.reference
        batch.update(taskRef, {
          publishStatus: todoList.publishStatus,
          updatedTime: serverTimestamp(),
        })

        //update task filed in todolist
        batch.update(todoListDocRef, {
          [`tasks.${key}.updatedTime`]: serverTimestamp(),
        })
      }

      return batch.commit()
    } else {
      throw Error('TodoList not found')
    }
  } catch (error) {
    throw error
  }
}

const bulkTaskRefUpdateObj = (tasks: { taskId: string }[]) => {
  const obj: any = {}
  for (const task of tasks) {
    const taskId = task.taskId
    const taskDocRef: DocumentData = doc(
      fireStoreDb,
      COLLECTION_TODOLIST_TASKS,
      taskId
    )
    obj[`tasks.${taskId}`] = {
      reference: taskDocRef,
      updatedTime: serverTimestamp(),
    }
  }
  return obj
}

const changeMultipleTaskStatus = async (
  todoRef: any,
  taskRefs: { [key: string]: any },
  updateByUser: User,
  taskStatus = Constants.TASK_STATUS.COMPLETED.key
) => {
  await runTransaction(fireStoreDb, async (transaction) => {
    const tasks = []
    for (const [key, value] of Object.entries(taskRefs)) {
      const taskRef = value.reference
      transaction.update(taskRef, {
        taskStatus: taskStatus,
        taskStatusChangedByUser: updateByUser,
        updatedByUser: updateByUser,
        updatedTime: serverTimestamp(),
      })

      const taskStatusHistoryRef = doc(
        fireStoreDb,
        COLLECTION_TASK_STATUS_CHANGE_HISTORY,
        key
      )

      const taskStatusHistorySnap = await getDoc(taskStatusHistoryRef)
      const isExist = taskStatusHistorySnap.exists()

      if (isExist) {
        transaction.update(taskStatusHistoryRef, {
          history: arrayUnion(
            ...[
              {
                taskStatus: taskStatus,
                taskStatusChangedByUser: updateByUser,
                updatedTime: Timestamp.now(),
              },
            ]
          ),
        })
      } else {
        transaction.set(taskStatusHistoryRef, {
          history: arrayUnion(
            ...[
              {
                taskStatus: taskStatus,
                taskStatusChangedByUser: updateByUser,
                updatedTime: Timestamp.now(),
              },
            ]
          ),
        })
      }
      tasks.push({ taskId: key })
    }
    transaction.update(todoRef, bulkTaskRefUpdateObj(tasks))
  })
}

export const createUpdateTodoTask = async (
  todoListBuild: TodoListBuilder,
  taskBuild?: TaskBuilder | null,
  publishRemoteTasks = false,
  markAllAsDone?: boolean,
  skipUpdateTodoList?: boolean
): Promise<TodoTaskCreateResponse> => {
  try {
    let todoListId = todoListBuild.id ? todoListBuild.id : ''
    let message = ''
    let isNewTodoList = false
    if (todoListId === '') {
      if (!todoListBuild.createdByUser && !!taskBuild?.updatedByUser) {
        todoListBuild.setUpdatedByUser(taskBuild.updatedByUser)
        todoListBuild.setCreatedByUser(taskBuild.updatedByUser)
      }

      const todoList = await createTodoList(todoListBuild)
      todoListBuild.setId(todoList?.id || '')

      todoListId = todoList?.id || ''

      message = 'Todo list created successfully'
      isNewTodoList = true
    } else {
      if (!skipUpdateTodoList) {
        const todoList = await updateTodoList(todoListBuild)
        todoListBuild.setId(todoList?.id || '')
        todoListId = todoList?.id || ''
        message = 'Todo list updated successfully'
      } else {
        const todoList = await updateTodoListUpdatedByOnly(todoListBuild)
        todoListBuild.setId(todoList?.id || '')
        todoListId = todoList?.id || ''
      }
    }

    const todoListSnap = (await getTodoList(
      todoListId,
      true
    )) as DocumentSnapshot<DocumentData>

    if (!todoListSnap.exists()) {
      throw Error('TodoList not found')
    }

    /*===================Add task to todo List======================*/
    let updateTaskBuild = null
    let isNewTask = false
    if (taskBuild) {
      taskBuild.setTodoListId(todoListId)
      if (!!todoListBuild?.publishStatus) {
        taskBuild.setPublishStatus(todoListBuild.publishStatus)
      }
      //create new task
      if (taskBuild?.taskId === null || taskBuild?.taskId === undefined) {
        updateTaskBuild = await addTask(todoListSnap, taskBuild)
        isNewTask = true
      } else {
        //update task
        updateTaskBuild = await updateTask(todoListSnap, taskBuild)
      }
    }
    /*===================End: Add task to todo List======================*/

    //update other task with publishStatus
    if (publishRemoteTasks) {
      await publishRemoteTodoTasks(todoListBuild)
      message = 'Todo list published successfully'
    }
    if (markAllAsDone && todoListBuild.updatedByUser) {
      if (todoListId) {
        const todoListDocRef = doc(fireStoreDb, COLLECTION_TODOLIST, todoListId)
        const todoListSnap = await getDoc(todoListDocRef)

        const tasks = (todoListSnap.data()?.tasks || {}) as TodoListTaskRef
        changeMultipleTaskStatus(
          todoListDocRef,
          tasks,
          todoListBuild.updatedByUser,
          Constants.TASK_STATUS.COMPLETED.key
        )
      }
    }

    return {
      message,
      todoListBuild: todoListBuild.convertToTodoList(
        todoListSnap.data() as TodoList
      ),
      taskBuild: updateTaskBuild ? updateTaskBuild : undefined,
      isNewTask: isNewTask,
      isNewTodoList: isNewTodoList,
    }
  } catch (error) {
    return Promise.reject(error)
  }
}

export const createUpdateTodoTaskBulk = async (
  todoListBuild: TodoListBuilder,
  taskBuilds?: TaskBuilder[] | null,
  publishRemoteTasks = false,
  markAllAsDone?: boolean,
  skipUpdateTodoList?: boolean
): Promise<TodoTaskBulkCreateResponse> => {
  try {
    let todoListId = todoListBuild.id ? todoListBuild.id : ''

    let message = ''
    let isNewTodoList = false
    if (todoListId === '') {
      if (
        !!taskBuilds &&
        taskBuilds.length > 0 &&
        !!taskBuilds[0].updatedByUser
      ) {
        todoListBuild.setCreatedByUser(taskBuilds[0].updatedByUser)
        todoListBuild.setUpdatedByUser(taskBuilds[0].updatedByUser)
      }

      const todoList = await createTodoList(todoListBuild)
      todoListBuild.setId(todoList?.id || '')

      todoListId = todoList?.id || ''

      message = 'Todo list created successfully'
      isNewTodoList = true
    } else {
      if (!skipUpdateTodoList) {
        const todoList = await updateTodoList(todoListBuild)
        todoListBuild.setId(todoList?.id || '')
        todoListId = todoList?.id || ''
        message = 'Todo list updated successfully'
      } else {
        const todoList = await updateTodoListUpdatedByOnly(todoListBuild)
        todoListBuild.setId(todoList?.id || '')
        todoListId = todoList?.id || ''
      }
    }

    const todoListSnap = (await getTodoList(
      todoListId,
      true
    )) as DocumentSnapshot<DocumentData>

    if (!todoListSnap.exists()) {
      throw Error('TodoList not found')
    }

    /*===================Add task to todo List======================*/
    let updateTaskBuild = null
    let isNewTask = false
    if (!!taskBuilds && (taskBuilds || []).length > 0) {
      const modifiedTaskBuilds: TaskBuilder[] = taskBuilds.map((taskBuild) => {
        taskBuild.setTodoListId(todoListId)
        if (!!todoListBuild?.publishStatus) {
          taskBuild.setPublishStatus(todoListBuild.publishStatus)
        }
        return taskBuild
      })
      const newTasks = modifiedTaskBuilds?.some(
        (modifiedTaskBuild): boolean =>
          !(modifiedTaskBuild as any)?.taskId ?? null
      )

      if (newTasks) {
        updateTaskBuild = await addTasks(todoListSnap, modifiedTaskBuilds)
        isNewTask = true
      }
    }
    /*===================End: Add task to todo List======================*/

    //update other task with publishStatus
    if (publishRemoteTasks) {
      await publishRemoteTodoTasks(todoListBuild)
      message = 'Todo list published successfully'
    }
    if (markAllAsDone && todoListBuild.updatedByUser) {
      if (todoListId) {
        const todoListDocRef = doc(fireStoreDb, COLLECTION_TODOLIST, todoListId)
        const todoListSnap = await getDoc(todoListDocRef)

        const tasks = (todoListSnap.data()?.tasks || {}) as TodoListTaskRef
        changeMultipleTaskStatus(
          todoListDocRef,
          tasks,
          todoListBuild.updatedByUser,
          Constants.TASK_STATUS.COMPLETED.key
        )
      }
    }

    return {
      message,
      todoListBuild: todoListBuild.convertToTodoList(
        todoListSnap.data() as TodoList
      ),
      taskBuild: updateTaskBuild ? updateTaskBuild : undefined,
      isNewTask: isNewTask,
      isNewTodoList: isNewTodoList,
    }
  } catch (error) {
    return Promise.reject(error)
  }
}

/*=============Read data=================*/

export const getTaskById = async (taskId: string | null): Promise<Task> => {
  try {
    if (taskId === null) {
      throw Error('Task id is null')
    }
    const docRef = doc(fireStoreDb, COLLECTION_TODOLIST_TASKS, taskId)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      return docSnap.data() as Task
    } else {
      throw Error('Task not found')
    }
  } catch (error) {
    return handleFirebaseAuthenticationError(error)
  }
}

export const getTasksByTodoListId = async (todoListId: string | null) => {
  try {
    if (todoListId === null) {
      throw Error('Todo list id is null')
    }
    const docSnap = await getDocs(
      query(
        collection(fireStoreDb, COLLECTION_TODOLIST_TASKS),
        where('todoListId', '==', todoListId),
        limit(Constants.TODO_THREAD_PREVIEW_TASK_COUNT)
      )
    )
    return docSnap.docs.map((doc) => doc.data()) as Task[]
  } catch (error) {
    return handleFirebaseAuthenticationError(error)
  }
}

export const getTodoListTasksSubscribe = (
  todoListId: string,
  onTodoListTasksChanges: (
    documentSnapshot: QuerySnapshot<DocumentData>
  ) => void,
  onError: (error: FirestoreError) => void
): Unsubscribe => {
  try {
    const unsubscribe = onSnapshot(
      query(
        collection(fireStoreDb, COLLECTION_TODOLIST_TASKS),
        where('todoListId', '==', todoListId)
      ),
      onTodoListTasksChanges,
      onError
    )
    return unsubscribe
  } catch (error) {
    return handleFirebaseAuthenticationError(error)
  }
}

export const getTodoTasksSubscribe = async (effectiveUserId: string | null) => {
  try {
    if (effectiveUserId === null) {
      throw Error('effective user id id is null')
    }
    const docSnap = await getDocs(
      query(
        collection(fireStoreDb, COLLECTION_TODOLIST_TASKS),
        where('taggedEffectiveUserIds', 'array-contains-any', [
          effectiveUserId,
        ]),
        where('taskStatus', '==', Constants.TASK_STATUS?.OUTSTANDING.key),
        where('publishStatus', '==', Constants.PUBLISH_STATUS.PUBLISH),
        limit(Constants.LATEST_TASK_COUNT),
        orderBy('updatedTime', 'desc')
      )
    )
    return docSnap.docs.map((doc) => doc.data()) as Task[]
  } catch (error) {
    return handleFirebaseAuthenticationError(error)
  }
}

export const getUserTasksByStatusType = async (
  effectiveUserId: string,
  taskStatus: string
): Promise<Task[]> => {
  try {
    const docSnap = await getDocs(
      query(
        collection(fireStoreDb, COLLECTION_TODOLIST_TASKS),
        where('taggedEffectiveUserIds', 'array-contains-any', [
          effectiveUserId,
        ]),
        where('taskStatus', '==', taskStatus),
        where('publishStatus', '==', Constants.PUBLISH_STATUS.PUBLISH),
        orderBy('updatedTime', 'desc')
      )
    )
    return docSnap.docs.map((doc) => doc.data()) as Task[]
  } catch (error) {
    return handleFirebaseAuthenticationError(error)
  }
}

export const changeTaskStatus = async (
  taskBuild: TaskBuilder
): Promise<TaskBuilder> => {
  try {
    if (!taskBuild.taskId) {
      throw Error('task not synchronized')
    }
    const taskDocRef = doc(
      fireStoreDb,
      COLLECTION_TODOLIST_TASKS,
      taskBuild.taskId as string
    )

    const todoDocRef = doc(
      fireStoreDb,
      COLLECTION_TODOLIST,
      taskBuild.todoListId as string
    )

    await runTransaction(fireStoreDb, async (transaction: Transaction) => {
      const task = taskBuild.build()

      transaction.update(taskDocRef, {
        taskStatus: task.taskStatus,
        taskStatusChangedByUser: task.taskStatusChangedByUser,
        updatedByUser: task.taskStatusChangedByUser,
        updatedTime: serverTimestamp(),
      })

      transaction.update(todoDocRef, {
        [`tasks.${taskBuild.taskId}`]: {
          reference: taskDocRef,
          updatedTime: serverTimestamp(),
        },
      })
    })

    const taskStatusHistoryDocRef = doc(
      fireStoreDb,
      COLLECTION_TASK_STATUS_CHANGE_HISTORY,
      taskBuild.taskId as string
    )

    const isExists = (await getDoc(taskStatusHistoryDocRef)).exists()
    if (isExists) {
      await updateDoc(taskStatusHistoryDocRef, {
        history: arrayUnion(
          ...[
            {
              taskStatus: taskBuild.taskStatus,
              taskStatusChangedByUser: taskBuild.taskStatusChangedByUser,
              updatedTime: Timestamp.now(),
            },
          ]
        ),
      })
    } else {
      await setDoc(taskStatusHistoryDocRef, {
        history: arrayUnion(
          ...[
            {
              taskStatus: taskBuild.taskStatus,
              taskStatusChangedByUser: taskBuild.taskStatusChangedByUser,
              updatedTime: Timestamp.now(),
            },
          ]
        ),
      })
    }

    return taskBuild
  } catch (error) {
    return handleFirebaseAuthenticationError(error)
  }
}

export const deleteTask = async (
  taskBuild: TaskBuilder,
  taskDeletedUser: User
): Promise<TaskBuilder> => {
  const taskRef = doc(
    fireStoreDb,
    COLLECTION_TODOLIST_TASKS,
    taskBuild.taskId as string
  )

  if (!taskBuild.todoListId) {
    throw Error('Todo list id not found')
  }

  const todoListRef = doc(
    fireStoreDb,
    COLLECTION_TODOLIST,
    taskBuild.todoListId as string
  )

  const res = runTransaction(fireStoreDb, async (transaction: Transaction) => {
    const taskDoc = await getDoc(taskRef)
    const todoDoc = await getDoc(todoListRef)

    if (taskDoc.exists() && todoDoc.exists()) {
      const updatedTaskBuilder = new TaskBuilder()
        .convertToTask(taskDoc.data() as Task)
        .setUpdatedByUser(taskDeletedUser as User)

      const todoListData = todoDoc.data()

      const remainTasks = todoListData?.tasks || {}
      delete remainTasks[taskBuild.taskId as string]

      const deletedTaskRef = doc(
        fireStoreDb,
        COLLECTION_DELETED_TODOLIST_TASKS,
        taskBuild.taskId as string
      )

      transaction.set(deletedTaskRef, taskDoc.data())

      transaction.update(todoListRef, {
        [`deletedTasks.${updatedTaskBuilder.taskId}`]: {
          reference: deletedTaskRef,
          deletedUser: updatedTaskBuilder.updatedByUser,
          updatedTime: serverTimestamp(),
        },
        tasks: remainTasks,
        updatedTime: serverTimestamp(),
      })

      transaction.delete(taskRef)
      return taskBuild
    } else {
      throw Error('Task or todo list not found')
    }
  })

  return res
}

export const updateTaskListOrder = async (
  updatedByUser: User,
  tasks: TaskBuilder[]
) => {
  const batch = writeBatch(fireStoreDb)

  for (const task of tasks) {
    const todoListDocRef = doc(
      fireStoreDb,
      COLLECTION_TODOLIST_TASKS,
      task.taskId as string
    )
    const taskData = await getDoc(todoListDocRef)
    if (taskData.exists()) {
      batch.update(taskData.ref, {
        sequence: task.sequence,
        updatedByUser: updatedByUser,
        updatedTime: serverTimestamp(),
      })
    }
  }

  await batch.commit()
}
