import { ReactNode } from 'react'
import { FlatList, View } from 'react-native'
import StyleSheet from 'react-native-media-query'
import {
  ComponentOptions,
  AppColors,
  Text,
  Spacer,
} from '@tealsapp/teals-components'
import { ProgressStatus } from '../types/Common'

const { styles } = StyleSheet.create({
  statusBarStyle: {
    height: 3,
    borderRadius: 2,
  },
  statusItemWrapper: {
    flexDirection: 'column',
    alignItems: 'center',
    marginHorizontal: ComponentOptions.SPACES.EXTRA_SMALL,
  },
  statusItemText: {
    flexDirection: 'row',
    marginTop: ComponentOptions.SPACES.SMALL,
    alignContent: 'center',
    alignItems: 'center',
  },
})

type Props = {
  barWidth: number
  leftElement?: ReactNode
  stepCompleteColor: string
  stepIncompleteColor: string
  progressStatus: ProgressStatus[]
}

const CustomStatusBar = ({
  barWidth,
  leftElement,
  stepCompleteColor,
  stepIncompleteColor,
  progressStatus,
}: Props) => {
  return (
    <FlatList
      data={progressStatus}
      extraData={progressStatus}
      keyExtractor={(index) => index.toString()}
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
      horizontal={true}
      renderItem={({ item }) => {
        return (
          <View style={styles.statusItemWrapper}>
            <View
              style={[
                styles.statusBarStyle,
                { width: barWidth },
                item.stepCompleted
                  ? { backgroundColor: stepCompleteColor }
                  : { backgroundColor: stepIncompleteColor },
              ]}
            />
            <View style={styles.statusItemText}>
              {!!leftElement && item.stepCompleted && leftElement}
              {!!leftElement && item.stepCompleted && (
                <Spacer isVertical size={ComponentOptions.SPACES.SMALL} />
              )}
              <Text
                body_small
                bold
                color={
                  item.stepCompleted
                    ? AppColors.common.white
                    : AppColors.common.subtitleText
                }
              >
                {item.name}
              </Text>
            </View>
          </View>
        )
      }}
    />
  )
}

export default CustomStatusBar
