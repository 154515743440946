import { StyleSheet, View } from 'react-native'
import {
  ComponentOptions,
  Spacer,
  Shimmer,
  Icon,
  AppColors,
  Text,
} from '@tealsapp/teals-components'
import { ClipLayout } from '../../types/ClipCentral'

const styles = StyleSheet.create({
  wrapperStyle: {
    flex: 1,
    justifyContent: 'space-between',
    flexDirection: 'column',
    paddingTop: ComponentOptions.SPACES.LARGE,
  },
  clipPreviewContainerStyle: {
    alignItems: 'center',
    paddingTop: ComponentOptions.SPACES.LARGE * 3,
  },
  clipFooterContainerStyle: {
    flexDirection: 'row',
    paddingVertical: ComponentOptions.SPACES.LARGE,
    justifyContent: 'center',
  },
  clipContainerStyle: {
    width: 375,
    height: 676,
    borderRadius: ComponentOptions.SPACES.MEDIUM,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: AppColors.common.rm_grey1,
  },
  clipHighlightViewContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: 1,
    paddingRight: ComponentOptions.SPACES.LARGE,
  },
  clipHighlightViewWrapper: {
    paddingTop: ComponentOptions.SPACES.LARGE,
    paddingLeft: ComponentOptions.SPACES.LARGER,
  },
  clipContainerLoadingStyle: {
    paddingRight: ComponentOptions.SPACES.LARGE,
    marginBottom: ComponentOptions.SPACES.LARGE,
  },
})

type ClipPreviewLoadingProps = {
  isLoading: boolean
  currentLayout: ClipLayout
}
const ClipPreviewLoading = (props: ClipPreviewLoadingProps) => {
  const { isLoading, currentLayout } = props
  return (
    <View style={styles.wrapperStyle}>
      {isLoading &&
        (currentLayout === ClipLayout.highlight ? (
          <View style={styles.clipPreviewContainerStyle}>
            <Shimmer
              width={375}
              height={676}
              borderRadius={ComponentOptions.SPACES.MEDIUM}
            />
          </View>
        ) : null)}

      {isLoading &&
        (currentLayout === ClipLayout.slides ? (
          <View style={styles.clipHighlightViewWrapper}>
            <Shimmer height={40} width={300} />
            <Spacer />
            <Shimmer height={20} width={300} />
            <Spacer />
            <View style={styles.clipHighlightViewContainer}>
              {Array.from(Array(5)).map((item) => (
                <View
                  style={styles.clipContainerLoadingStyle}
                  key={`itemLoading${item}`}
                >
                  <Shimmer
                    height={234}
                    width={168}
                    borderRadius={ComponentOptions.SPACES.MEDIUM}
                  />
                </View>
              ))}
            </View>
          </View>
        ) : null)}

      {!isLoading && (
        <View style={styles.clipPreviewContainerStyle}>
          <View style={styles.clipContainerStyle}>
            <Icon name={`Clip`} size={80} fill={AppColors.common.grey} />
            <Spacer />
            <Text h3 color={AppColors.common.black}>{`Clip not found`}</Text>
          </View>
        </View>
      )}

      {isLoading && (
        <View style={styles.clipFooterContainerStyle}>
          <Shimmer
            width={50}
            height={50}
            borderRadius={ComponentOptions.SPACES.MEDIUM}
          />
          <Spacer isVertical />
          <Shimmer
            width={50}
            height={50}
            borderRadius={ComponentOptions.SPACES.MEDIUM}
          />
          <Spacer isVertical />
          <Shimmer
            width={50}
            height={50}
            borderRadius={ComponentOptions.SPACES.MEDIUM}
          />
          <Spacer isVertical />
          <Shimmer
            width={50}
            height={50}
            borderRadius={ComponentOptions.SPACES.MEDIUM}
          />
        </View>
      )}
    </View>
  )
}

export default ClipPreviewLoading
