import React from 'react'
import { View, StyleProp, ViewStyle } from 'react-native'
import StyleSheet from 'react-native-media-query'
import { AppColors, ComponentOptions, Icon } from '@tealsapp/teals-components'
import { Constants, Util } from '../common'
import RightPanel from './RightPanel'
import { NavigationProps } from '../types/Common'

const { styles } = StyleSheet.create({
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  backIconWrapper: {
    marginHorizontal: ComponentOptions.SPACES.SMALL,
    '@media (max-width: 1024px)': {
      visibility: 'visible',
    },
  },
  headerContentWrapper: {
    marginLeft: -ComponentOptions.SPACES.MEDIUM,
    '@media (max-width: 1024px)': {
      marginLeft: 0,
    },
  },
  shadowStyle: {
    borderColor: AppColors.common.toggleShadow,
    borderBottomWidth: 1,
  },
  contentWrapper: {
    height: '100%',
  },
})

type MainContentProps = {
  containerStyles?: StyleProp<ViewStyle>
  backgroundColor: string
  sideBorders: boolean
  headerContainerStyles?: StyleProp<ViewStyle>
  headerHeight?: number
  header?: React.ReactElement //This is title component only
  children?: React.ReactNode
  rightPanelContainerStyles?: StyleProp<ViewStyle>
  rightPanel?: React.ReactNode
  rightPanelNavigationProps?: NavigationProps
  onCloseRightPanelCallback?: () => void
  onCloseMainContentCallback?: () => void
  hideHeader: boolean
  hideLeftIcon?: boolean
  renderAbsoluteContent?: React.ReactNode
} & typeof DefaultMainContentProps

const DefaultMainContentProps = {
  backgroundColor: 'transparent',
  sideBorders: true,
  headerHeight: 100,
  onCloseRightPanelCallback: () => {},
  onCloseMainContentCallback: () => {},
  hideHeader: false,
}

function MainContent({
  containerStyles,
  backgroundColor,
  sideBorders,
  headerContainerStyles,
  headerHeight,
  header,
  children,
  rightPanelContainerStyles,
  rightPanel,
  rightPanelNavigationProps,
  hideLeftIcon,
  onCloseMainContentCallback,
  hideHeader,
  renderAbsoluteContent,
}: MainContentProps) {
  const { width } = Util.getWindowDimensions()
  function getResponsiveMainContentWidth() {
    if (width <= Constants.BREAK_POINT) {
      return '100vw'
    } else if (!rightPanel && width > Constants.BREAK_POINT) {
      return 'calc(100vw - 425px)'
    } else {
      return 'calc(100vw - 800px)'
    }
  }

  function getResponsiveMainContentHight() {
    if (width <= Constants.BREAK_POINT) {
      return `calc(100vh - ${headerHeight + 51}px)` // because of the top menu
    } else {
      return `calc(100vh - ${headerHeight}px)`
    }
  }

  function renderHeader() {
    return (
      <View
        style={[
          styles.header,
          styles.shadowStyle,
          headerContainerStyles,
          { height: `${headerHeight}px` },
        ]}
      >
        {!hideLeftIcon && (
          <Icon
            name={'ChevronLeft'}
            wrapperStyles={styles.backIconWrapper}
            fill={AppColors.common.grey}
            size={20}
            onPress={() => onCloseMainContentCallback()}
          />
        )}
        <View style={{ flex: 1 }}>{header}</View>
      </View>
    )
  }

  function renderRightPanel() {
    return (
      rightPanel && (
        <RightPanel
          containerStyles={rightPanelContainerStyles}
          navigationProps={rightPanelNavigationProps}
        >
          {rightPanel}
        </RightPanel>
      )
    )
  }

  return (
    <View style={{ flexDirection: 'row' }}>
      <View
        style={[
          { backgroundColor },
          sideBorders ? styles.shadowStyle : {},
          containerStyles,
          { width: getResponsiveMainContentWidth() },
          {
            display:
              rightPanel && width <= Constants.BREAK_POINT ? 'none' : 'flex',
          },
          { flexDirection: 'column' },
        ]}
      >
        {hideHeader ? null : renderHeader()}
        <View
          style={[
            { height: getResponsiveMainContentHight() },
            { backgroundColor },
          ]}
        >
          <View style={styles.contentWrapper}>{children}</View>
        </View>
      </View>
      {renderRightPanel()}
      {renderAbsoluteContent && renderAbsoluteContent}
    </View>
  )
}

MainContent.defaultProps = DefaultMainContentProps

export default MainContent
