import { useEffect, useRef, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useCallManagerContext } from '../../context/CallManagerContext'
import { Call, CallingState, useCalls } from '@stream-io/video-react-sdk'
import {
  ComponentOptions,
  AppColors,
  useTheme,
  Spacer,
  Text,
} from '@tealsapp/teals-components'
import LinearGradient from 'react-native-linear-gradient'
import ActionButton from './ActionButton'
import { Constants, Util } from '../../common'
import Avatar from '../../components/Avatar'
import ringTone from '../../Assets/ring-tone.wav'

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    width: 200,
    height: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    borderRadius: ComponentOptions.SPACES.SMALL,
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.4)',
  },
  incommingCallWrapper: {
    flex: 1,
    width: '100%',
    flexDirection: 'row',
    height: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
  },
  callerwrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: ComponentOptions.SPACES.SMALL,
    backgroundColor: AppColors.common.dark,
  },
})

const draggableWrapperStyles = {
  flex: 1,
  left: 50,
  zIndex: 3000,
  top: window.innerHeight - 200,
  width: 'auto',
  position: 'absolute' as const,
  height: 'auto',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: AppColors.common.transparent,
}

const RingingPopUp = () => {
  const theme = useTheme()

  const newCallRef = useRef<Call | null>(null)
  const isPressedContainer = useRef(false)

  const calls = useCalls()

  const { call, streamClient, setCall, setCallState } = useCallManagerContext()

  const [hasARingingCall, setHasARingingCall] = useState(false)

  useEffect(() => {
    newCallRef.current = null
  }, [])

  useEffect(() => {
    if (calls.length === 1 && call && call?.id === calls?.[0]?.id) return

    if (newCallRef.current) return

    for (const call of calls) {
      if (
        newCallRef.current &&
        call?.id === newCallRef.current?.id &&
        call?.state?.callingState === CallingState.LEFT
      ) {
        newCallRef.current = null
        return
      }
      if (
        !newCallRef.current &&
        call?.state?.callingState === CallingState.RINGING
      ) {
        playRingTone()
        newCallRef.current = call
        setHasARingingCall(true)
        break
      }
    }
  }, [calls])

  useEffect(() => {}, [newCallRef.current, hasARingingCall])

  async function acceptCall() {
    if (call) {
      Util.showErrorMessage('You are already on a call. End it first to join.')
      return
    }
    if (newCallRef.current) {
      try {
        const call = streamClient?.call('gt_video_call', newCallRef.current?.id)
        if (call) {
          newCallRef.current = null
          await call.get()
          setCall(call)
          setCallState({
            isCreator: false,
            initiatingCall: true,
          })
        }
      } catch (e) {
        Util.showErrorMessage('Could not join to the call at the moment.')
      }
    }
  }

  async function rejectCall() {
    try {
      if (newCallRef.current) {
        const call = streamClient?.call('gt_video_call', newCallRef.current?.id)
        await call?.leave()
      }
    } catch (e) {
    } finally {
      newCallRef.current = null
      setHasARingingCall(false)
    }
  }

  function playRingTone() {
    const audio = document.getElementById(
      Constants.RING_TONE_ID
    ) as HTMLAudioElement

    if (!audio) {
      return
    }

    audio.src = ringTone
    audio.autoplay = true
    audio.loop = true
  }

  if (!newCallRef.current) return <></>

  document.addEventListener('mousemove', (e) => {
    Util.dragThisItem(
      e.clientX,
      e.clientY,
      'RingingPopUp',
      isPressedContainer.current
    )
    e.preventDefault()
  })

  document.addEventListener('touchmove', (e) => {
    Util.dragThisItem(
      e.touches?.[0]?.clientX,
      e.touches?.[0]?.clientY,
      'RingingPopUp',
      isPressedContainer.current
    )
    e.preventDefault()
  })

  document.addEventListener('mouseup', () => {
    if (isPressedContainer.current) isPressedContainer.current = false
  })

  document.addEventListener('touchend', () => {
    if (isPressedContainer.current) isPressedContainer.current = false
  })

  return (
    <div
      id="RingingPopUp"
      style={draggableWrapperStyles}
      onMouseDown={() => (isPressedContainer.current = true)}
      onTouchStart={() => (isPressedContainer.current = true)}
    >
      <audio id={Constants.RING_TONE_ID} src={ringTone} autoPlay loop />
      <View style={styles.wrapper}>
        {newCallRef.current?.state?.createdBy?.name && (
          <View style={styles.callerwrapper}>
            <Avatar
              imageUri={newCallRef.current?.state?.createdBy?.image || ''}
              imageFetchType={Constants.IMAGE_FETCH_TYPE.PROFILE}
              avatarProps={{
                size: ComponentOptions.AVATAR_SIZE.MEDIUM,
                altText: Util.getAcronym(
                  newCallRef.current?.state?.createdBy?.name || ''
                ),
                uniqueId: newCallRef.current?.state?.createdBy?.id || '',
                hasBorder: true,
                borderColor: AppColors.common.white,
              }}
            />
            <Spacer size={ComponentOptions.SPACES.SMALL} />
            <Text body_small color={AppColors.common.white}>
              {newCallRef.current?.state?.createdBy?.name + ' is calling'}
            </Text>
          </View>
        )}
        <LinearGradient
          style={styles.incommingCallWrapper}
          colors={[theme.gradient.color1, theme.gradient.color2]}
        >
          <ActionButton
            enabled={true}
            deviceError={false}
            actionDisabled={false}
            enableIcon={'Call'}
            disableIcon={'call'}
            size={ComponentOptions.SPACES.LARGE}
            toggleAction={acceptCall}
            errorAction={() => {}}
          />
          <Spacer isVertical size={ComponentOptions.SPACES.LARGE} />
          <ActionButton
            enabled={true}
            deviceError={false}
            actionDisabled={false}
            enableIcon={'DetailedHangUp'}
            disableIcon={'DetailedHangUp'}
            color={AppColors.common.red}
            toggleAction={rejectCall}
            errorAction={() => {}}
          />
        </LinearGradient>
      </View>
    </div>
  )
}

export default RingingPopUp
