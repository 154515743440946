import { Image, ImageStyle, StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  logo: {
    width: 263,
    height: 66,
    marginHorizontal: 'auto',
  },
})

type LogoProps = {
  style?: ImageStyle
}

const Logo = ({ style }: LogoProps) => {
  return (
    <Image
      source={require('../images/Raymmer-logo.svg')}
      style={[styles.logo, style]}
      resizeMode={'contain'}
    />
  )
}

export default Logo
