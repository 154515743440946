import {
  useActiveEffectiveUserId,
  useActiveOrgId,
} from '../../context/UserContext'
import * as CallUtil from '../../common/CallUtils'
import QueryKeys from '../../common/QueryKeys'
import { Constants, getAxios } from '../../common'
import { useQuery } from 'react-query'

const api = getAxios()
const apiNoAuth = getAxios()

export const getStreamAuthTokenForBackground = async (
  effectiveUserId: string
) => {
  const resp = await apiNoAuth.get(
    `/user/${effectiveUserId}/calls-registration-token`
  )
  return resp.data.token
}

export const fetchStreamUser = async (effectiveUserId: string) => {
  const url = `/user/${effectiveUserId}/calls-registration-token`
  return await api.post(url)
}

export const useFetchStreamUser = (enabled = true, onError = () => {}) => {
  const activeEffectiveUserId = useActiveEffectiveUserId()
  const orgId = useActiveOrgId()
  return useQuery(
    [QueryKeys.STREAM_USER, orgId, activeEffectiveUserId],
    () => fetchStreamUser(activeEffectiveUserId),
    {
      initialData: undefined,
      enabled,
      onError,
      select: (data) => {
        return data?.data?.user || null
      },
    }
  )
}

const fetchEligableUsersThread = async (threadId: string) => {
  const url = `/call/users?type=thread&thread-id=${threadId}`
  return await api.get(url)
}

const fetchEligableUsersBusiness = async () => {
  return await api.get('/call/users?type=business')
}

const useFetchCurrentBusinessStreamUsers = (
  enabled = true,
  onError = () => {}
) => {
  const orgId = useActiveOrgId()
  return useQuery(
    [
      QueryKeys.STREAM_USERS,
      Constants.STREAM_CALL.CALL_MEMBER_CATEGORRIES.BUSINESS,
      orgId,
    ],
    () => fetchEligableUsersBusiness(),
    {
      initialData: undefined,
      enabled,
      onError,
      cacheTime: 0, // need latest data
      select: (data: any) => {
        return CallUtil.convertToCallMembersLobby(
          Constants.STREAM_CALL.CALL_MEMBER_CATEGORRIES.BUSINESS,
          data?.data?.['user-details'] || []
        )
      },
    }
  )
}

export const useFetchThreadStreamUsers = (
  enabled: boolean,
  threadId: string,
  onError = () => {}
) => {
  return useQuery(
    [
      QueryKeys.STREAM_USERS,
      Constants.STREAM_CALL.CALL_MEMBER_CATEGORRIES.THREAD,
      threadId,
    ],
    () => fetchEligableUsersThread(threadId),
    {
      initialData: undefined,
      enabled,
      onError,
      cacheTime: 0,
      select: (data: any) => {
        return CallUtil.convertToCallMembersLobby(
          Constants.STREAM_CALL.CALL_MEMBER_CATEGORRIES.THREAD,
          data?.data?.['user-details'] || []
        )
      },
    }
  )
}
