import { UploadCategory } from './Files'
import { Affiliation } from './Thread'

export type QuickResponse = {
  messageId: string
  quickResponse: string
  userId: string
  responseBy: string
  orgId: string
  respondedTime?: string
}

export type QuickResponseGroup = {
  count: number
  emoji: string
}

export interface Message {
  createdTime: string
  deliveryStatus: boolean
  individualDelivery: IndividualDelivery
  appSentStatus: SentStatus
  message: string
  messageId: string
  messageSentTime: string
  messageEditedTime: string
  messageType: string
  orgId: string
  parentMessage?: Message
  parentMessageId: string
  participants: string[]
  postedBy: string
  previousMessageId: string
  readInfo: ReadInfo
  sequence: number
  showInfo: ShowInfo
  threadId: string
  threadType: string
  userId: string
  appIsSelected: boolean
  appLoadingMessage: string
  appTotalUploadingProgress: number
  groupInviteMessageType: string
  invitedBy: string
  metadata: Metadata
  quickResponses: QuickResponse[]
  quickResponsesGroup: QuickResponseGroup[]
  source: string
  system: boolean
  isForwarded: boolean
  noForwardIndicator: boolean
  user?: Affiliation
  updatedTime?: string
  appIsDeleting: boolean
  appIsMessageGroupingNotEnabled: boolean
  messageGroupId: string
  messageGroupSize: number
  messageGroupIndex: number
}

export interface IndividualDelivery {
  [id: string]: boolean
}

export interface ReadInfo {
  [id: string]: boolean
}

export interface ShowInfo {
  [id: string]: boolean
}

export interface Metadata {
  media: Media[]
  deletedMessage: boolean
}

export interface Media {
  key: string
  type: string
  path?: string
  fileName?: string
  clipId?: string
  businessId?: string
  businessName?: string
  businessLogo?: string
  businessTrades: string[]
  handle?: string
  thumbnail?: Thumbnail[]
  todoListId?: string
}

export interface Thumbnail {
  path: string
}

export interface ChatMessage {
  _id: string
  text: string
  createdAt: Date
  user: ChatUser
  messageObj: Message
  seenStatus: SeenStatus
  readStatus: ReadStatus
  actionType: string
  image?: string
  media?: Media[]
  isSelected: boolean
  loadingMessage: boolean
  sequence: number
  show: boolean
  system?: boolean
  deliveryStatus: DeliveryStatus
  appIsMessageGroupingNotEnabled: boolean
  messageGroupId: string
  messageGroupSize: number
  messageGroupIndex: number
}

export interface ForwardThreadListMessage {
  messageObj: {
    metadata: Metadata
  }
  text: string
}

export interface ChatUser {
  _id: string
  name: string
  avatar: string
  affiliationType: string
  userId: string
}

export interface ToolbarMedia {
  key: string
  name: string
  type?: UploadCategory
  file?: File
  status?: string
  url?: string
  groupId?: string
}

export interface LiveTypingUser {
  userId: string
  altText: string
  uniqueId: string
  image: {
    s3Key: string
  }
  createdTime: number
  effectiveUserId: string
}

export enum ClipMode {
  close = 'close',
  edit = 'edit',
  preview = 'preview',
}

export enum FilesMode {
  close = 'close',
  edit = 'edit',
  preview = 'preview',
}

export enum HoverAction {
  emoji = 'emoji',
  reply = 'reply',
  menu = 'menu',
  delete = 'delete',
  emojiList = 'emojiList',
  emojiChooser = 'emojiChooser',
  forward = 'forward',
  info = 'info',
  translate = 'translate',
  revertTranslate = 'revertTranslate',
}

export type EmojiPreview = {
  data: any
  summary: any
}

export interface MessageInfo {
  effectiveUserId: string
  gtCreatedTime: string
  gtUpdatedTime: string
  messageId: string
  readInfo: boolean
  readTime: string
  sentInfo: boolean
  sentTime: string
  showInfo: boolean
  threadFilter: string
  threadId: string
}

export enum SentStatus {
  sending = 'sending',
  sent = 'sent',
  failed = 'failed',
}
export enum SeenStatus {
  seen = 'seen',
  seenAll = 'seen-all',
  notAvailable = 'not-available',
}
export enum DeliveryStatus {
  delivered = 'delivered',
  deliveryAll = 'delivered-all',
  notAvailable = 'not-available',
}
export enum ReadStatus {
  read = 'read',
  readAll = 'read-all',
  notAvailable = 'not-available',
}

export type ForwardMessageObject = {
  'message-id': string
  'user-id': string
  'posted-by': string
  message: string
  'thread-id': string
  metadata: {
    media: {
      'todolist-id': string
      type: string
    }[]
  }
  sequence: string
  'show-info': { [effectiveUserId: string]: boolean }
}

export type ForwardMessage = {
  threadId: string
  message: ForwardMessageObject
  queryKey: string[]
}
