import React, { useEffect, useState } from 'react'
import LinearGradient from 'react-native-linear-gradient'
import { StyleSheet, View } from 'react-native'
import {
  AppColors,
  ComponentOptions,
  Text,
  Button,
  Icon,
  Spacer,
} from '@tealsapp/teals-components'
import { Constants, MessageUtils, Util } from '../../common'
import { GalleyType, UploadItem } from '../../types/Files'

import ClipDropZone from './ClipDropZone'
import ClipPreviewer from './ClipPreviewer'
import ClipImageUploader from './ClipImageUploader'
import { useGalleryTabContext } from '../../context/GalleryTabContext'

const styles = StyleSheet.create({
  mainWrapper: {
    backgroundColor: AppColors.common.white,
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  wrapperStyle: {
    width: '100%',
    height: '100%',
  },
  headerStyle: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: ComponentOptions.SPACES.LARGE,
    alignItems: 'center',
  },
  footerStyle: {
    backgroundColor: AppColors.common.concrete,
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 100,
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingHorizontal: ComponentOptions.SPACES.LARGE,
  },
  emptySlideWrapperStyle: {
    justifyContent: 'center',
    flex: 1,
    alignItems: 'center',
  },
  emptySlideContainerStyle: {
    width: 375,
    height: 676,
    justifyContent: 'center',
    borderBottomLeftRadius: ComponentOptions.SPACES.SMALL,
    borderBottomRightRadius: ComponentOptions.SPACES.SMALL,
  },
  emptySlideCaptionContainerStyle: {
    paddingTop: ComponentOptions.SPACES.LARGE * 5,
    paddingLeft: ComponentOptions.SPACES.LARGEST,
  },
})

const EmptyClip = () => {
  return (
    <View style={styles.emptySlideWrapperStyle}>
      <LinearGradient
        style={styles.emptySlideContainerStyle}
        colors={[AppColors.common.white, AppColors.common.dark]}
        locations={[0, 3]}
      >
        <View style={styles.emptySlideCaptionContainerStyle}>
          <Icon name={'AddImage'} fill={AppColors.common.primary} size={40} />
          <Spacer size={ComponentOptions.SPACES.SMALL} />
          <Text h2 color={AppColors.common.primary}>{`Empty clip`}</Text>
        </View>
      </LinearGradient>
    </View>
  )
}

type ClipMediaSelectProps = {
  onClose: () => void
  onPressAddClipNext: (stories: UploadItem[]) => void
  editMode: boolean
  onPressOpenGallery?: (isVisible: boolean) => void
  isEmptyClipCreation?: boolean
}

function ClipMediaSelection({
  onClose,
  onPressAddClipNext,
  editMode,
  onPressOpenGallery,
  isEmptyClipCreation,
}: ClipMediaSelectProps) {
  const [showDropZone, setShowDropZone] = useState(false)
  const [media, setMedia] = useState<UploadItem[]>([])
  const [galleryMedia, setGalleryMedia] = useState<UploadItem[]>([])
  const [selectedMediaIndex, setSelectedMediaIndex] = useState<number>(0)

  const { selectedItems, removeFromSelected } = useGalleryTabContext()

  useEffect(() => {
    setShowDropZone(media.length === 0 && galleryMedia.length === 0)
    if (selectedMediaIndex === -1) {
      setSelectedMediaIndex(0)
    }
  }, [media, galleryMedia])

  useEffect(() => {
    if (
      Constants.UPLOAD_LIMITS.CLIP_SLIDE_BULK_UPLOADING_COUNT -
        galleryMedia.length -
        media.length -
        1 >=
      0
    ) {
      setGalleryMedia(selectedItems)
    }
    if (
      selectedItems.length + media.length >
      Constants.UPLOAD_LIMITS.CLIP_SLIDE_BULK_UPLOADING_COUNT
    ) {
      removeFromSelected(selectedItems[selectedItems.length - 1])
      Util.showErrorMessage(
        `Can't upload more than ${Constants.UPLOAD_LIMITS.CLIP_SLIDE_BULK_UPLOADING_COUNT} items to a clip`
      )
    }
  }, [selectedItems, galleryMedia])

  const cleanceMedia = async (items: UploadItem[]) => {
    const promises = items
      .filter((item) => item.galleryType === GalleyType.video && !item.thumbUrl)
      .map(async (item: UploadItem) => {
        //set thumb
        const thumbUrl = await MessageUtils.createThumbnail(
          item.file as File,
          10000
        )
        item.thumbUrl = String(thumbUrl)
        // set url
        const buffer = await item.file?.arrayBuffer()
        const url = URL.createObjectURL(new Blob([buffer || '']))
        item.uploadingUrl = url
      })
    await Promise.all(promises)
    setMedia(items)
    setShowDropZone(items.length === 0)
  }

  return (
    <View style={styles.mainWrapper}>
      <View style={styles.wrapperStyle}>
        <View style={styles.headerStyle}>
          <Text body_strong color={AppColors.common.black}>
            {isEmptyClipCreation
              ? 'Create Empty collection'
              : editMode
              ? 'Create collection'
              : 'Preview'}
          </Text>
          {editMode && (
            <Button
              title={`Close`}
              onPress={onClose}
              textProps={{ body_strong: true }}
              type={ComponentOptions.BUTTON_TYPES.CLEAR}
              buttonColor={AppColors.common.yellow}
              paddingHorizontal={0}
            />
          )}
        </View>

        {isEmptyClipCreation && <EmptyClip />}

        {!isEmptyClipCreation && showDropZone && (
          <ClipDropZone
            onDroppedFile={(list) => {
              const uploadMedia = [...media, ...list]
              cleanceMedia(uploadMedia)
            }}
            onPressOpenGallery={onPressOpenGallery}
            onSelectFiles={(newFiles) => {
              if (
                media.length + newFiles.length <=
                Constants.UPLOAD_LIMITS.CLIP_SLIDE_BULK_UPLOADING_COUNT
              ) {
                const uploadMedia = [...media, ...newFiles]
                cleanceMedia(uploadMedia)
              } else {
                Util.showErrorMessage(
                  `Can't upload more than ${Constants.UPLOAD_LIMITS.CLIP_SLIDE_BULK_UPLOADING_COUNT} items to a clip`
                )
              }
            }}
          />
        )}

        {!isEmptyClipCreation && !showDropZone && (
          <ClipPreviewer
            media={[...media, ...galleryMedia]}
            editMode={editMode}
            onPressDelete={(deletedItem) => {
              const mediaLength = media.length
              const galleryMediaLength = galleryMedia.length

              if (
                !!deletedItem &&
                deletedItem.uploadStatus ===
                  Constants.FILE_UPLOAD_STATUS.UPLOADED
              ) {
                setSelectedMediaIndex(
                  selectedMediaIndex === mediaLength + galleryMediaLength - 1
                    ? selectedMediaIndex - 1
                    : selectedMediaIndex
                )
                removeFromSelected(deletedItem)
              } else {
                const newMediaList = media.filter(
                  (item) =>
                    item.recordKey !== media[selectedMediaIndex].recordKey
                )
                setSelectedMediaIndex(
                  galleryMedia.length === 0
                    ? selectedMediaIndex === mediaLength - 1
                      ? selectedMediaIndex - 1
                      : selectedMediaIndex
                    : selectedMediaIndex
                )
                setMedia(newMediaList)
              }
            }}
            onSubmitClick={(stories) => {
              onPressAddClipNext(stories)
            }}
            selectedIndex={selectedMediaIndex}
          />
        )}
      </View>
      {!isEmptyClipCreation && editMode && (
        <View style={styles.footerStyle}>
          <ClipImageUploader
            media={[...media, ...galleryMedia]}
            onSelectFiles={(newFiles) => {
              if (
                media.length + newFiles.length <=
                Constants.UPLOAD_LIMITS.CLIP_SLIDE_BULK_UPLOADING_COUNT
              ) {
                const uploadMedia = [...media, ...newFiles]
                cleanceMedia(uploadMedia)
              } else {
                Util.showErrorMessage(
                  `Can't upload more than ${Constants.UPLOAD_LIMITS.CLIP_SLIDE_BULK_UPLOADING_COUNT} items to a clip`
                )
              }
            }}
            onPressMedia={(index) => {
              setSelectedMediaIndex(index)
            }}
            onPressOpenGallery={onPressOpenGallery}
          />
        </View>
      )}
    </View>
  )
}

export default ClipMediaSelection
