import React, { useEffect, useState } from 'react'
import { Avatar as AvatarBase } from '@tealsapp/teals-components'
import { fetchMedia } from '../common/AxiosUtil'
import { Constants } from '../common'

type AvatarProps = {
  s3Key?: string
  imageFetchType?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  avatarProps: any
  imageUri?: string
}

const Avatar = ({ s3Key, avatarProps, imageUri }: AvatarProps) => {
  const [s3imageUri, setS3imageUri] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (s3Key && s3Key.length > 0) {
      const size =
        avatarProps?.size <= 20
          ? Constants.IMAGE_SIZES.AVATAR_SMALL
          : Constants.IMAGE_SIZES.AVATAR_LARGE
      fetchMedia(s3Key, size)
        .then((uri) => {
          setS3imageUri(uri)
        })
        .catch(() => {
          setS3imageUri(undefined)
        })
    } else {
      setS3imageUri(undefined)
    }
  }, [s3Key])

  return (
    <div data-img-ids={s3Key}>
      <AvatarBase {...avatarProps} imageUri={imageUri || s3imageUri} />
    </div>
  )
}

export default Avatar
