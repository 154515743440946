import { QueryClient, useMutation, useQueryClient } from 'react-query'
import { TaskBuilder, TodoListBuilder } from '../../prototypes/TodoList'
import * as TodoTaskActions from '../../firebaseActions/ToDoTaskActions'
import * as TodoActions from '../../firebaseActions/ToDoActions'
import { GeneralError, ServerError } from '../../types/Common'
import {
  TaskGalleryMediaCategory,
  TaskGalleryTab,
  TaskGalleryTabKeys,
  TodoTaskBulkCreateResponse,
  TodoTaskCreateResponse,
  User,
} from '../../types/TodoList'
import { useActiveOrgId } from '../../context/UserContext'
import { Constants, QueryKeys, getAxios } from '../../common'
import { deleteTaskMediaCache } from '../../common/TodoUtils'
import { invalidateNotificationQueries } from '../notification/useMutateNotification'

const api = getAxios(true)

export type CreateUpdateTaskToDoParams = {
  todoListBuild: TodoListBuilder
  taskBuild?: TaskBuilder
  publishRemoteTasks?: boolean
  markAllAsDone?: boolean
  skipUpdateTodoList?: boolean
}

export type CreateUpdateBulkTaskToDoParams = {
  todoListBuild: TodoListBuilder
  taskBuilds?: TaskBuilder[]
  publishRemoteTasks?: boolean
  markAllAsDone?: boolean
  skipUpdateTodoList?: boolean
}
export type PublishToDoParams = {
  todoListBuild: TodoListBuilder
}

export type ChangeTaskStatusParams = {
  taskBuild: TaskBuilder
  createTaskResp?: TodoTaskCreateResponse
}

export type TaskDeleteParams = {
  taskBuild: TaskBuilder
  taskDeletedUser: User
}

export type TodoDeleteParams = {
  todoBuild: TodoListBuilder
  todoDeletedUser: User
}

export type TodoListSettingsParams = {
  markAllAsDone: boolean
}

export type TodoTaskOrderParams = {
  updatedByUser: User
  tasks: TaskBuilder[]
  todoListId: string
}

export const useCreateUpdateTaskAndTodoList = (
  onSuccess: (
    data: TodoTaskCreateResponse,
    variables: CreateUpdateTaskToDoParams
  ) => void,
  onErrorCallback: (
    error: GeneralError,
    variables: CreateUpdateTaskToDoParams
  ) => void
) => {
  const queryClient = useQueryClient()
  const activeOrgId = useActiveOrgId()
  return useMutation(
    (variables: CreateUpdateTaskToDoParams) =>
      TodoTaskActions.createUpdateTodoTask(
        variables.todoListBuild,
        variables.taskBuild,
        false,
        variables.markAllAsDone,
        variables.skipUpdateTodoList
      ),
    {
      onSuccess,
      onSettled: (
        data?: TodoTaskCreateResponse,
        error?: any,
        variables?: CreateUpdateTaskToDoParams
      ) => {
        if (!error) {
          const taskId = variables?.taskBuild?.taskId
          const todoListId = variables?.todoListBuild?.id || ''
          const effectiveUserId =
            variables?.todoListBuild.updatedByUser?.effectiveUserId

          queryClient.invalidateQueries([QueryKeys.TASK_BY_ID, taskId])
          queryClient.invalidateQueries([
            QueryKeys.TODO_DETAILS_LOCATIONS,
            todoListId,
          ])

          invalidateCommonQueries(
            queryClient,
            activeOrgId,
            effectiveUserId,
            todoListId
          )
        }
      },
      onError: (error: any, variables: any) => {
        onErrorCallback(error as GeneralError, variables)
      },
    }
  )
}

export const useCreateUpdateBulkTaskAndTodoList = (
  onSuccess: (
    data: TodoTaskBulkCreateResponse,
    variables: CreateUpdateBulkTaskToDoParams
  ) => void,
  onErrorCallback: (
    error: GeneralError,
    variables: CreateUpdateBulkTaskToDoParams
  ) => void
) => {
  const queryClient = useQueryClient()
  const activeOrgId = useActiveOrgId()
  return useMutation(
    (variables: CreateUpdateBulkTaskToDoParams) =>
      TodoTaskActions.createUpdateTodoTaskBulk(
        variables.todoListBuild,
        variables.taskBuilds,
        false,
        variables.markAllAsDone,
        variables.skipUpdateTodoList
      ),
    {
      onSuccess,
      onSettled: (
        data?: TodoTaskBulkCreateResponse,
        error?: any,
        variables?: CreateUpdateBulkTaskToDoParams
      ) => {
        if (!error) {
          const taskBuilds = variables?.taskBuilds
          const todoListId = variables?.todoListBuild?.id || ''
          const effectiveUserId =
            variables?.todoListBuild.updatedByUser?.effectiveUserId

          taskBuilds?.forEach((task) => {
            const taskId = task.taskId
            queryClient.invalidateQueries([QueryKeys.TASK_BY_ID, taskId])
          })
          queryClient.invalidateQueries([
            QueryKeys.TODO_DETAILS_LOCATIONS,
            todoListId,
          ])

          invalidateCommonQueries(
            queryClient,
            activeOrgId,
            effectiveUserId,
            todoListId
          )
        }
      },
      onError: (error: any, variables: any) => {
        onErrorCallback(error as GeneralError, variables)
      },
    }
  )
}

export const usePublishTodoList = (
  onSuccess: (
    data: TodoTaskCreateResponse,
    variables: PublishToDoParams
  ) => void,
  onErrorCallback: (error: GeneralError, variables: PublishToDoParams) => void
) => {
  const queryClient = useQueryClient()
  const activeOrgId = useActiveOrgId()
  return useMutation(
    (variables: PublishToDoParams) =>
      TodoActions.publishTodoList(variables.todoListBuild),
    {
      onSuccess,
      onSettled: (
        data?: TodoTaskCreateResponse,
        error?: any,
        variables?: PublishToDoParams
      ) => {
        const effectiveUserId =
          variables?.todoListBuild?.updatedByUser?.effectiveUserId
        const todoListId = variables?.todoListBuild?.id || ''

        invalidateCommonQueries(
          queryClient,
          activeOrgId,
          effectiveUserId,
          todoListId
        )
      },
      onError: (error: any, variables: any) => {
        onErrorCallback(error as GeneralError, variables)
      },
    }
  )
}

export const useChangeTaskStatus = (
  onSuccess: (data: TaskBuilder, variables: ChangeTaskStatusParams) => void,
  onErrorCallback: (
    error: GeneralError,
    variables: ChangeTaskStatusParams
  ) => void
) => {
  const queryClient = useQueryClient()
  const activeOrgId = useActiveOrgId()
  return useMutation(
    (variables: ChangeTaskStatusParams) =>
      TodoTaskActions.changeTaskStatus(variables.taskBuild),
    {
      onSettled: (
        data?: TaskBuilder,
        error?: any,
        variables?: ChangeTaskStatusParams
      ) => {
        const todoListId = variables?.taskBuild?.todoListId
        const taskId = variables?.taskBuild?.taskId
        queryClient.invalidateQueries([QueryKeys.TASK_BY_ID, taskId])

        const effectiveUserId =
          variables?.taskBuild?.taskStatusChangedByUser?.effectiveUserId

        queryClient.invalidateQueries([
          QueryKeys.TODO_OUTSTANDING_TASKS_HOME,
          effectiveUserId,
        ])

        invalidateCommonQueries(queryClient, activeOrgId, effectiveUserId)
        queryClient.invalidateQueries([
          QueryKeys.TODO_OUTSTANDING_TASKS,
          todoListId,
        ])
      },
      onSuccess: (data, variables: ChangeTaskStatusParams) =>
        onSuccess(data, variables),
      onError: (error: any, variables: ChangeTaskStatusParams) => {
        onErrorCallback(error as GeneralError, variables)
      },
    }
  )
}

export const useDeleteTask = (
  onSuccess: (data: TaskBuilder, variables: TaskDeleteParams) => void,
  onErrorCallback: (error: GeneralError, variables: TaskDeleteParams) => void
) => {
  const queryClient = useQueryClient()
  const activeOrgId = useActiveOrgId()
  return useMutation(
    (variables: TaskDeleteParams) =>
      TodoTaskActions.deleteTask(
        variables.taskBuild,
        variables.taskDeletedUser
      ),
    {
      onSettled: (
        data?: TaskBuilder,
        error?: any,
        variables?: TaskDeleteParams
      ) => {
        const todoListId = variables?.taskBuild?.todoListId as string

        const effectiveUserId = variables?.taskDeletedUser?.effectiveUserId

        invalidateCommonQueries(
          queryClient,
          activeOrgId,
          effectiveUserId,
          todoListId
        )
      },
      onSuccess: (data, variables: TaskDeleteParams) =>
        onSuccess(data, variables),
      onError: (error: any, variables: TaskDeleteParams) => {
        onErrorCallback(error as GeneralError, variables)
      },
    }
  )
}

export const useDeleteTodoList = (
  onSuccess: (data: TodoListBuilder, variables: TodoDeleteParams) => void,
  onErrorCallback: (error: GeneralError, variables: TodoDeleteParams) => void
) => {
  const queryClient = useQueryClient()
  const activeOrgId = useActiveOrgId()
  return useMutation(
    (variables: TodoDeleteParams) =>
      TodoActions.deleteTodoList(
        variables.todoBuild,
        variables.todoDeletedUser
      ),
    {
      onSettled: (
        data?: TodoListBuilder,
        error?: any,
        variables?: TodoDeleteParams
      ) => {
        const todoListId = variables?.todoBuild?.id as string

        const effectiveUserId = variables?.todoDeletedUser?.effectiveUserId

        invalidateCommonQueries(
          queryClient,
          activeOrgId,
          effectiveUserId,
          todoListId
        )
      },
      onSuccess: (data, variables: TodoDeleteParams) =>
        onSuccess(data, variables),
      onError: (error: any, variables: TodoDeleteParams) => {
        onErrorCallback(error as GeneralError, variables)
      },
    }
  )
}

function invalidateCommonQueries(
  queryClient: QueryClient,
  activeOrgId: string,
  effectiveUserId?: string,
  todoListId?: string
) {
  queryClient.invalidateQueries([
    QueryKeys.BUSINESS_LATEST_TODO_LISTS,
    activeOrgId,
  ])

  if (!!todoListId) {
    queryClient.invalidateQueries([QueryKeys.TODOLIST_BY_ID, todoListId])
    queryClient.invalidateQueries([
      QueryKeys.TODOLIST_TASKS_BY_TODO_ID,
      todoListId,
    ])
    queryClient.invalidateQueries([
      QueryKeys.TODO_DETAILS_LOCATIONS,
      todoListId,
    ])
  }

  if (!!effectiveUserId) {
    queryClient.invalidateQueries([
      QueryKeys.USER_TASKS_STATUS_WISE,
      effectiveUserId,
    ])

    queryClient.invalidateQueries([
      QueryKeys.All_DRAFT_TODO_LISTS,
      activeOrgId,
      effectiveUserId,
    ])

    queryClient.invalidateQueries([
      QueryKeys.BUSINESS_All_TODO_LISTS,
      activeOrgId,
    ])

    queryClient.invalidateQueries([
      QueryKeys.TODO_OUTSTANDING_TASKS_HOME,
      effectiveUserId,
    ])

    invalidateNotificationQueries(queryClient, activeOrgId)
  }
}

export const useUpdateTodoListSettings = (
  onSuccess: (
    data: TodoTaskCreateResponse,
    variables: CreateUpdateTaskToDoParams
  ) => void,
  onErrorCallback: (
    error: GeneralError,
    variables: CreateUpdateTaskToDoParams
  ) => void
) => {
  const queryClient = useQueryClient()
  const activeOrgId = useActiveOrgId()

  return useMutation(
    (variables: CreateUpdateTaskToDoParams) => {
      return TodoTaskActions.createUpdateTodoTask(
        variables.todoListBuild,
        variables.taskBuild,
        false,
        variables.markAllAsDone
      )
    },
    {
      onSuccess,
      onSettled: (
        data,
        error,
        variables: { todoListBuild: TodoListBuilder }
      ) => {
        const effectiveUserId =
          variables.todoListBuild?.updatedByUser?.effectiveUserId
        const todoListId = variables.todoListBuild.id
        queryClient.invalidateQueries([QueryKeys.TODO_TASKS, todoListId])
        queryClient.invalidateQueries([QueryKeys.TODO_LIST, todoListId])
        queryClient.invalidateQueries([
          QueryKeys.TODO_OUTSTANDING_TASKS,
          todoListId,
        ])
        if (effectiveUserId) {
          invalidateCommonQueries(
            queryClient,
            activeOrgId,
            effectiveUserId,
            todoListId || ''
          )
        }
      },
      onError: (error: any, variables: any) => {
        onErrorCallback(error as GeneralError, variables)
      },
    }
  )
}

export const useTaskOrderChange = (
  onSuccess: (variables: TodoTaskOrderParams) => void,
  onError: (error: GeneralError, variables: TodoTaskOrderParams) => void
) => {
  const queryClient = useQueryClient()
  const activeOrgId = useActiveOrgId()
  return useMutation(
    (variables: TodoTaskOrderParams) =>
      TodoTaskActions.updateTaskListOrder(
        variables.updatedByUser,
        variables.tasks
      ),
    {
      onSuccess: (data, variables) => onSuccess(variables),
      onSettled: (data, error, variables) => {
        const effectiveUserId = variables.updatedByUser.effectiveUserId

        queryClient.invalidateQueries([
          QueryKeys.TODOLIST_TASKS_BY_TODO_ID,
          variables.todoListId,
        ])
        if (effectiveUserId) {
          invalidateCommonQueries(queryClient, activeOrgId, effectiveUserId)
        }
      },
      onError,
    }
  )
}

export type DeleteMediaFromTaskParams = {
  source: TaskGalleryTab
  todoListId: string
  taskId: string
  mediaModel: {
    key: string
    type: TaskGalleryMediaCategory
    'todo-list-id': string
    'clip-id'?: string //only for clips
  }[]
  tab: TaskGalleryTabKeys
}

const deleteMediaFromTask = async ({
  source,
  todoListId,
  taskId,
  mediaModel,
}: DeleteMediaFromTaskParams) => {
  const body = {
    source: source,
    'todo-list-id': todoListId,
    'media-model': mediaModel,
  }
  // දැනුමට යමක්
  // Note: When you are using delete http method you need to pass object in 'data' attribute to work properly
  return await api.delete(`/tasks/${taskId}/media`, { data: body })
}
export const useDeleteMediaFromTask = (
  onSuccess: (data: any, variables: DeleteMediaFromTaskParams) => void,
  onError: (error: GeneralError, variables: DeleteMediaFromTaskParams) => void
) => {
  const queryClient = useQueryClient()
  const activeOrgId = useActiveOrgId()

  return useMutation(deleteMediaFromTask, {
    onSettled: (data, error, variables) => {
      const { taskId, source } = variables
      queryClient.invalidateQueries([
        QueryKeys.TODO_TASK_GALLERY_ITEMS,
        taskId,
        source,
        activeOrgId,
      ])
      queryClient.invalidateQueries([
        QueryKeys.TODO_TASK_GALLERY_ITEMS_COUNT,
        taskId,
        activeOrgId,
      ])
    },
    onSuccess: (data, variables) => {
      onSuccess(data, variables)
    },
    onError: (error, variables) => onError(error, variables),
  })
}

export type AddMediaToTaskParams = {
  source: TaskGalleryTab
  todoListId: string
  taskId: string
  mediaModel: {
    key: string
    type: TaskGalleryMediaCategory
    'todo-list-id': string
    'clip-id'?: string //only for clips
  }[]
  tab: TaskGalleryTabKeys
}
const addMediaToTask = async ({
  source,
  todoListId,
  taskId,
  mediaModel,
}: AddMediaToTaskParams) => {
  const body = {
    source: source,
    'todo-list-id': todoListId,
    'media-model': mediaModel,
  }
  return await api.post(`/tasks/${taskId}/media`, body)
}
export const useAddMediaToTaskGallery = (
  onSuccessCallback = (data: any, variables: AddMediaToTaskParams) => {},
  onErrorCallBack = (error: ServerError, variables: AddMediaToTaskParams) => {}
) => {
  const queryClient = useQueryClient()
  const activeOrgId = useActiveOrgId()

  return useMutation(addMediaToTask, {
    onSettled: (_data, _error, variables) => {
      const { taskId, source } = variables
      queryClient.invalidateQueries([
        QueryKeys.TODO_TASK_GALLERY_ITEMS,
        taskId,
        source === Constants.GALLERY_UPLOAD_TYPES.DOCUMENTS
          ? TaskGalleryTab.docs
          : source,
        activeOrgId,
      ])
      queryClient.invalidateQueries([
        QueryKeys.TODO_TASK_GALLERY_ITEMS_COUNT,
        taskId,
        activeOrgId,
      ])
    },
    onMutate: (variables: AddMediaToTaskParams) => {
      const { taskId, tab, mediaModel } = variables

      const updatedModel = mediaModel.map((item) => {
        return {
          ...item,
          'object-id':
            tab === TaskGalleryTabKeys.Docs ? item.key : item.key.split('/')[1],
        }
      })
      deleteTaskMediaCache(queryClient, activeOrgId, tab, taskId, updatedModel)
    },
    onSuccess: (data, variables, context) => {
      onSuccessCallback(data, variables)
    },
    onError: (error: any, variables) =>
      onErrorCallBack(error.response?.data as ServerError, variables),
  })
}
