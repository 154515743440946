import {
  collection,
  query,
  where,
  limit,
  orderBy,
  getDocs,
  QuerySnapshot,
  DocumentData,
  onSnapshot,
  addDoc,
  Unsubscribe,
  FirestoreError,
  updateDoc,
  doc,
  serverTimestamp,
  getDoc,
  DocumentSnapshot,
  runTransaction,
  Transaction,
} from 'firebase/firestore'
import { Constants } from '../common'
import {
  getCollectionNameWithPrefix,
  fireStoreDb,
  handleFirebaseAuthenticationError,
} from '../common/FirestoreUtils'
import { FirebaseCollections, PublishStatus, User } from '../types/TodoList'
import { OrderBy } from '../types/Common'
import { Task, TodoList, TodoListBuilder } from '../prototypes/TodoList'
import { createUpdateTodoTask } from './ToDoTaskActions'

const COLLECTION_TODOLIST = getCollectionNameWithPrefix(
  FirebaseCollections.ToDoListCollection
)
const COLLECTION_TODOLIST_TASKS = getCollectionNameWithPrefix(
  FirebaseCollections.TaskCollection
)

const COLLECTION_DELETED_TODOLIST_TASKS = getCollectionNameWithPrefix(
  FirebaseCollections.DeletedTodoListTasksCollection
)

const COLLECTION_DELETED_TODOLISTS = getCollectionNameWithPrefix(
  FirebaseCollections.DeletedToDoListCollection
)

/*==================Save functions =====================================*/

export const createTodoList = async (
  todoListBuild: TodoListBuilder
): Promise<TodoList> => {
  try {
    //no todoListId, create new todoList
    const todoDocRef = collection(fireStoreDb, COLLECTION_TODOLIST)
    const todoListData = todoListBuild.build() as TodoList

    const convertedObj = todoListBuild.convertToFirebaseObject()
    const todoListSnap = addDoc(todoDocRef, {
      ...convertedObj,
      createdTime: serverTimestamp(),
      updatedTime: serverTimestamp(),
      lastSequence: 0,
    })
    const todoListId = (await todoListSnap).id

    //update id in todoList object

    const todoListDocRef = doc(fireStoreDb, COLLECTION_TODOLIST, todoListId)

    const todoListDataUpdate = {
      id: todoListId,
    }
    await updateDoc(todoListDocRef, {
      ...todoListDataUpdate,
      createdTime: serverTimestamp(),
      updatedTime: serverTimestamp(),
    })

    todoListData.id = todoListId
    return todoListData
  } catch (error) {
    throw error
  }
}

export const updateTodoListWhenAddNewTask = async (
  todoListSnap: DocumentSnapshot<DocumentData>,
  newTask: Task
) => {
  try {
    const extraData = {} as any

    const lastSeq = newTask.sequence || 0

    if (lastSeq === 0) {
      throw Error('Task sequence is invalid')
    }

    const taskId = newTask?.taskId || ''

    if (taskId === '') {
      throw Error('Task id is invalid')
    }
    const docRef = doc(fireStoreDb, COLLECTION_TODOLIST_TASKS, taskId)
    extraData[`tasks.${taskId}`] = {
      reference: docRef,
      updatedTime: serverTimestamp(),
      updatedByUser: newTask?.updatedByUser || null,
    }

    await updateDoc(todoListSnap.ref, {
      ...extraData,
      lastSequence: lastSeq,
      updatedTime: serverTimestamp(),
    })
  } catch (error) {
    throw error
  }
}

export const updateTodoListWhenAddNewTasks = async (
  todoListSnap: DocumentSnapshot<DocumentData>,
  newTasks: Task[],
  nextSequence: number
) => {
  try {
    const extraData = {} as any
    const serverTimestampString = serverTimestamp()
    for (const newTask of newTasks) {

      const taskId = newTask?.taskId || ''

      if (taskId === '') {
        throw Error('Task id is invalid')
      }
      const docRef = doc(fireStoreDb, COLLECTION_TODOLIST_TASKS, taskId)
      extraData[newTask?.taskId || ''] = {
        reference: docRef,
        updatedTime: serverTimestampString,
        updatedByUser: newTask?.updatedByUser || null,
      }
    }
    const existingTasks = todoListSnap.data()?.tasks
    await updateDoc(todoListSnap.ref, {
      tasks: {...existingTasks, ...extraData},
      lastSequence: nextSequence,
      updatedTime: serverTimestampString,
    })
  } catch (error) {
    throw error
  }
}

export const updateTodoList = async (
  todoListBuild: TodoListBuilder
): Promise<TodoList> => {
  try {
    const todoListId = todoListBuild.id as string
    todoListBuild.setId(todoListId)
    const todoListData = todoListBuild.buildForUpdate(todoListBuild)

    const todoListDocRef = doc(fireStoreDb, COLLECTION_TODOLIST, todoListId)

    const objToFirebase = todoListBuild.convertToFirebaseObject()
    await updateDoc(todoListDocRef, {
      ...objToFirebase,
      updatedTime: serverTimestamp(),
    })
    todoListData.id = todoListId
    return todoListData
  } catch (error) {
    throw error
  }
}

export const updateTodoListUpdatedByOnly = async (
  todoListBuild: TodoListBuilder
): Promise<TodoList> => {
  try {
    const todoListId = todoListBuild.id as string

    const updateTodoBuild = new TodoListBuilder().setId(todoListId)

    if (!!todoListBuild.updatedByUser) {
      updateTodoBuild.setUpdatedByUser(todoListBuild.updatedByUser)
    }

    const todoListDocRef = doc(fireStoreDb, COLLECTION_TODOLIST, todoListId)

    const todoListData = updateTodoBuild.buildForUpdate(updateTodoBuild)

    if (!!todoListData.updatedByUser) {
      await updateDoc(todoListDocRef, {
        updatedByUser: todoListData.updatedByUser,
        updatedTime: serverTimestamp(),
      })
    } else {
      await updateDoc(todoListDocRef, {
        updatedTime: serverTimestamp(),
      })
    }

    todoListData.id = todoListId
    return todoListData
  } catch (error) {
    throw error
  }
}

export const publishTodoList = async (todoListBuild: TodoListBuilder) => {
  return createUpdateTodoTask(
    todoListBuild.setPublishStatus(Constants.PUBLISH_STATUS.PUBLISH),
    null,
    true
  )
}
/*==============GET functions =========================================*/

export const getTodoList = async (
  todoListId: string,
  snapshotOnly = false
): Promise<TodoList | DocumentSnapshot<DocumentData>> => {
  try {
    const todoListDocRef = doc(fireStoreDb, COLLECTION_TODOLIST, todoListId)
    const todoListSnap = await getDoc(todoListDocRef)
    if (!todoListSnap.exists()) {
      throw Error('TodoList not found')
    } else {
      if (snapshotOnly) {
        return Promise.resolve(todoListSnap)
      } else {
        return Promise.resolve(todoListSnap.data() as TodoList)
      }
    }
  } catch (error) {
    return handleFirebaseAuthenticationError(error)
  }
}

export const getLatestBusinessTodoLists = async (
  businessId: string
): Promise<TodoList[]> => {
  try {
    const querySnapshot = await getDocs(
      query(
        collection(fireStoreDb, COLLECTION_TODOLIST),
        where('businessId', '==', businessId),
        where('publishStatus', '==', Constants.PUBLISH_STATUS.PUBLISH),
        limit(Constants.LATEST_TO_DO_LIST_COUNT), // Currently, the limit is 5
        orderBy('updatedTime', OrderBy.desc)
      )
    )
    return querySnapshot.docs.map((doc) => doc.data()) as TodoList[]
  } catch (error) {
    return handleFirebaseAuthenticationError(error)
  }
}

export const getTodoListSubscribe = (
  todoListId: string,
  onTodoListChanges: (documentSnapshot: QuerySnapshot<DocumentData>) => void,
  onError: (error: FirestoreError) => void
): Unsubscribe => {
  try {
    const unsubscribe = onSnapshot(
      query(
        collection(fireStoreDb, COLLECTION_TODOLIST),
        where('id', '==', todoListId)
      ),
      onTodoListChanges,
      onError
    )
    return unsubscribe
  } catch (error) {
    return handleFirebaseAuthenticationError(error)
  }
}

export const deleteTodoList = async (
  todoListBuild: TodoListBuilder,
  todoDeletedUser: User
): Promise<TodoListBuilder> => {
  const todoListRef = doc(
    fireStoreDb,
    COLLECTION_TODOLIST,
    todoListBuild.id as string
  )

  if (!todoListBuild.id) {
    throw Error('Todo list id not found')
  }

  const res = runTransaction(fireStoreDb, async (transaction: Transaction) => {
    const todoDoc = await getDoc(todoListRef)

    if (todoDoc.exists()) {
      const tasks = todoDoc.data()?.tasks || {}

      for (const [key, value] of Object.entries(tasks)) {
        const taskRef = value.reference
        const taskSnapshot = await getDoc(taskRef)

        if (taskSnapshot.exists()) {
          const taskData = taskSnapshot.data() as Task

          const deletedTaskRef = doc(
            fireStoreDb,
            COLLECTION_DELETED_TODOLIST_TASKS,
            taskData.taskId as string
          )

          transaction.set(deletedTaskRef, {
            ...taskData,
            deletedUser: todoDeletedUser,
            updatedUser: todoDeletedUser,
            updatedTime: serverTimestamp(),
          })
          transaction.delete(taskRef)
        }
      }

      const deletedTodoListRef = doc(
        fireStoreDb,
        COLLECTION_DELETED_TODOLISTS,
        todoListBuild.id as string
      )

      transaction.set(deletedTodoListRef, {
        ...todoDoc.data(),
        deletedUser: todoDeletedUser,
        updatedUser: todoDeletedUser,
        updatedTime: serverTimestamp(),
      })
      transaction.delete(todoListRef)

      return todoListBuild
    } else {
      throw Error('Todo list not found')
    }
  })
  return res
}

export const getAllTaskDetailsLocations = async (
  todoListId: string
): Promise<Task[]> => {
  try {
    const querySnapshot = await getDocs(
      query(
        collection(fireStoreDb, COLLECTION_TODOLIST_TASKS),
        where('todoListId', '==', todoListId),
        where('location.name', '!=', '')
      )
    )
    return querySnapshot.docs.map((doc) => doc.data()) as Task[]
  } catch (error) {
    throw error
  }
}

export const getTodoOutstandingTasks = async (
  todoListId: string
): Promise<Task[]> => {
  try {
    const querySnapshot = await getDocs(
      query(
        collection(fireStoreDb, COLLECTION_TODOLIST_TASKS),
        where('todoListId', '==', todoListId),
        where('taskStatus', '==', Constants.TASK_STATUS.OUTSTANDING.key)
      )
    )
    return querySnapshot.docs.map((doc) => doc.data()) as Task[]
  } catch (error) {
    throw error
  }
}

//Business todoLists
export const getYourBusinessTodoLists = async (
  businessId: string
): Promise<TodoList[]> => {
  try {
    const querySnapshot = await getDocs(
      query(
        collection(fireStoreDb, COLLECTION_TODOLIST),
        where('businessId', '==', businessId),
        where('publishStatus', '==', PublishStatus.publish),
        orderBy('updatedTime', 'desc')
      )
    )
    return querySnapshot.docs.map((doc) => doc.data()) as TodoList[]
  } catch (error) {
    throw error
  }
}

export const getDraftTodoLists = async (
  businessId: string,
  effectiveUserId: string
) => {
  try {
    const querySnapshot = await getDocs(
      query(
        collection(fireStoreDb, COLLECTION_TODOLIST),
        where('businessId', '==', businessId),
        where('publishStatus', '==', PublishStatus.draft),
        where('createdByUser.effectiveUserId', '==', effectiveUserId),
        orderBy('updatedTime', 'desc')
      )
    )
    return querySnapshot.docs.map((doc) => doc.data()) as TodoList[]
  } catch (error) {
    throw error
  }
}
