export default {
  COGNITO_ERROR_CODES: {
    USER_NOT_CONFIRMED: 'UserNotConfirmedException',
    PASSWORD_RESET_REQUIRED: 'PasswordResetRequiredException',
    USER_NOT_FOUND: 'UserNotFoundException',
    USERNAME_EXISTS: 'UsernameExistsException',
    INVALID_PASSWORD: 'InvalidPasswordException',
  },
  GT_GENERAL: {
    GTResourceNotFoundException: 'GTResourceNotFoundException',
    GTValidationError: {
      code: 'GT_1009',
      type: 'GTValidationError',
    },
  },
  GT_SIGNUP: {
    GTIncompleteSignupException: 'GTIncompleteSignupException',
  },
  GT_NO_ACCOUNT_SELECTION: {
    code: 'GT_1007',
    type: 'GTNoRoleSelectedException',
  },
  MEDIA_ERROR_CODES: {
    MEDIA_TYPE_NOT_SUPPORTED_ERROR: 'MediaTypeNotSupportedError',
    MEDIA_NOT_AVAILABLE_ERROR: 'MediaNotAvailableError',
  },
}
