import React, { useState } from 'react'
import { Thread } from '../types/Thread'
import { Call, StreamVideoClient } from '@stream-io/video-react-sdk'
import { CallState } from '../types/Stream'

type CallManagerType = {
  callState: CallState
  thread?: Thread
  streamClient?: StreamVideoClient | null
  call?: Call | null
  setCallState: (state: CallState) => void
  setThread: (thread: Thread) => void
  setStreamClient: (client: StreamVideoClient | null | undefined) => void
  setCall: (call: Call | null) => void
}

type CallProviderProps = {
  children: React.ReactNode
}

const initCallState: CallState = {
  callMembers: [],
  initiatingCall: false,
  isCreator: false,
}

const CallManagerContext = React.createContext<CallManagerType | undefined>(
  undefined
)

const CallManagerContextProvider = ({ children }: CallProviderProps) => {
  const [callState, setCallState] = useState<CallState>(initCallState)
  const [thread, setThread] = useState<Thread | undefined>()
  const [call, setCall] = useState<Call | null>()
  const [streamClient, setStreamClient] = useState<StreamVideoClient | null>()

  return (
    <CallManagerContext.Provider
      value={{
        callState,
        thread,
        streamClient,
        call,
        setCallState,
        setThread,
        setStreamClient,
        setCall,
      }}
    >
      {children}
    </CallManagerContext.Provider>
  )
}

const useCallManagerContext = () => {
  const context = React.useContext(CallManagerContext)
  if (context === undefined) {
    throw new Error(
      'useCallManagerContext must be used within a CallManagerContextProvider'
    )
  }
  return context
}

const useCallState = () => {
  const context = React.useContext(CallManagerContext)
  return context?.callState
}

export { CallManagerContextProvider, useCallManagerContext, useCallState }
